import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = {  };

function ShowContactInfo(props) {
    const { classes } = props;
    const { t } = useTranslation();

    return (
        <Box sx={{ paddingLeft: "60px", paddingTop: "60px", minHeight: "400px" }}>
            {t('showContactInfo.info1')}            
            <br />
            {t('showContactInfo.info2')}{" "}
            <a href="mailto:office@finnebacks.se">office@finnebacks.se</a>
        </Box>
    );
}

export default withStyles(styles)(ShowContactInfo);
