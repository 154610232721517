import React, { useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import clsx from "clsx";
import { loadCSS } from "fg-loadcss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';

const styles = { };

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MessageDialog(props) {
    const theme = useTheme();
    const { classes } = props;

    useEffect(() => {
        loadCSS(
            "https://use.fontawesome.com/releases/v5.6.3/css/all.css",
            document.querySelector("#font-awesome-css")
        );
    }, []);

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
            >
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        <Typography variant="h6" sx={{marginLeft: theme.spacing(3), marginRight: theme.spacing(3), flex: 1}}>
                            {props.headline}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="Close"
                            onClick={props.onClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {props.showOkIcon && (
                        <Box sx={{color: "#2ecc71", display: "flex", justifyContent: "center",  marginTop: theme.spacing(3), margin: theme.spacing(1)}}>
                            <Icon
                                className={clsx(
                                    classes.icon,
                                    "fa fa-check fa-4x"
                                )}
                                fontSize="large"
                            />
                        </Box>
                    )}
                    {props.showWarningIcon && (
                        <Box sx={{color: "#e74c3c", display: "flex", justifyContent: "center", marginTop: theme.spacing(3), margin: theme.spacing(1)}}>
                            <Icon
                                className={clsx(
                                    classes.icon,
                                    "fas fa-warning fa-4x"
                                )}
                                fontSize="large"
                            />
                        </Box>
                    )}
                    <DialogContentText sx={{display: "flex", justifyContent: "center", marginTop: theme.spacing(3)}}>
                        {props.message}
                    </DialogContentText>

                    <DialogActions sx={{display: "flex", justifyContent: "center", margin: theme.spacing(2)}}>
                        <Button
                            className={classes.button}
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={props.onClose}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(MessageDialog);
