import React from "react";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import { styled } from '@mui/material/styles';
import SvgIcon from "@mui/material/SvgIcon";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const styles = {         
  tooltip: {
    marginLeft: 7,
  },
  horisontalTd: {
    borderCollapse: "collapse",
    border: "1px solid black",
    textAlign: "center",
  },
};

const TdKey = styled('td')(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));

function EditIcon(props) {
  if (props.isvisible !== "true") return null;

  return (
    <SvgIcon {...props}>
      <path d="M21.635,6.366c-0.467-0.772-1.043-1.528-1.748-2.229c-0.713-0.708-1.482-1.288-2.269-1.754L19,1C19,1,21,1,22,2S23,5,23,5  L21.635,6.366z M10,18H6v-4l0.48-0.48c0.813,0.385,1.621,0.926,2.348,1.652c0.728,0.729,1.268,1.535,1.652,2.348L10,18z M20.48,7.52  l-8.846,8.845c-0.467-0.771-1.043-1.529-1.748-2.229c-0.712-0.709-1.482-1.288-2.269-1.754L16.48,3.52  c0.813,0.383,1.621,0.924,2.348,1.651C19.557,5.899,20.097,6.707,20.48,7.52z M4,4v16h16v-7l3-3.038V21c0,1.105-0.896,2-2,2H3  c-1.104,0-2-0.895-2-2V3c0-1.104,0.896-2,2-2h11.01l-3.001,3H4z" />
    </SvgIcon>
  );
}

function RoomPosMarkTable(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = props;

  const handleOnClick = (data) => {
    let area = { name: data };
    props.onRoomPosMarkClicked(area);
  };

  function splitToMultiLines(text){
    const newText = text.split('\n').map(str => <tr><td>{str}</td></tr>);
    return newText;
  };

  const RenderVerticalTable = (
    <React.Fragment>
      <AppBar sx={{ backgroundColor: "black", position: "relative", width: "400px" }} elevation={0}>
        <Toolbar variant="dense">
          <Typography
            variant="button"
            display="block"
            sx={{marginLeft: theme.spacing(2), flex: 1}}
          >
            {t('roomPosMarkTable.headline')}
          </Typography>
        </Toolbar>
      </AppBar>
      <table sx={{width: "100%"}}>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={index} sx={{ borderTop: "1pt solid black", paddingTop: theme.spacing(2) }}>
              <td sx={{ paddingTop: theme.spacing(2),
						paddingLeft: theme.spacing(2),
						paddingRight: theme.spacing(2),
						verticalAlign: "top",
						width: "1px",
						whiteSpace: "nowrap" }}>
              <h4>{item.positionNumber}</h4>
              </td>
              <td sx={{ cursor: "pointer", paddingTop: theme.spacing(2), whiteSpace: "nowrap" }}>
                <table>
                  <tbody>
                    <tr
                      key={index.toString() + "_1002"}
                      onClick={() => handleOnClick(item.data)}
                    >
                      <TdKey>{t('roomPosMarkTable.benamning')}</TdKey>
                      <td>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>{JSON.parse(item.data).roomLabel}</span>
                        </Tooltip>
                      </td>
                    </tr>
                    <tr
                      key={index.toString() + "_1003"}
                      onClick={() => handleOnClick(item.data)}
                    >
                      <TdKey>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>{t('roomPosMarkTable.invHeight')}</span>
                        </Tooltip>
                      </TdKey>
                      <td>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>
                            h=
                            {JSON.parse(item.data).invHeight} &nbsp;&nbsp;
                            <EditIcon
                              color="primary"
                              fontSize="small"
                              isvisible={props.editEnabled.toString()}
                            />
                          </span>
                        </Tooltip>
                      </td>
                    </tr>
                    <tr
                      key={index.toString() + "_1004"}
                      onClick={() => handleOnClick(item.data)}
                    >
                      <TdKey>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>{t('roomPosMarkTable.utvHeight')}</span>
                        </Tooltip>
                      </TdKey>
                      <td>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>
                            h=
                            {JSON.parse(item.data).utvHeight}
                          </span>
                        </Tooltip>
                      </td>
                    </tr>
                    <tr
                      key={index.toString() + "_1005"}
                      onClick={() => handleOnClick(item.data)}
                    >
                      <TdKey>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>{t('roomPosMarkTable.panelThickness')}</span>
                        </Tooltip>
                      </TdKey>
                      <td>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>
                            t=
                            {JSON.parse(item.data).panelThickness}
                          </span>
                        </Tooltip>
                      </td>
                    </tr>
                    <tr
                      key={index.toString() + "_1006"}
                      onClick={() => handleOnClick(item.data)}
                    >
                      <TdKey>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>{t('roomPosMarkTable.floorType')}</span>
                        </Tooltip>
                      </TdKey>
                      <td>
                        <Tooltip
                          title={
                            props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                          }
                        >
                          <span>{JSON.parse(item.data).floorText}</span>
                        </Tooltip>
                      </td>
                    </tr>
                    {JSON.parse(item.data).numWalls === 0 && (
                      <tr
                        key={index.toString() + "_1007"}
                        onClick={() => handleOnClick(item.data)}
                      >
                        <TdKey>
                          <Tooltip
                            title={
                              props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                            }
                          >
                            <span>{t('roomPosMarkTable.walls')}</span>
                          </Tooltip>
                        </TdKey>
                        <td>
                          <Tooltip
                            title={
                              props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                            }
                          >
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              {JSON.parse(item.data).numWalls} st
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    )}
                    {JSON.parse(item.data).hasRoof === "Nej" && (
                      <tr
                        key={index.toString() + "_1008"}
                        onClick={() => handleOnClick(item.data)}
                      >
                        <TdKey>
                          <Tooltip
                            title={
                              props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                            }
                          >
                            <span>{t('roomPosMarkTable.roof')}</span>
                          </Tooltip>
                        </TdKey>
                        <td>
                          <Tooltip
                            title={
                              props.editEnabled ? t('roomPosMarkTable.clickToChange') : ""
                            }
                          >
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              {JSON.parse(item.data).hasRoof}
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    )}
                    {item.roomPositionsItems.map((c, i) => (
                      <tr key={i} onClick={() => handleOnClick(c.value.data)}>
                        <TdKey sx={{verticalAlign: "top"}}>{c.key}</TdKey>
                        <td>
                          <table sx={{width: "100%"}}>
                            <tbody>
                              {splitToMultiLines(c.value.text)}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );

  const RenderHorisontalTable = (
    <table sx={{ width: "100%", borderCollapse: "collapse", border: "1px solid black", marginTop: theme.spacing(7) }}>
      <thead>
        <tr>
          <th className={classes.horisontalTd}>ID</th>
          <th className={classes.horisontalTd}>Typ</th>
          <th className={classes.horisontalTd}>Rumsbenämning</th>
          <th className={classes.horisontalTd}>Inv.höjd</th>
          <th className={classes.horisontalTd}>Utv.höjd</th>
          <th className={classes.horisontalTd}>t=</th>
          <th className={classes.horisontalTd}>Golvtyp</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((item, index) => (
          <tr
            key={index}
            sx={{ cursor: "pointer", borderCollapse: "collapse", border: "1px solid black" }}
            onClick={() => handleOnClick(item.data)}
          >
            <td className={classes.horisontalTd}>{item.positionNumber}</td>
            <td className={classes.horisontalTd}>
              {JSON.parse(item.data).roomType}
            </td>
            <td className={classes.horisontalTd}>
              {JSON.parse(item.data).roomLabel}
            </td>
            <td className={classes.horisontalTd}>
              {JSON.parse(item.data).invHeight}
            </td>
            <td className={classes.horisontalTd}>
              {JSON.parse(item.data).utvHeight}
            </td>
            <td className={classes.horisontalTd}>
              {JSON.parse(item.data).panelThickness}
            </td>
            <td className={classes.horisontalTd}>
              {JSON.parse(item.data).floorText}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className={classes.root}>
      {props.data && (
        <React.Fragment>
          <Typography sx={{ backgroundColor: "black", color: "white" }} />
          {props.showVertical && <div>{RenderVerticalTable}</div>}
          {props.showHorisontal && <div>{RenderHorisontalTable}</div>}
        </React.Fragment>
      )}
    </div>
  );
}

export default withStyles(styles)(RoomPosMarkTable);
