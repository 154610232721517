import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import { green } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import Modal from "@mui/material/Modal";
import { red } from '@mui/material/colors';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";

const styles = { };

function ChangeIntegerValue(props) {
    const theme = useTheme();  
    const { t } = useTranslation();
    const [newValue, setNewValue] = useState(null);    
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {        
        setNewValue(props.changeData.oldValue);
      }, [props.changeData.oldValue]);

    const handleValueChanged = (newValue) => {
        validateData(newValue);
        setNewValue(newValue);
    }

    const handleEnterPressed = (event) => {        
        handleBtnOkClicked(event.target.value);
        event.preventDefault();
    };

    const handleBtnOkClicked = (newValue) => {
        
        if (!validateData(newValue))
            return;

        props.onOkClicked(props.changeData.propertyName, newValue, props.changeData.oldValue);
    };

    const validateData = (newValue) => {

        if (props.changeData.min !== props.changeData.max)
        {
            if (newValue < props.changeData.min)
            {
                setError(true);
                setErrorMessage("Min tillåtet värde är " + props.changeData.min + " mm");
                return false;
            }

            if (newValue > props.changeData.max)
            {
                setError(true);
                setErrorMessage("Max tillåtet värde: " + props.changeData.max + " mm");
                return false;
            }
        }

        setError(false);
        setErrorMessage(null);
        return true;
    };

    if (props.changeData.isActive) {
        return (
            <Box sx={{display: "flex", flexWrap: "wrap"}}>
                <CssBaseline />
                <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
                <Card sx={{maxWidth: 400, height: "100%", overflowY: "auto"}}>
                    <CardHeader
                    action={
                        <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                        <IconButton onClick={props.onClose} size="large">
                            <CancelPresentationIcon style={{ color: red[500] }} />
                        </IconButton>
                        </Tooltip>
                    }
                    title= 'Nytt värde'
                    />
                    <CardContent>
                        <FormControl sx={{margin: theme.spacing(1),  minWidth: 330}}>
                            <Box>
                                <TextField
                                    type="number"
                                    label="Ange nytt värde"
                                    autoFocus
                                    error={error}
                                    InputProps={{inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">{props.changeData.dimPrefix}</InputAdornment>, endAdornment: <InputAdornment position="end">mm</InputAdornment>}}
                                    sx={{ m: 0, width: '180px' }}
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+') {
                                        event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {handleValueChanged(event.target.value);}}
                                    onKeyDown={(event) => (event.keyCode === 13 ? handleEnterPressed(event) : null)}
                                    value={newValue}
                                />
                                <Button 
                                    display="inline" 
                                    sx={{ backgroundColor: green[500], m: theme.spacing(1) }} 
                                    variant="contained" 
                                    onClick={() => {handleBtnOkClicked(newValue);}}
                                >
                                    OK
                                </Button>                                
                            </Box>    

                            {errorMessage !== null && (
                                <Box mt={2}>
                                    <Typography sx={{color:"red"}}>{errorMessage}</Typography>
                                </Box>
                            )}                            
                        </FormControl>
                    </CardContent>
                </Card>
                </Modal>
            </Box>
            );
    }

    return null;
}

export default withStyles(styles)(ChangeIntegerValue);