import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CssBaseline from "@mui/material/CssBaseline";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";

const styles = {};

const GridControlArea = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3.5),
  minWidth: 945,
}));

const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  color: green[400],
  '&.Mui-checked': {
    color: green[600],
  },
}));

const sliderTicksDimTextHeight = {
  placement: "After",
  largeStep: 25,
  smallStep: 25,
  showSmallTicks: false,
};


function ChangeOptions(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dimTextHeight, setDimTextHeight] = useState(null);
  const [showSurroundingBuilding, setShowSurroundingBuilding] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      let roomUnit = props.designData.roomUnitStack.RoomUnits.find(
        (w) =>
          w.RoomUnit.ID === props.designData.roomUnitStack.CurrentRoomUnitID
      ).RoomUnit;

      setDimTextHeight(roomUnit.DimTextHeight);
      setShowSurroundingBuilding(roomUnit.IsShowSurroundingBuilding);
      setIsLoaded(true);
    }
  }, [isLoaded, props.designData]);

  const handleDimTextHeightChange = (args) => {
    if (args.value !== dimTextHeight) {
      setDimTextHeight(args.value);
      props.onChange({
        dimTextHeight: args.value,
      });
    }
  };

  const handleShowSurroundingBuildingChange = (event) => {
    setShowSurroundingBuilding(event.target.checked);
    props.onSurroundingChange();
  };

  if (!isLoaded) return null;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
        <Card sx={{height: "100%", overflowY: "auto"}}>
          <CardHeader
            action={
              <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                <IconButton onClick={props.onClose} size="large">
                  <CheckBoxIcon style={{ color: green[500] }} />
                </IconButton>
              </Tooltip>
            }
            title={t('changeOptions.title')}
          />
          <CardContent>
            <GridControlArea>
              <div
                className={"e-float-text e-label-top"}
                style={{
                  fontSize: "13px",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                {t('changeOptions.textHeight')}
              </div>
              <SliderComponent
                id="dimTextHeight"
                min={100}
                max={250}
                showButtons={true}
                step={25}
                ticks={sliderTicksDimTextHeight}
                value={dimTextHeight}
                change={handleDimTextHeightChange}
              />
            </GridControlArea>
            <GridControlArea>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      name="chkSurrounding"
                      checked={showSurroundingBuilding}
                      onChange={handleShowSurroundingBuildingChange}
                    />
                  }
                  label={t('changeOptions.surrounding')}
                />
              </FormGroup>
            </GridControlArea>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(ChangeOptions);
