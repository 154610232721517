import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


const NumInput = styled(MuiInput)`
  width: 60px;
`;

export default function IatNumInputSlider(props) {        
    const [label, setLabel] = useState(null);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(100);
    const [step, setStep] = useState(1);
    const [value, setValue] = useState(0);

    useEffect(() => {
        setLabel(props.label);
        setMin(props.min);
        setMax(props.max);
        setStep(props.step);
        setValue(props.value);
      }, [props.label, props.min, props.max, props.step, props.value]);

    const handleSliderChange = (event, newValue) => {
      setValue(newValue);
      props.onChange(label, value);
    };
  
    const handleInputChange = (event) => {
      setValue(event.target.value === '' ? '' : Number(event.target.value));
      props.onChange(label, value);
    };
  
    const handleBlur = () => {
      if (value < min) {
        setValue(min);
      } else if (value > max) {
        setValue(max);
      }
      props.onChange(label, value);
    };

    return (
      <Box sx={{ margin:'0.5em', width: 320 }}>      
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography id="input-slider" gutterBottom>
              {label}
            </Typography>    
          </Grid>
          <Grid item xs>
            <Slider
              min={min}
              max={max}
              step={step}
              value={typeof value === 'number' ? value : min}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item>
            <NumInput
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: {step},
                min: {min},
                max: {max},
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
}