import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const styles = { };

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GreenButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[500],
    "&:hover": {
        backgroundColor: green[700]
    }
  }));

function DockShowRoomDialog(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { classes } = props;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Dialog
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle className={classes.root}>
                    {t('dockShowRoomDialog.title')}
                    <IconButton
                        aria-label="Close"
                        sx={{position: "absolute", right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500]}}
                        onClick={() =>
                            props.onClick("dockShowRoomDialogCancel")
                        }
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        {t('dockShowRoomDialog.text')}                        
                    </DialogContentText>

                    <DialogActions>
                        <Button
                            className={classes.button}
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={() => props.onClick("dockShowColdRoom")}
                        >
                            Kylrum
                        </Button>
                        <GreenButton
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            onClick={() => props.onClick("dockShowFreezeRoom")}
                        >
                            Frysrum
                        </GreenButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(DockShowRoomDialog);
