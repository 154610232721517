import React, { useState } from "react";
import produce from "immer";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";
import SweSettings from "./localization/AllProFormManagerSweSettings";
import MessageDialog from "./MessageDialog";
import RegisterVerifyEmail from "./RegisterVerifyEmail";
import RequestAuthCode from "./RequestAuthCode";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = { };

const TextFieldEx = styled(TextField)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "400px",
    ".MuiInputLabel-asterisk": { color: "red" },
  }));

const TdPadding = styled('td')(({ theme }) => ({
    padding: "15px"
  }));

const formConfigSwe = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

    fields: {
        company: {
            displayName: "Företag",
            validation: {
                required: true,
                minLength: 4,
                maxLength: 40
            },
            errorMessages: {
                required: "Namn på ditt företag måste anges."
            }
        },
        firstName: {
            displayName: "Förnamn",
            validation: {
                required: true,
                minLength: 2,
                maxLength: 25
            },
            errorMessages: {
                required: "Förnamn måste anges."
            }
        },
        lastName: {
            displayName: "Efternamn",
            validation: {
                required: true,
                minLength: 2,
                maxLength: 25
            },
            errorMessages: {
                required: "Efternamn måste anges."
            }
        },
        phone: {
            displayName: "Telefon",
            validation: {
                required: true,
                minLength: 6,
                maxLength: 25
            },
            errorMessages: {
                required: "Telefonnummer måste anges."
            }
        },
        email: {
            displayName: "E-post adress",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "En giltig e-post adress måste anges."
            }
        },
        password: {
            displayName: "Lösenord",
            inputType: "password",
            validation: {
                required: true,
                password: true,
                minLength: 4,
                maxLength: 24,
                passwordComplexity: { lower: 0, upper: 0, number: 0, symbol: 0 }
            },
            errorMessages: {
                required: "Lösenord måste anges.",
                minLength:"Lösenord måste innehålla minst 4 tecken, helst 6 tecken eller fler.",
                maxLength: "Lösenord får inte innehålla fler än 24 tecken."
            }
        },
        confirmPassword: {
            displayName: "Bekräfta lösenord",
            inputType: "password",
            validation: {
                custom: (value, fieldname, unknown, form) => {
                    const passwordValue = form.value("password");
                    if (passwordValue !== value) {
                        return "Lösenord och det bekräftade lösenordet överensstämmer inte";
                    } else {
                        return true;
                    }
                }
            }
        }
    }
};

const formConfigFin = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

    fields: {
        company: {
            displayName: "Yritys",
            validation: {
                required: true,
                minLength: 4,
                maxLength: 40
            },
            errorMessages: {
                required: "Yrityksesi nimi on ilmoitettava."
            }
        },
        firstName: {
            displayName: "Etunimi",
            validation: {
                required: true,
                minLength: 2,
                maxLength: 25
            },
            errorMessages: {
                required: "Etunimi on syötettävä."
            }
        },
        lastName: {
            displayName: "Sukunimi",
            validation: {
                required: true,
                minLength: 2,
                maxLength: 25
            },
            errorMessages: {
                required: "Sukunimi on määritettävä."
            }
        },
        phone: {
            displayName: "Puhelin",
            validation: {
                required: true,
                minLength: 6,
                maxLength: 25
            },
            errorMessages: {
                required: "Puhelinnumero on syötettävä."
            }
        },
        email: {
            displayName: "Sähköpostiosoite",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "Kelvollinen sähköpostiosoite on annettava."
            }
        },
        password: {
            displayName: "Salasana",
            inputType: "password",
            validation: {
                required: true,
                password: true,
                minLength: 4,
                maxLength: 24,
                passwordComplexity: { lower: 0, upper: 0, number: 0, symbol: 0 }
            },
            errorMessages: {
                required: "Salasana on syötettävä.",
                minLength:"Salasanan tulee sisältää vähintään 4 merkkiä, mieluiten 6 merkkiä tai enemmän.",
                maxLength: "Salasanat voivat sisältää enintään 24 merkkiä."
            }
        },
        confirmPassword: {
            displayName: "Vahvista salasana",
            inputType: "password",
            validation: {
                custom: (value, fieldname, unknown, form) => {
                    const passwordValue = form.value("password");
                    if (passwordValue !== value) {
                        return "Salasana ja vahvistettu salasana eivät täsmää";
                    } else {
                        return true;
                    }
                }
            }
        }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Register(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { classes } = props;
    const [loading, setLoading] = useState(false);
    const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
    const [isRequestAuthCodeOpen, setRequestAuthCodeOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [messageDialog, setMessageDialog] = useState({
        isOpen: false,
        success: false,
        headline: "",
        message: "",
        showOkIcon: false,
        showWarningIcon: false
    });    
    const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe);

    function handleSubmit() {
        form.validate().then(isValid => {
            if (isValid) {
                setLoading(true);
                let mergedObject = {
                    ...form.data(),
                    language: i18n.language
                };
                let dtoData = JSON.stringify(mergedObject);
                fetch("/api/Account/Register", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: dtoData
                })
                    .then(res => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            throw new Error(res.status + " " + res.statusText);
                        }
                    })
                    .then(
                        result => {
                            if (result.succeeded) {
                                setEmail(form.data().email);
                                setIsVerifyEmailOpen(true);
                                setMessageDialog(
                                    produce(draft => {
                                        draft.isOpen = true;
                                        draft.success = true;
                                        draft.headline = t('register.msgBox.handleSubmit.ok.headline');
                                        draft.message = t('register.msgBox.handleSubmit.ok.message');
                                        draft.showOkIcon = true;
                                        draft.showWarningIcon = false;
                                    })
                                );
                            } else {
                                setMessageDialog(
                                    produce(draft => {
                                        draft.isOpen = true;
                                        draft.success = false;
                                        draft.headline = t('register.msgBox.handleSubmit.invalid.headline');
                                        draft.message = result.errorMessages[0];
                                        draft.showOkIcon = false;
                                        draft.showWarningIcon = true;
                                    })
                                );
                            }
                        },
                        error => {
                            setMessageDialog(
                                produce(draft => {
                                    draft.isOpen = true;
                                    draft.success = false;
                                    draft.headline = t('register.msgBox.handleSubmit.error.headline');
                                    draft.message = error.stack;
                                    draft.showOkIcon = false;
                                    draft.showWarningIcon = true;
                                })
                            );
                        }
                    )
                    .catch(error => {
                        setMessageDialog(
                            produce(draft => {
                                draft.isOpen = true;
                                draft.success = false;
                                draft.headline = t('register.msgBox.handleSubmit.error.headline');
                                draft.message = error.stack;
                                draft.showOkIcon = false;
                                draft.showWarningIcon = true;
                            })
                        );
                    });
            }
        });
    }

    const handleMessageDialogClose = success => {
        if (success) {
            if (isVerifyEmailOpen || isRequestAuthCodeOpen) {
                setLoading(false);
                setMessageDialog(
                    produce(draft => {
                        draft.headline = "";
                        draft.isOpen = false;
                        draft.meassage = "";
                        draft.showOkIcon = false;
                        draft.showWarningIcon = false;
                    })
                );
                return;
            }
            props.onClose();
        } else {
            setLoading(false);
            setMessageDialog(
                produce(draft => {
                    draft.headline = "";
                    draft.isOpen = false;
                    draft.meassage = "";
                    draft.showOkIcon = false;
                    draft.showWarningIcon = false;
                })
            );
        }
    };

    const handleVerifyEmail = () => {
        setIsVerifyEmailOpen(true);
    };

    const handleVerifyEmailClose = () => {
        setIsVerifyEmailOpen(false);
    };

    const handleVerifyEmailSubmit = () => {
        setIsVerifyEmailOpen(false);
        props.onClose();
    };

    const handleRequestAuthCode = () => {
        setRequestAuthCodeOpen(true);
    };

    const handleRequestAuthCodeClose = () => {
        setRequestAuthCodeOpen(false);
    };

    form.submit = handleSubmit;

    return (
        <Box sx={{display: "flex"}}>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
            >
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        <Typography variant="h6" sx={{marginLeft: theme.spacing(2), flex: 1}}>
                            {t('register.headline')}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="Close"
                            onClick={props.onClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {t('register.alreadyRegistred.clickIfAlreadyRegistred')}                                
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <table>
                                <tbody>
                                    <tr>
                                        <TdPadding>
                                            {t('register.alreadyRegistred.instruction1')}
                                        </TdPadding>
                                        <td />
                                    </tr>
                                    <tr>
                                        <TdPadding>
                                            <h6>{t('register.alreadyRegistred.isValidationCodeAdded')}</h6>
                                            {t('register.alreadyRegistred.instruction2')}                                            
                                        </TdPadding>
                                        <TdPadding>
                                            <Button
                                                className={classes.button}
                                                color="primary"
                                                disabled={loading}
                                                type="submit"
                                                variant="contained"
                                                onClick={() => {
                                                    handleVerifyEmail();
                                                }}
                                            >
                                                {t('register.alreadyRegistred.btnAddValidationCode')}                                                
                                            </Button>
                                        </TdPadding>
                                    </tr>
                                    <tr>
                                        <TdPadding>
                                            <h6>{t('register.alreadyRegistred.txtSendNewValidationCode')}</h6>
                                        </TdPadding>
                                        <TdPadding>
                                            <Button
                                                className={classes.button}
                                                color="primary"
                                                disabled={loading}
                                                type="submit"
                                                variant="contained"
                                                onClick={() => {
                                                    handleRequestAuthCode();
                                                }}
                                            >
                                                {t('register.alreadyRegistred.btnSendNewValidationCode')}                                                
                                            </Button>
                                        </TdPadding>
                                    </tr>
                                </tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>
                    <Grid item md={12}>
                        <Box sx={{backgroundColor: "#ffc107", color: "#212529"}} m={1} p={1}>
                            {t('register.infoAproveToSave')}
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item md={5}>
                                <Box
                                    sx={{backgroundColor: "#006bb2",  color: "white"}}
                                    m={0}
                                    ml={1}
                                    p={1}
                                >
                                    <b>{t('register.passwordInfo.passwordMust')}</b>
                                    <ul>
                                        <li>{t('register.passwordInfo.beAtLeastFourChar')}</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </Box>
                            </Grid>
                            <Grid item md={7}>
                                <Box
                                    sx={{backgroundColor: "#17a2b8", color: "#fff"}}
                                    m={0}
                                    mr={1}
                                    p={1}
                                >
                                    <b>{t('register.passwordInfo.aSaferPassword')}</b>
                                    <ul>
                                        <li>{t('register.passwordInfo.beAtLeastSixChar')}</li>
                                        <li>{t('register.passwordInfo.atLeastOneNotAlfaNum')}
                                            <br />{t('register.passwordInfo.sampleNotAlfaNum')}
                                        </li>
                                        <li>{t('register.passwordInfo.atLeastOneDigit')}</li>
                                        <li>{t('register.passwordInfo.atLeastOneSmallLetter')}</li>
                                        <li>{t('register.passwordInfo.atLeastOneCapital')}</li>                                        
                                    </ul>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box m={1} p={0}>
                            {t('register.emailInfo.note1')}                            
                            <br />
                            {t('register.emailInfo.note2')}                            
                            <br />
                            {t('register.emailInfo.note3')}{" "}
                            <a href="mailto:noreply@finnebacks.inadire.se">
                                noreply@finnebacks.inadire.se
                            </a>
                            &nbsp; {t('register.emailInfo.note4')}
                            <br />
                        </Box>
                        <Box                            
                            m={1}
                            mb={0}
                            mt={0}
                            p={0}
                        >
                            <Typography variant="body2" gutterBottom={false}>
                                {t('register.tips')}
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", flexDirection: "column"}} pt={0}>
                            <TextFieldEx
                                id="email"
                                label={t('register.labels.email')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("email")}
                            />
                            <TextFieldEx
                                id="password"
                                label={t('register.labels.password')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("password")}
                            />
                            <TextFieldEx
                                id="confirmPassword"
                                label={t('register.labels.confirmPassword')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("confirmPassword")}
                            />
                            <TextFieldEx
                                id="company"
                                label={t('register.labels.company')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("company")}
                            />
                            <TextFieldEx
                                id="firstName"
                                label={t('register.labels.firstName')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("firstName")}
                            />
                            <TextFieldEx
                                id="lastName"
                                label={t('register.labels.lastName')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("lastName")}
                            />
                            <TextFieldEx
                                id="phone"
                                label={t('register.labels.phone')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("phone")}
                            />
                        </Box>
                    </Grid>
                    <DialogActions sx={{justifyContent: "flex-start"}}>
                        <Button
                            className={classes.button}
                            color="primary"
                            disabled={loading}
                            type="submit"
                            variant="contained"                            
                            onClick={form.submit}
                        >
                            {t('register.btnSendRegistration')}                            
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{color: green[500], position: "absolute", left: "50%", marginLeft: 12}}
                            />
                        )}
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {isVerifyEmailOpen && (
                <RegisterVerifyEmail
                    email={email}
                    onClose={handleVerifyEmailClose}
                    submit={handleVerifyEmailSubmit}
                />
            )}
            {isRequestAuthCodeOpen && (
                <RequestAuthCode onClose={handleRequestAuthCodeClose} />
            )}
            {messageDialog.isOpen && (
                <MessageDialog
                    headline={messageDialog.headline}
                    message={messageDialog.message}
                    showOkIcon={messageDialog.showOkIcon}
                    showWarningIcon={messageDialog.showWarningIcon}
                    onClose={() =>
                        handleMessageDialogClose(messageDialog.success)
                    }
                />
            )}
        </Box>
    );
}

export default withStyles(styles)(Register);
