import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';


const styles = { };

function RequestReceived(props) {
    const { classes } = props;
    const { t } = useTranslation();

    return (
        <Box sx={{paddingLeft: "60px", paddingTop: "60px", minHeight: "400px"}}>
            <h2>{t('requestReceived.headline')}</h2>
            {t('requestReceived.message1')} <br />
            {t('requestReceived.message2')}
        </Box>
    );
}

export default withStyles(styles)(RequestReceived);
