import React, { Fragment } from 'react';
import { Suspense } from 'react';
import { render } from 'react-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { registerLicense } from '@syncfusion/ej2-base';
import RoomEditor from "./components/RoomEditor";
import './i18n';

//const App = () => (
//    <Fragment>        
//        <RoomEditor/>
//    </Fragment>    
//);

registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFacF5JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNhW39ccHJVT2RYWUE=');

const theme = createTheme({
    palette: {
        primary: {
            light: '#7986cb',
            main: '#3f51b5',
            dark: '#303f9f',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff4081',
            main: '#f50057',
            dark: '#c51162',
            contrastText: '#fff'
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: 'rgba(0, 0, 0, 0.87)'
        },
    },
});

const App = () => (
    <div className="App">
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RoomEditor />
            </ThemeProvider>
        </StyledEngineProvider>
    </div>
);

render(
    <Suspense fallback="Loading...">
        <App />
    </Suspense>,
    document.getElementById('app')
);

