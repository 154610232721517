import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = {};

const imageNames = {
  InfoWall: require("../images/InfoWall.png"),
  InfoPossibleWall: require("../images/InfoPossibleWall.png"),
  InfoDoor: require("../images/InfoDoor.png"),
  InfoPossibleDoor: require("../images/InfoPossibleDoor.png"),
  InfoShelving: require("../images/InfoShelving.png"),
  InfoPossibleShelving: require("../images/InfoPossibleShelving.png"),
  InfoPreviewTooltip: require("../images/InfoPreviewTooltip.png"),
  InfoPreviewPosTable: require("../images/InfoPreviewPosTable.png"),
  InfoPreviewRoomPosMark: require("../images/InfoPreviewRoomPosMark.png"),
};

function HelpInfo(props) {  
  const { t } = useTranslation();
  const { classes } = props;  
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", maxWidth: "400px" }}>
      <Card className={classes.card}>
        <CardHeader
          action={
            <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          title={t('helpInfo.title')}
        />
        <CardContent>
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{t('helpInfo.panel1.headline')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('helpInfo.panel1.text1')}                
                <br />
                <br />
                {t('helpInfo.panel1.text2')}                
                <br />
                <br />
                {t('helpInfo.panel1.text3')}                
                <br />
                <br />
                {t('helpInfo.panel1.text4')}                
                <br />
                <img src={imageNames.InfoPreviewTooltip} alt="" />
                <br />
                <br />
                <br />
                {t('helpInfo.panel1.text5')}                
                <br />
                <br />
                <img src={imageNames.InfoPreviewPosTable} alt="" />
                <br />
                <br />
                <br />
                {t('helpInfo.panel1.text6')}                
                <br />
                <img
                  src={imageNames.InfoPreviewRoomPosMark}
                  alt=""
                  width="240"
                />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>{t('helpInfo.panel2.headline')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('helpInfo.panel2.text1')}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>{t('helpInfo.panel3.headline')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table cellPadding={"10"}>
                <tbody>
                  <tr>
                    <td style={{ height: "60px" }}>
                      <img src={imageNames.InfoPossibleWall} alt="" />
                    </td>
                    <td>
                      <strong>{t('helpInfo.panel3.strong1')}</strong>
                      <br />
                      {t('helpInfo.panel3.text1')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "60px" }}>
                      <img src={imageNames.InfoWall} alt="" />
                    </td>
                    <td>
                      <strong>{t('helpInfo.panel3.strong2')}</strong>
                      <br />
                      {t('helpInfo.panel3.text2')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "120px" }}>
                      <img src={imageNames.InfoPossibleDoor} alt="" />
                    </td>
                    <td>
                      <strong>{t('helpInfo.panel3.strong3')}</strong>
                      <br />
                      {t('helpInfo.panel3.text3')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "120px" }}>
                      <img src={imageNames.InfoDoor} alt="" />
                    </td>
                    <td>
                      <strong>{t('helpInfo.panel3.strong4')}</strong>
                      <br />
                      {t('helpInfo.panel3.text4')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "60px" }}>
                      <img src={imageNames.InfoPossibleShelving} alt="" />
                    </td>
                    <td>
                      <strong>{t('helpInfo.panel3.strong5')}</strong>
                      <br />
                      {t('helpInfo.panel3.text5')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "60px" }}>
                      <img src={imageNames.InfoShelving} alt="" />
                    </td>
                    <td>
                      <strong>{t('helpInfo.panel3.strong6')}</strong>
                      <br />
                      {t('helpInfo.panel3.text6')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel34-content"
              id="panel34-header"
            >
              <Typography>{t('helpInfo.panel4.headline')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {t('helpInfo.panel4.text1')}
                <br />
                <br />
                <div>
                  <span style={{ marginBottom: "5px" }}>
                    {t('helpInfo.panel4.text2')}
                  </span>
                  <ul>
                    <li>{t('helpInfo.panel4.text3')}</li>
                    <li>{t('helpInfo.panel4.text4')}</li>
                    <li>{t('helpInfo.panel4.text5')}</li>
                  </ul>
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withStyles(styles)(HelpInfo);
