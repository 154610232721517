import React, { useState, useEffect } from "react";

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import {IatCustomSelect, IatStyledOption} from "./basecomponents/IatCustomSelect";
import IatNumInputSlider from "./basecomponents/IatNumInputSlider";

const styles = {};

const imageNumPanelParts = {
    1: require("../images/NumPanelParts1.png"),
    2: require("../images/NumPanelParts2.png"),
    3: require("../images/NumPanelParts3.png"),
    4: require("../images/NumPanelParts4.png"),
    5: require("../images/NumPanelParts5.png")
};

const panelPartsOptions = [
    { code: '1', label: '1 panel' },
    { code: '2', label: '2 paneler' },
    { code: '3', label: '3 paneler' },
    { code: '4', label: '4 paneler' },
    { code: '5', label: '5 paneler' },
];

function ChangePanelDivision(props) {  
  const { classes } = props;
  const { t } = useTranslation();
  
  const [isLoaded, setIsLoaded] = useState(false);  
  const [isModified, setIsModified] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [panelDivision, setPanelDivision] = useState(null);  
  const [numPanelParts, setNumPanelParts] = useState(1);
  const [matt1, setMatt1] = useState(0);
  const [matt2, setMatt2] = useState(0);
  const [matt3, setMatt3] = useState(0);
  const [matt4, setMatt4] = useState(0);  
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    if (!isLoaded) {
        // Ex "1" eller "4 [6000 6000 3000 *]"
        setPanelDivision(props.panelDivision);
        let myPanelDivision = props.panelDivision.replace('[', '');
        let myArray = myPanelDivision.split(" ");
        let count = myArray.length;        

        if (myPanelDivision === null || myPanelDivision === "1" || count < 2){
            setNumPanelParts('1');
            
        } else {            
            setNumPanelParts(myArray[0] === '' ? '1' : myArray[0]);
            setMatt1((count < 2 || myArray[1] === '') ? 0 : Number(myArray[1]));
            setMatt2((count < 3 || myArray[1] === '') ? 0 : Number(myArray[2]));
            setMatt3((count < 4 || myArray[1] === '') ? 0 : Number(myArray[3]));
            setMatt4((count < 5 || myArray[1] === '') ? 0 : Number(myArray[4]));
        }
                
        setIsModified(false);
        setIsLoaded(true);
    }
  }, [isLoaded, props.panelDivision]);

  if (!isLoaded) return null;
   
  const labelLastText = ('changePanelDivision.dimension') + " " + parseInt(numPanelParts) + ":";

  const handleNumPanelPartChanged = (newValue) => {
    setNumPanelParts(newValue);    
    setIsModified(true);    
    setErrMsg(validateData(newValue) ? null : t('changePanelDivision.handleNumPanelPartChanged.errorMsg'));
  };

  const handleSliderChanged = (label, value) => {
      switch(label)
      {
          case "Mått 1: ":
          case "Mitata 1: ":
              setMatt1(value);              
              break;

            case "Mått 2: ":
            case "Mitata 2: ":
                setMatt2(value);
                break;

            case "Mått 3: ":
            case "Mitata 3: ":
                setMatt3(value);
                break;

            case "Mått 4: ":
            case "Mitata 4: ":
                setMatt4(value);
                break;

            default:
                break;
      }

      setIsModified(true);
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClickClose = () => {
    if (isModified) {      
      if (!validateData(numPanelParts)){
        setErrMsg(t('changePanelDivision.handleClickClose.errorMsg'))
        return;
      }

      let result = numPanelParts.toString();
      switch(Number(numPanelParts))
      {
        case 1:
          break;

        case 2:
          result = result.concat(" [", matt1.toString(), " *]");
          break;
          
        case 3:
          result = result.concat(" [", matt1.toString(), " ", matt2.toString(), " *]");
          break;

        case 4:
          result = result.concat(" [", matt1.toString(), " ", matt2.toString(), " ", matt3.toString(), " *]");
          break;

        case 5:
        default:
          result = result.concat(" [", matt1.toString(), " ", matt2.toString(), " ", matt3.toString(), " ", matt4.toString(), " *]");
          break;
      }
      setPanelDivision(result);
      setErrMsg(null);
      props.onChange(result);
    }
    setIsOpen(false);    
  };

  function validateData(numParts) 
  {
    switch(Number(numParts))
    {
      case 1:
        return true;

      case 2:
        return  !isNaN(matt1) && Number(matt1) >= 300;
        
      case 3:
        return  !isNaN(matt1) && !isNaN(matt2) && Number(matt1) >= 300 && Number(matt2) >= 300;        

      case 4:
        return  !isNaN(matt1) && !isNaN(matt2) && !isNaN(matt3) && Number(matt1) >= 300 && Number(matt2) >= 300 && Number(matt3) >= 300;

      case 5:
      default:
        return  !isNaN(matt1) && !isNaN(matt2) && !isNaN(matt3) && !isNaN(matt4) && Number(matt1) >= 300 && Number(matt2) >= 300 && Number(matt3) >= 300 && Number(matt4) >= 300;
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Button 
        variant="outlined"
        size="small"
        startIcon={<Avatar variant="square" src={imageNumPanelParts[numPanelParts]} sx={{ width: 20, height: 20 }} />}
        onClick={handleClickOpen}>
          {panelDivision}
      </Button>

      <Modal sx={{ display: "flex", justifyContent: "flex-end" }} open={isOpen}>
        <Card sx={{height: "100%", overflowY: "auto"}}>
          <CardHeader
            action={
              <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                <IconButton onClick={handleClickClose} size="large">
                  <CheckBoxIcon style={{ color: green[500] }} />
                </IconButton>
              </Tooltip>
            }
            title={t('changePanelDivision.title')}
          />
          <CardContent className={classes.cardContents}>
            
            <Typography sx={{marginLeft:'0.5em'}}>Antal paneler på längden</Typography>

            <Grid container direction="column">
              <Grid item>
                  <IatCustomSelect value={numPanelParts}  onChange={handleNumPanelPartChanged}>
                      {panelPartsOptions.map((c) => (
                          <IatStyledOption key={c.code} value={c.code}>
                          <img
                              loading="lazy"
                              width="20"
                              src={imageNumPanelParts[c.code]}
                              alt={`${c.label}`}
                          />
                          {c.label}
                          </IatStyledOption>
                      ))}
                  </IatCustomSelect>
              </Grid>

              {numPanelParts !== null && parseInt(numPanelParts) > 1 && (
                  <Grid item>
                      <IatNumInputSlider
                          label={t('changePanelDivision.dimension') + " 1: "}
                          min={300}
                          max={6000}
                          step={150}
                          value={matt1}
                          onChange={handleSliderChanged}
                          />
                  </Grid>
              )}

              {numPanelParts !== null && parseInt(numPanelParts) > 2 && (
                  <Grid item>
                      <IatNumInputSlider
                          label={t('changePanelDivision.dimension') + " 2: "}
                          min={300}
                          max={6000}
                          step={150}
                          value={matt2}
                          onChange={handleSliderChanged}
                          />
                  </Grid>
              )}

              {numPanelParts !== null && parseInt(numPanelParts) > 3 && (
                  <Grid item>
                      <IatNumInputSlider
                          label={t('changePanelDivision.dimension') + " 3: "}
                          min={300}
                          max={6000}
                          step={150}
                          value={matt3}
                          onChange={handleSliderChanged}
                          />
                  </Grid>
              )}

              {numPanelParts !== null && parseInt(numPanelParts) > 4 && (
                  <Grid item>
                      <IatNumInputSlider
                          label={t('changePanelDivision.dimension') + " 4: "}
                          min={300}
                          max={6000}
                          step={150}
                          value={matt4}
                          onChange={handleSliderChanged}
                          />
                  </Grid>
              )}

              {numPanelParts !== null && parseInt(numPanelParts) > 1 && (
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <Typography sx={{margin:'0.5em'}}>{labelLastText}</Typography>
                      <Typography>*</Typography>
                    </Stack>                        
                  </Grid>
              )}

              {errMsg !== null && (
                  <Grid item>
                      <Box sx ={{mt:3}}>
                        <Typography sx={{color:'red', margin:'0.5em'}}>{errMsg}</Typography>
                      </Box>
                  </Grid>
              )}
            </Grid>              

          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(ChangePanelDivision);