import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = {           
    fittedImage:{
        width: "900px", 
        height: "300px",
        objectFit: "contain"
    }
};

const imageNames = {
    deleteRoom: require("../images/DeleteRoom.png")
};

function RoomIndex(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { classes } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [roomUnitsIndexData, setRoomUnitsIndexData] = useState("null");

    useEffect(() => {
        if (!isLoaded) {
            let dtoData = JSON.stringify({
                roomUnitStack: props.designData.roomUnitStack,
                previewSizes: props.previewSizes,
                imageMapData: null,
                description: null
            });
            fetch("/api/CfzWebApi/GetAllRoomUnitIndexData", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: dtoData
            })
                .then(res => res.json())
                .then(result => {
                    setRoomUnitsIndexData(result);
                    setIsLoaded(true);
                });
        }
    }, [isLoaded, props.designData, props.previewSizes]);

    if (!isLoaded) return null;

    const handleDeleteRoomUnit = roomUnitID => {
        setIsLoaded(false);
        props.onDeleteRoomUnitClicked(roomUnitID);
    };

    return (
        <div className={classes.root}>
            {roomUnitsIndexData && (
                <React.Fragment>
                    <AppBar sx = {{ backgroundColor: "black",  position: "relative" }} elevation={0}>
                        <Toolbar>
                            <Typography
                                variant="button"
                                display="block"
                                sx = {{ marginLeft: theme.spacing(2), flex: 1 }}                                
                            >
                                {t('roomIndex.title')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Typography sx=  {{ backgroundColor: "black", color: "white" }} />
                    <List>
                        {roomUnitsIndexData.map(roomUnitsData => (
                            <ListItem button key={roomUnitsData.id}>
                                <ListItemText
                                    disableTypography={true}
                                    primary={roomUnitsData.description}
                                    secondary={
                                        <React.Fragment>
                                            <div>
                                                {roomUnitsData.createdDate}
                                            </div>
                                            <br />
                                            <Tooltip
                                                title={t('roomIndex.openToolTip')}
                                                placement="bottom"
                                            >
                                                <div
                                                    style={{
                                                        height: "300px",
                                                        width: "900px"
                                                    }}
                                                >
                                                    <img className={classes.fittedImage}
                                                        src={roomUnitsData.image2dsrc}
                                                        alt=""
                                                    />
                                                </div>
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                    onClick={() =>
                                        props.onOpenRoomUnitClick(
                                            roomUnitsData.id
                                        )
                                    }
                                />

                                {roomUnitsIndexData.length > 1 && (
                                    <Tooltip title={t('roomIndex.deleteToolTip')}>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label="Delete"
                                                onClick={() =>
                                                    handleDeleteRoomUnit(
                                                        roomUnitsData.id
                                                    )
                                                }
                                                size="large">
                                                <img
                                                    src={imageNames.deleteRoom}
                                                    alt=""
                                                />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </Tooltip>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            )}
        </div>
    );
}

export default withStyles(styles)(RoomIndex);
