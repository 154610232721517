import React from "react";
import withStyles from '@mui/styles/withStyles';
import CheckButton from "./CheckButton";

const styles = {
    divStyle: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: 2
    },
    floorTypeImgage: {
        border: "1px solid #000",
        borderRadius: 4
    },
    checkButton: {}
};

const imageNames = {
    1: require("../images/1.png"),
    2: require("../images/2.png"),
    3: require("../images/3.png"),
    4: require("../images/4.png")
};

const disabledImageNames = {
    1: require("../images/1-disabled.png")
};

function FloorTypeImage(props) {
    const { classes } = props;

    const isSelected =
        props.selectedFloorType &&
        props.selectedFloorType.substring(0, 1) === props.floorType;

    if (props.roomType === "Frys" && props.floorType === "1") {
        return (
            <div className={classes.divStyle}>
                <img
                    alt=""
                    className={classes.floorTypeImgage}
                    src={disabledImageNames[props.floorType]}
                    style={{
                        cursor: "not-allowed",
                        pointerEvents: "visible"
                    }}
                />
            </div>
        );
    }

    return (
        <div className={classes.divStyle}>
            <img
                alt=""
                className={classes.floorTypeImgage}
                src={imageNames[props.floorType]}
                onClick={() => props.onClick(props.floorType)}
                style={{
                    cursor: "pointer",
                    pointerEvents: "visible"
                }}
            />
            <div
                style={{
                    pointerEvents: "none",
                    opacity: 1,
                    position: "absolute",
                    height: "36px",
                    width: "100%"
                }}
            >
                <CheckButton
                    isSelected={isSelected}
                    isSelectable={true}
                    color={"rgba(255, 255, 255, 0.7)"}
                    selectedColor={"#4285f4"}
                    hoverColor={"rgba(255, 255, 255, 1)"}
                    paddingLeft={"70px"}
                    paddingTop={"1px"}
                />
            </div>
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(FloorTypeImage);
