import React, { useState, useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import Stack from '@mui/material/Stack';
import "./css/ZoomSlider.css";

const styles = theme => ({
    button: {
        borderRadius: "30%",
        margin: 1,
        outline: "none",
        width: "150px"
    },
    label: {
        // Aligns the content of the button vertically.
        flexDirection: "column",
        fontSize: "0.8125rem"
    },    
});

function CustomToolbarZoomSlider(props) {
    const { classes } = props;
    const [disabled, setDisabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (!isLoaded) {
            switch (props.previewType) {
                case "viewPlan2d":
                    setDisabled(props.previewToolbarStatus.zoom2d.disabled);
                    setValue(props.zoomLevels.image2d.value);
                    break;

                case "viewSection":
                    setDisabled(props.previewToolbarStatus.zoom2d.disabled);
                    setValue(props.zoomLevels.section.value);
                    break;

                default:
                    setDisabled(true);
                    break;
            }
            setIsLoaded(true);
        }
    }, [
        isLoaded,
        props.previewType,
        props.previewToolbarStatus,
        props.zoomLevels
    ]);

    if (!props.previewToolbarStatus.viewPlan2d.visible) return null;
    if (!isLoaded) return null;

    return (
        <Tooltip title={props.title} placement="bottom-start">
            <Button
                classes={{ root: classes.button, label: classes.label }}
                variant="text"
                color="primary"
                disabled={disabled}
                disableRipple={true}
                disableFocusRipple={true}
            >
                <Stack sx={{width:"100%"}}>
                    <SliderComponent
                        id="slider"
                        min={100}
                        max={1600}
                        showButtons={true}
                        step={100}
                        value={value}
                        change={args => props.onZoom(args.value)}
                    />
                    {props.label}
                </Stack>
            </Button>
        </Tooltip>
    );
}

export default withStyles(styles, { withTheme: true })(CustomToolbarZoomSlider);
