import React, { useState, useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CssBaseline from "@mui/material/CssBaseline";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';
import { L10n } from "@syncfusion/ej2-base";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";

const styles = {    
  pdfViewer: {
    height: "900px", 
    minWidth: 945,
  },
};

function PdfViewer(props) {
  const { t } = useTranslation();
  const { classes } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataPdfBase64, setDataPdfBase64] = useState(null);

  useEffect(() => {
    if (!isLoaded) {
      L10n.load({
        "sv-SE": {
          PdfViewer: {
            PdfViewer: "PDF-visare",
            Annotation: "Lägg till eller redigera kommentarer",
            "Annotation Edit text": "Redigera kommentar",
            Apply: "Tillämpa",
            Automatic: "Automatisk",
            Bookmarks: "Bokmärken",
            Cancel: "Annullera",
            "Color edit": "Ändra färg",
            Copy: "Kopia",
            Cut: "Skära",
            Delete: "Radera annotering",
            Download: "Ladda ner",
            "Download file": "Ladda ner fil",
            "Enter Password":
              "Detta dokument är lösenordsskyddat. Ange ett lösenord.",
            "File Corrupted": "Filen är skadad",
            "File Corrupted Content": "Filen är skadad och kan inte öppnas.",
            "Find in document": "Sök i dokumentet",
            "Fit Page": "Anpassa till sida",
            "Fit Width": "Anpassa till bredd",
            "Go To First Page": "Visa första sidan",
            "Go To Last Page": "Visa sista sidan",
            GoToPage: "Gå till sidan",
            Highlight: "Markera text",
            "Highlight context": "Markera",
            "Invalid Password": "Fel lösenord. Var god försök igen.",
            "Match case": "Matcha gemer/versaler",
            "Next Page": "Visa nästa sida",
            None: "Ingen",
            "No matches":
              "Sökning i dokumentet har slutförts. Inga fler matchningar hittades",
            "No Text Found": "Ingen text hittades",
            OK: "OK",
            Opacity: "Opacitet",
            "Opacity edit": "Ändra opacitet",
            Open: "Öppna fil",
            "Open text": "Öppen",
            "Page Number": "Aktuellt sidnummer",
            "Page Thumbnails": "Sidminiatyrer",
            Panning: "Panoreringsläge",
            "Pan text": "Panorera",
            "Password Protected": "Lösenord krävs",
            "Previous Page": "Visa föregående sida",
            Print: "Skriv ut dokument",
            "Print text": "Skriva ut",
            Redo: "Göra om",
            "Selection text": "Urval",
            "Search text": "Sök",
            "Server error":
              "Webbtjänsten lyssnar inte. PDF Viewer beror på webbtjänsten för alla dess funktioner. Starta webbtjänsten för att fortsätta.",
            Strikethrough: "Genomgående text",
            "Strikethrough context": "Stryk igenom",
            "Text Search": "Sök text",
            "Text Selection": "Textvalverktyg",
            Underline: "Understruken text",
            "Underline context": "Understrykning",
            Undo: "Ångra",
            Zoom: "Zoom",
            "Zoom In": "Zooma in",
            "Zoom in text": "Zooma in",
            "Zoom Out": "Zooma ut",
            "Zoom out text": "Zooma ut",
            "First text": "Första sidan",
            "Previous text": "Föregående sida",
            "Next text": "Nästa sida",
            "Last text": "Sista sidan",
            "Line Thickness": "Linjetjocklek",
            "Line Properties": "Linjeegenskaper",
            "Start Arrow": "Starta pilen",
            "End Arrow": "Slutpilen",
            "Line Style": "Line Style",
            "Fill Color": "Fyllnadsfärg",
            "Line Color": "Linjefärg",
            Paste: "Klistra",
            "Delete Context": "Radera",
            Properties: "Egenskaper",
            "Add Stamp": "Lägg till stämpel",
            "Add Shapes": "Lägg till former",
            "Stroke edit": "Ändra slagfärg",
            "Change thickness": "Ändra kanttjocklek",
            "Add line": "Lägg till linje",
            "Add arrow": "Lägg till pil",
            "Add rectangle": "Lägg till rektangel",
            "Add circle": "Lägg till cirkel",
            "Add polygon": "Lägg till polygon",
            "Add Comments": "Lägg till kommentarer",
            Comments: "kommentarer",
            "No Comments Yet": "Inga kommentarer än",
            Accepted: "Accepterad",
            Completed: "Avslutad",
            Cancelled: "Avbryten",
            Rejected: "avvisade",
            "Leader Length": "Ledarlängd",
            "Scale Ratio": "Skala förhållande",
          },
        },
      });

      let dtoData = JSON.stringify({
        roomUnitStack: props.designData.roomUnitStack,
        previewSizes: props.previewSizes,
      });

      fetch("/api/CfzWebApi/GeneratePdfPrintDocument", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: dtoData,
      })
        .then((res) => res.json())
        .then((result) => {
          setIsLoaded(true);
          setDataPdfBase64(result);
        });
    }
  }, [isLoaded, props.designData, props.previewSizes]);

  if (dataPdfBase64 == null) return null;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
        <Card sx={{height: "100%", overflowY: "auto"}}>
          <CardHeader
            action={
              <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                <IconButton onClick={props.onClose} size="large">
                  <CheckBoxIcon style={{ color: green[500] }} />
                </IconButton>
              </Tooltip>
            }
            title={t('pdfPrint.print')}
          />
          <CardContent className={classes.cardContents}>
            <PdfViewerComponent
              id="pdfContainer"
              className={classes.pdfViewer}
              documentPath={dataPdfBase64}
              locale="sv-SE"
              serviceUrl="https://fbckpdfviewer.inadire.se/api/PdfViewer/"
            >
              <Inject
                services={[
                  Toolbar,
                  Magnification,
                  Navigation,
                  ThumbnailView,
                  Print,
                  TextSelection,
                  TextSearch,
                ]}
              />
            </PdfViewerComponent>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(PdfViewer);
