import React from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = { };

const ButtonEx = styled(Button)(({ theme }) => ({  
  borderRadius: "30%",
  margin: 1,
  outline: "none",
  "&:focus": { outline: 0 },
}));

const imageNames = {
  partitionWallX: require("../images/PartionWallAddVert.png"),
  partitionWallY: require("../images/PartionWallAddHori.png"),
  partitionWallRemove: require("../images/PartionWallRemove.png"),
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PartionWallDialog(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Dialog
        open={true}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.root}>
          {t('partionWallDialog.title')}
          <IconButton
            aria-label="Close"
            sx={{position: "absolute", right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500]}}
            onClick={() => props.onClick("partitionWallCancel")}
            size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText align="center">
            {t('partionWallDialog.text')}
          </DialogContentText>

          <DialogActions>
            <ButtonEx              
              color="primary"
              type="submit"
              variant="text"
              onClick={() => props.onClick("partionWallAddX")}
            >
              <Stack>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <img src={imageNames["partitionWallX"]} alt="" />
                </Grid>
                {t('partionWallDialog.vertically')}
              </Stack>
            </ButtonEx>
            <ButtonEx
              classes={{ root: classes.button, label: classes.label }}
              color="primary"
              variant="text"
              onClick={() => props.onClick("partionWallAddY")}
            >
              <Stack>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <img src={imageNames["partitionWallY"]} alt="" />
                </Grid>
                {t('partionWallDialog.horizontally')}
              </Stack>
            </ButtonEx>
          </DialogActions>
        </DialogContent>
        {props.isHasPartionWalls && (
          <DialogContent dividers>
            <DialogContentText align="center">eller</DialogContentText>
            <DialogActions>
              <ButtonEx
                classes={{ root: classes.button, label: classes.label }}
                color="primary"
                variant="text"
                onClick={() => props.onClick("partionWallShowRemoveable")}
              >
                <Stack>
                  <Grid direction="row" justifyContent="center" alignItems="center">
                    <img src={imageNames["partitionWallRemove"]} alt="" />
                  </Grid>
                  {t('partionWallDialog.remove')}
                </Stack>
              </ButtonEx>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(PartionWallDialog);
