import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import produce from "immer";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import BeforeLeave from "./BeforeLeave";
import ChangeRoomProps from "./ChangeRoomProps";
import ChangeDoorProps from "./ChangeDoorProps";
import ChangeDimension from "./ChangeDimension";
import ChangeOptions from "./ChangeOptions";
import ChangeShelvings from "./ChangeShelvings";
import CornerChamferDialog from "./CornerChamferDialog"
import DockShowRoomDialog from "./DockShowRoomDialog";
import HelpInfo from "./HelpInfo";
import Login from "./Login";
import MyArchive from "./MyArchive";
import PartionWallDialog from "./PartionWallDialog";
import PdfViewer from "./PdfViewer";
import Preview from "./Preview";
import PreviewToolbar from "./PreviewToolbar";
import RequestReceived from "./RequestReceived";
import RoomIndex from "./RoomIndex";
import RoomPosMarkTable from "./RoomPosMarkTable";
import RoomUnitDescriptionDialog from "./RoomUnitDescriptionDialog";
import RoomUnitHeadline from "./RoomUnitHeadline";
import SaveRoomUnit from "./SaveRoomUnit";
import SendRequest from "./SendRequest";
import ShowContactInfo from "./ShowContactInfo";

const drawerWidth = 300;
const queryString = require("query-string");


const styles = (theme) => ({ });

const imageNames = {
  Contact: require("../images/Contact.png"),
  FbLogotype: require("../images/logo_finnebacks_2012.png"),
  RenumberAutoIndexNumbers: require("../images/RenumberAutoIndexNumbers.png"),
  RoomDesign: require("../images/RoomDesign.png"),
  SaveRoomUnit: require("../images/SaveRoomUnit.png"),
  SendRequest: require("../images/SendRequest.png"),
  ShowAllRooms: require("../images/ShowAllRooms.png"),
  ShowMyArchive: require("../images/ShowMyArchive.png"),
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeaderEx = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// // const AppBarEx = styled(AppBar, {
// //   shouldForwardProp: (prop) => prop !== 'open',
// // })(({ theme, open }) => ({
// //   zIndex: theme.zIndex.drawer + 1,
// //   transition: theme.transitions.create(['width', 'margin'], {
// //     easing: theme.transitions.easing.sharp,
// //     duration: theme.transitions.duration.leavingScreen,
// //   }),
// //   ...(open && {
// //     marginLeft: drawerWidth,
// //     width: `calc(100% - ${drawerWidth}px)`,
// //     transition: theme.transitions.create(['width', 'margin'], {
// //       easing: theme.transitions.easing.sharp,
// //       duration: theme.transitions.duration.enteringScreen,
// //     }),
// //   }),
// // }));

const DrawerEx = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function RoomEditor(props) {
  const theme = useTheme();  
  const { t, i18n } = useTranslation();
  const { classes } = props;
  const [activeModule, setActiveModule] = useState("");
  const [nextActiveModule, setNextActiveModule] = useState("");
  const [designData, setDesignData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [numRooms, setNumRooms] = useState(1);
  const [previewType, setPreviewType] = useState("viewPlan2d");
  const [activeSectionMark, setActiveSectionMark] = useState("");

  const [runParams, setRunParams] = useState({
    isStaff: false,
    sessionKey: null,    
  });

  const [user, setUser] = useState({
    company: "",
    name: "",
    phone: "",
    email: "",
    access_token: "",
    token_type: "",
    expires_in: 0,
    userRole: "",
  });

  const [previewSizes] = useState({
    image2d: { height: 800, width: 800 },
    image3d: { height: 800, width: 800 },
    section: { height: 900, width: 900 },
  });

  const [previewZoomLevels, setPreviewZoomLevels] = useState({
    image2d: { value: 800 },
    image3d: { value: 800 },
    section: { value: 500 },
  });

  const [roomUnitDescription, setRoomUnitDescription] = useState({
    description: "",
    isOpen: false,
    isRequestNewRoom: false,
    isRename: false,
  });

  const [dockRoomTypeDialog, setDockRoomTypeDialog] = useState({
    isOpen: false,
  });

  const [partionWallDialog, setPartionWallDialog] = useState({
    isOpen: false,
    isHasPartionWalls: false,
  });

  const [cornerChamferDialog, setCornerChamferDialog] = useState({
    isOpen: false,
    isHasCornerChamfer: false,
  });

  const [mainToolbarStatus, setMainToolbarStatus] = useState({
    all: { disabled: false },
  });

  const [mainToolbarVisibility, setMainToolbarVisibility] = useState({
    showRoomDesign: { isHidden: false },
    showRoomIndex: { isHidden: false },
    renumberAutoIndexNumbers: { isHidden: false },
    sendRequest: { isHidden: false },
    showMyArchive: { isHidden: false },
    saveRoomUnit: { isHidden: false },
    showContactInfo: { isHidden: false },
  });

  const [previewToolbarStatus, setPreviewToolbarStatus] = useState({
    newRoomUnit: { disabled: false, isCheckButton: false, isHidden: false, visible: true },
    showSurroundingBuilding: { disabled: false, isCheckButton: true, checked: false, visible: true },
    viewPlan2d: { disabled: false, isCheckButton: false, visible: true },
    viewSection: { disabled: false, isCheckButton: false, visible: true },
    dockShowRooms: { disabled: false, isCheckButton: false, visible: true },
    dockRemoveRoom: { disabled: false, isCheckButton: false, visible: true },
    cancelDockRoom: { disabled: false, isCheckButton: false, visible: false },
    partitionWallAddRemove: { disabled: false, isCheckButton: false, visible: true },
    partitionWallCancel: { disabled: false, isCheckButton: false, visible: false },
    cornerChamferAddRemove: { disabled: false, isCheckButton: false, visible: false },
    cornerChamferCancel: { disabled: false, isCheckButton: false, visible: false },
    selectSectionMark: { disabled: false, isCheckButton: false, visible: true  },
    endSelectSectionMark: { disabled: false, isCheckButton: false, visible: false },
    zoom2d: { disabled: false, isCheckButton: false, visible: true },
    showPdfViewer: { disabled: false, isCheckButton: false, visible: true },
    showOptions: { disabled: false, isCheckButton: false, visible: true },
    showHelp: { disabled: false, isCheckButton: false, visible: true, isActive: true },
  });

  const [changeRoomData, setChangeRoomData] = useState({
    autoPosNumber: null,
    floorBoard: null,
    floorDir: null,
    floorDivision: null,
    floorType: null,
    hasRoof: null,
    invHeight: null,
    isActive: false,    
    isAllowSpecialDim: false,
    isHasHeatCable: false,
    isHasLeca: false,
    isHasTryckUtVentil: false,
    isOriginMoveable: false,
    originOffset: null,
    panelMaterial: null,
    panelThickness: null,
    roofDir: null,
    roofDivision: null,
    roomID: null,
    roomLabel: null,
    roomType: null,
  });

  const [changeDoorData, setChangeDoorData] = useState({
    doorID: null,    
    isActive: false,    
    roomID: null,    
  });

  const [changeDimData, setChangeDimData] = useState({
    dimPrefix: null,
    isActive: false,
    maxValue: null,
    minValue: null,
    oldValue: "0",
    propertyName: null,
    roomID: null,
    isAllowSpecialDim: false,
    step: null,
  });

  const [changeShelvingData, setChangeShelvingData] = useState({
    isActive: false,
    numShelfLevels: null,
    roomID: null,
    shapeCode: null,
    shelfMaterial: null,
    shelfType: null,
    shelvingUnitID: null,
    shelvesHeight: null,
    shelvesWidths: null,
  });

  const [showPdfViewer, setShowPdfViewer] = useState({
    isActive: false,
  });

  const [showOptions, setShowOptions] = useState({
    isActive: false,
  });  

  useEffect(() => {
    if (!isLoaded) {      
      let sessionKey = null;
      let language = null;
      const parsed = queryString.parse(window.location.search);      
      if ('sessionKey' in parsed){ 
        sessionKey = parsed.sessionKey;
      }
      
      if ('lang' in parsed){         
        language = parsed.lang;
      }

      if (sessionKey === null) {
        createNewRoomUnitStack("", "Kyl", true, language);
      } else {
        startWanErpDesign(sessionKey);
      }            
    }    
  });  

  const handleDrawerOpen = () => {    
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleLoginClicked = () => {
    closeWindows();
    setNextActiveModule(activeModule);
    setActiveModule("login");
  };

  const handleLogoutClicked = () => {
    setUser({
      company: "",
      name: "",
      phone: "",
      email: "",
      access_token: "",
      token_type: "",
      expires_in: 0,
      userRole: "",
    });
    switch (activeModule) {
      case "showRoomDesign":
      case "showRoomIndex":
      case "showContactInfo":
        break;

      default:
        setActiveModule("showRoomDesign");
        setNextActiveModule("");
        break;
    }
  };

  const handleLoginOk = (data) => {
    setUser({
      company: data.company,
      name: data.name,
      phone: data.phone,
      email: data.email,
      access_token: data.access_token,
      token_type: data.token_type,
      expires_in: data.expires_in,
      userRole: data.user,
    });
    setActiveModule(nextActiveModule);
    setNextActiveModule("");
  };

  const handleLoginCancel = () => {
    setActiveModule("showRoomDesign");
    setNextActiveModule("");
  };

  const handleMainToolbarClicked = (value) => {
    switch (value) {
      case "showRoomDesign":
        setActiveModule(value);
        doShowRoomUnitDescription(designData.roomUnitStack);
        break;

      case "login":
      case "showContactInfo":
      case "showRoomIndex":
      case "sendRequest":
        closeWindows();
        setActiveModule(value);
        break;

      case "renumberAutoIndexNumbers":
        closeWindows();
        doRenumberAutoIndexNumbers();
        break;

      case "openSavedRoom":
      case "saveRoomUnit":
      case "showMyArchive":
        closeWindows();
        setNextActiveModule(user.access_token !== "" ? "" : value);
        setActiveModule(user.access_token !== "" ? value : "login");
        break;

      default:
        break;
    }
  };


  const startWanErpDesign = (sessionKey) => {
    let dtoData = JSON.stringify({
      roomUnitStack: null,
      fbckSessionKey: sessionKey,       
      previewSizes: previewSizes,
      imageMapData: null,
      description: null,
      roomType: null,
    });
	  console.log(dtoData);
    fetch("/api/CfzWebApi/StartWanErpDesign", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.status + " " + res.statusText);
        }
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setRunParams(
            produce((draft) => {
              draft.isStaff = true;
              draft.sessionKey = sessionKey;              
            })
          );
          afterNewDesignDataFromWebApi(result);
          hasDookedRooms(result.roomUnitStack);
          setActiveModule("showRoomDesign");
          afterNewRoomFromWebApi(result, null);          
          setMainToolbarVisibility(
            produce((draft) => {
              draft.sendRequest.isHidden = true;
              draft.showMyArchive.isHidden = true;
              draft.saveRoomUnit.isHidden = true;
              draft.showContactInfo.isHidden = true;
            })
          );        
          setPreviewToolbarStatus(
            produce((draft) => {
              draft.newRoomUnit.isHidden = true;
            })
          );
        },
        (error) => {
          setIsLoaded(true);
          setDesignData(null);
          setError(error);
        }
      )
      .catch((error) => setError(error));
  };

  const finishWanErpDesign = () => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      fbckSessionKey: runParams.sessionKey,       
      previewSizes: null,
      imageMapData: null,
      description: null,
      roomType: null,
    });
    fetch("/api/CfzWebApi/FinishWanErpDesign", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(res.status + " " + res.statusText);
      }
    })    
    .then(
      (result) => {  
        if (result === null || !result.succeeded) {
          throw new Error("Ett fel har inträffat");
        } 
        if (result.wanErpReturnUri === null || result.wanErpReturnUri.length === 0) {
          window.chrome.webview.postMessage('FinishWanErpDesign');
        }
        else {
          window.location.replace(result.wanErpReturnUri);
        }
      },
      (error) => {        
        setError(error);
      }
    )
    .catch((error) => setError(error));
  };

  const cancelWanErpDesign = () => {
    let dtoData = JSON.stringify({
      roomUnitStack: null,
      fbckSessionKey: runParams.sessionKey,       
      previewSizes: null,
      imageMapData: null,
      description: null,
      roomType: null,
    });
    fetch("/api/CfzWebApi/CancelWanErpDesign", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(res.status + " " + res.statusText);
      }
    })    
    .then(
      (result) => {  
        if (result === null || !result.succeeded) {
          throw new Error("Ett fel har inträffat");
        } 
        if (result.wanErpReturnUri === null || result.wanErpReturnUri.length === 0) {
          window.chrome.webview.postMessage('CancelWanErpDesign');
        }
        else {
          window.location.replace(result.wanErpReturnUri);
        }
      },
      (error) => {        
        setError(error);
      }
    )
    .catch((error) => setError(error));    
  };

  const createNewRoomUnitStack = (description, roomType, isStartupCall, language) => {
    let dtoData = JSON.stringify({
      roomUnitStack: null,      
      previewSizes: previewSizes,
      imageMapData: null,
      description: description,
      fbckSessionKey: 0,
      language: language,
      roomType: roomType,
    });	  
    fetch("/api/CfzWebApi/CreateNewRoomUnitStack", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.status + " " + res.statusText);
        }
      })
      .then(
        (result) => {
          setIsLoaded(true);          
          afterNewDesignDataFromWebApi(result);
          hasDookedRooms(result.roomUnitStack);
          setActiveModule("showRoomDesign");
          if (isStartupCall) {
            if (language !== null){              
              i18n.changeLanguage(language);
            }
            setRoomUnitDescription({
              description: "",
              isOpen: true,
              isRequestNewRoom: false,
              isRename: false,
            });
          } else {
            afterNewRoomFromWebApi(result, null);
          }
        },
        (error) => {
          setIsLoaded(true);
          setDesignData(null);
          setError(error);
        }
      )
      .catch((error) => setError(error));
  };

  const createNewRoomUnit = (description, roomType) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      imageMapData: null,
      description: description,
      roomType: roomType,
    });
    fetch("/api/CfzWebApi/CreateNewUnitAndRoomCombination", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          afterNewDesignDataFromWebApi(result);
          hasDookedRooms(result.roomUnitStack);
          setActiveModule("showRoomDesign");
          afterNewRoomFromWebApi(result, null);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const updateRoomUnitDescription = (description, roomUnitID) => {
    let roomUnit = designData.roomUnitStack.RoomUnits.find(
      (w) => w.RoomUnit.ID === roomUnitID
    ).RoomUnit;

    roomUnit.Description = description;
  };

  const handleRoomUnitDescriptionChanged = (
    formData,
    isRequestNewRoom,
    isRename,
    roomType) => {
    if (isRequestNewRoom) {
      createNewRoomUnit(formData.roomUnitDescription, roomType);
    } else if (isRename) {
      updateRoomUnitDescription(
        formData.roomUnitDescription,
        designData.roomUnitStack.CurrentRoomUnitID
      );
      doShowRoomUnitDescription(designData.roomUnitStack);
    } else {
      createNewRoomUnitStack(formData.roomUnitDescription, roomType, false, designData.roomUnitStack.RunParams.Language);
    }
  };

  const handleRoomUnitDescriptionCanceled = () => {
    doShowRoomUnitDescription(designData.roomUnitStack);
  };

  const handleOpenRoomUnitClicked = (roomUnitID) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      roomUnitID: roomUnitID,
      roomID: null,
      roomType: null,
    });
    fetch("/api/CfzWebApi/SetCurrentRoomUnit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDesignData(result);
          setActiveModule("showRoomDesign");
          doShowRoomUnitDescription(result.roomUnitStack);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handleDeleteRoomUnitClicked = (roomUnitID) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      roomUnitID: roomUnitID,
      roomID: null,
      roomType: null,
    });
    fetch("/api/CfzWebApi/DeleteRoomUnit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          afterNewDesignDataFromWebApi(result);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handlePreviewToolbarClicked = (command) => {
    let dtoData = null;
    switch (command) {
      case "viewPlan2d":
      case "viewSection":
        closeWindows();
        setActiveSectionMark("");
        setPreviewType(command);
        break;

      case "newRoomUnit":
        closeWindows();
        setRoomUnitDescription({
          description: "",
          isOpen: true,
          isRequestNewRoom: true,
          isRename: false,
        });
        break;

      case "showSurroundingBuilding":
        closeWindows();
        toogleSurroundingBuilding();
        break;

      case "dockShowRooms":
        closeWindows();
        setDockRoomTypeDialog({ isOpen: true });
        break;

      case "dockShowRemoveableRooms":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          roomUnitID: null,
          roomID: null,
          roomType: "Kyl",
        });
        fetch("/api/CfzWebApi/DockShowRemoveableRooms", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.disabled = true;
                  draft.cancelDockRoom.visible = true;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "dockShowRoomDialogCancel":
        closeWindows();
        break;

      case "dockShowColdRoom":
      case "dockShowFreezeRoom":
        closeWindows();
        switch (command) {
          case "dockShowColdRoom":
            dtoData = JSON.stringify({
              roomUnitStack: designData.roomUnitStack,
              previewSizes: previewSizes,
              roomUnitID: null,
              roomID: null,
              roomType: "Kyl",
            });
            break;
          case "dockShowFreezeRoom":
            dtoData = JSON.stringify({
              roomUnitStack: designData.roomUnitStack,
              previewSizes: previewSizes,
              roomUnitID: null,
              roomID: null,
              roomType: "Frys",
            });
            break;

          default:
            break;
        }
        fetch("/api/CfzWebApi/DockShowPossibleRooms", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.visible = false;
                  draft.cancelDockRoom.visible = true;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "cancelDockRoom":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          roomUnitID: null,
          roomID: null,
          roomType: null,
        });
        fetch("/api/CfzWebApi/DockRoomCanceled", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
              hasDookedRooms(result.roomUnitStack);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = false;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = false;
                  draft.showSurroundingBuilding.diabled = false;
                  draft.viewPlan2d.disabled = false;
                  draft.viewSection.disabled =
                    result.imageMaps.imageMapSectionData === null;
                  draft.selectSectionMark.disabled = false;
                  draft.partitionWallAddRemove.disabled = false;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = false;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = false;
                  draft.dockRemoveRoom.disabled = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = false;
                  draft.showOptions.disabled = false;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "partitionWallAddRemove":
        closeWindows();
        let isHasPartionWalls = hasPartionWalls(designData.roomUnitStack.RoomUnits.find((w) => w.RoomUnit.ID === designData.roomUnitStack.CurrentRoomUnitID).RoomUnit.TopRoom);
        setPartionWallDialog({
          isOpen: true,
          isHasPartionWalls: isHasPartionWalls,
        });
        break;

      case "partionWallAddX":
      case "partionWallAddY":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          wallDivisionCode: command === "partionWallAddX" ? "X" : "Y",
        });
        fetch("/api/CfzWebApi/ShowPossibleNewPartitionWalls", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = true;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.visible = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "partionWallShowRemoveable":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          wallDivisionCode: null,
        });
        fetch("/api/CfzWebApi/ShowPossibleRemoveablePartitionWalls", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = true;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.visible = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "partitionWallCancel":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          wallDivisionCode: null,
        });
        fetch("/api/CfzWebApi/PartitionWallCanceled", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
              hasDookedRooms(result.roomUnitStack);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = false;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = false;
                  draft.showSurroundingBuilding.diabled = false;
                  draft.viewPlan2d.disabled = false;
                  draft.viewSection.disabled = result.imageMaps.imageMapSectionData === null;
                  draft.selectSectionMark.disabled = false;
                  draft.partitionWallAddRemove.disabled = false;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = false;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = false;
                  draft.dockRemoveRoom.disabled = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = false;
                  draft.showOptions.disabled = false;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "cornerChamferAddRemove":
        closeWindows();
        let isHasCornerChamfers = hasCornerChamfers(designData.roomUnitStack.RoomUnits.find((w) => w.RoomUnit.ID === designData.roomUnitStack.CurrentRoomUnitID).RoomUnit.TopRoom);
        setCornerChamferDialog({
          isOpen: true,
          isHasCornerChamfer: isHasCornerChamfers,
        });
        break;

      case "cornerChamferAddPerp":        
      case "cornerChamferAddSkew":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          chamferCode: command === "cornerChamferAddPerp" ? "Perp" : "Skew",
        });
        fetch("/api/CfzWebApi/ShowPossibleNewCornerChamfers", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = true;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.visible = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "cornerChamferShowRemoveable":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,          
        });
        fetch("/api/CfzWebApi/ShowPossibleRemoveableCornerChamfers", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = true;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.visible = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "cornerChamferCancel":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,          
        });
        fetch("/api/CfzWebApi/CornerChamferCanceled", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
              hasDookedRooms(result.roomUnitStack);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = false;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = false;
                  draft.showSurroundingBuilding.diabled = false;
                  draft.viewPlan2d.disabled = false;
                  draft.viewSection.disabled = result.imageMaps.imageMapSectionData === null;
                  draft.selectSectionMark.disabled = false;
                  draft.partitionWallAddRemove.disabled = false;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = false;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = false;
                  draft.dockRemoveRoom.disabled = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = false;
                  draft.showOptions.disabled = false;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "selectSectionMark":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          id: null,
        });
        fetch("/api/CfzWebApi/SelectSectionMarks", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setPreviewType("viewPlan2d");
              setDesignData(result);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = true;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = true;
                  draft.showSurroundingBuilding.diabled = true;
                  draft.viewPlan2d.disabled = true;
                  draft.viewSection.disabled = true;
                  draft.selectSectionMark.disabled = true;
                  draft.partitionWallAddRemove.disabled = true;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = true;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = true;
                  draft.dockRemoveRoom.visible = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = true;
                  draft.showPdfViewer.disabled = true;
                  draft.showOptions.disabled = true;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "endSelectSectionMark":
        closeWindows();
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          id: null,
        });
        fetch("/api/CfzWebApi/EndSelectSectionMarks", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
              hasDookedRooms(result.roomUnitStack);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = false;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = false;
                  draft.showSurroundingBuilding.diabled = false;
                  draft.viewPlan2d.disabled = false;
                  draft.viewSection.disabled =
                    result.imageMaps.imageMapSectionData === null;
                  draft.selectSectionMark.disabled = false;
                  draft.partitionWallAddRemove.disabled = false;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = false;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = false;
                  draft.dockRemoveRoom.disabled = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = false;
                  draft.showOptions.disabled = false;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "showPdfViewer":
        closeWindows();
        setShowPdfViewer({ isActive: true });
        break;

      case "showOptions":
        closeWindows();
        setShowOptions({ isActive: true });
        break;

      case "showHelp":
        setPreviewToolbarStatus(
          produce((draft) => {
            draft.showHelp.isActive = true;
          })
        );
        break;

      default:
        break;
    }
  };

  const handlePreviewZoomed = (zoomValue) => {
    switch (previewType) {
      case "viewSection":
        setPreviewZoomLevels(
          produce((draft) => {
            draft.section.value = zoomValue;
          })
        );
        break;

      case "viewPlan2d":
        setPreviewZoomLevels(
          produce((draft) => {
            draft.image2d.value = zoomValue;
          })
        );
        break;

      default:
        break;
    }
  };

  const handleCloseHelpInfo = () => {
    setPreviewToolbarStatus(
      produce((draft) => {
        draft.showHelp.isActive = false;
      })
    );
  };

  const afterNewDesignDataFromWebApi = (result) => {
    setError("");
    setDesignData(result);

    let description = result.roomUnitStack.RoomUnits.find(
      (w) => w.RoomUnit.ID === result.roomUnitStack.CurrentRoomUnitID
    ).RoomUnit.Description;

    let isShowSurroundingBuilding = result.roomUnitStack.RoomUnits.find(
      (w) => w.RoomUnit.ID === result.roomUnitStack.CurrentRoomUnitID
    ).RoomUnit.IsShowSurroundingBuilding;

    setRoomUnitDescription({
      description: description,
      isOpen: false,
      isRequestNewRoom: false,
      isRename: false,
    });
    setNumRooms(result.roomUnitStack.RoomUnits.length);

    setPreviewToolbarStatus(
      produce((draft) => {
        draft.showSurroundingBuilding.checked = isShowSurroundingBuilding;
        draft.viewSection.disabled =
          result.imageMaps.imageMapSectionData === null;
      })
    );
  };

  const hasDookedRooms = (roomUnitStack) => {
    let isHasDockedRooms =
      roomUnitStack.RoomUnits.find(
        (w) => w.RoomUnit.ID === roomUnitStack.CurrentRoomUnitID
      ).RoomUnit.TopRoom.DockedRooms.length > 0;

    setPreviewToolbarStatus(
      produce((draft) => {
        draft.dockRemoveRoom.visible = isHasDockedRooms;
      })
    );
  };

  const afterNewRoomFromWebApi = (result, prevPosMarkTable) => {
    let roomUnit = result.roomUnitStack.RoomUnits.find(
      (w) => w.RoomUnit.ID === result.roomUnitStack.CurrentRoomUnitID
    ).RoomUnit;

    let roomID =
      prevPosMarkTable === null
        ? roomUnit.TopRoom.ID
        : findAddedRoomID(prevPosMarkTable, result.posMarkTable);
    let room = findRoom(roomUnit.TopRoom, roomID);

    if (room !== undefined) {
      setChangeRoomData({
        autoPosNumber: room.AutoPositionNumber,
        floorBoard: room.FloorBoard,        
        floorDir: room.FloorType.substring(2),
        floorDivision: room.FloorDivision,
        floorType: room.FloorType,
        hasRoof: room.RoofDir === "--" ? "Nej" : "Ja",
        invHeight: room.InvHeight,
        isActive: true,        
        isAllowSpecialDim: room.IsAllowSpecialDim,
        isHasHeatCable: room.IsHasHeatCable,
        isHasLeca: room.IsHasLeca,
        isHasTryckUtVentil: room.IsHasTryckUtVentil,
        isOriginMoveable: room.ID !== room.RoomByReference.IdTopMainRoom,
        originOffset: room.OriginOffset,
        panelMaterial: room.Material,
        panelThickness: room.Thickness,
        roofDir: room.RoofDir,
        roofDivision: room.RoofDivision,
        roomID: room.ID,
        roomLabel: room.Label,
        roomType: room.RoomType,
      });
    }
  };

  const findRoom = (room, idToFind) => {
    if (room.ID === idToFind) {
      return room;
    }

    for (var i = 0; i < room.DockedRooms.length; i++) {
      let result = findRoom(room.DockedRooms[i], idToFind);
      if (result !== undefined) return result;
    }
  };

  const findAddedRoomID = (prevPosMarkTable, newPosMarkTable) => {
    let arrayNewPosMarks = newPosMarkTable.filter(
      (f) => !prevPosMarkTable.some((w) => w.roomID === f.roomID)
    );
    return arrayNewPosMarks[0].roomID;
  };

  const hasPartionWalls = (room) => {
    if (room.RoomsInsideRoom.length > 0)
      return true;

    for (var i = 0; i < room.DockedRooms.length; i++) {
      let result = hasPartionWalls(room.DockedRooms[i]);
      if (result !== undefined) return result;
    }
  };

  const hasCornerChamfers = (room) => {    
  };

  const closeWindows = () => {
    setChangeRoomData({
      isActive: false,
    });

    setChangeDoorData({
      isActive: false,
    });

    setChangeDimData({
      isActive: false,
    });

    setChangeShelvingData({
      isActive: false,
    });

    setDockRoomTypeDialog({ isOpen: false });
    setPartionWallDialog({ isOpen: false, isHasPartionWalls: false });
    setCornerChamferDialog({ isOpen: false, isHasCornerChamfer: false });
  };

  const doShowRoomUnitDescription = (roomUnitStack) => {
    let description = roomUnitStack.RoomUnits.find(
      (w) => w.RoomUnit.ID === roomUnitStack.CurrentRoomUnitID
    ).RoomUnit.Description;
    setRoomUnitDescription({
      description: description,
      isOpen: false,
      isRequestNewRoom: false,
      isRename: false,
    });
  };

  const doRenumberAutoIndexNumbers = () => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
    });

    fetch("/api/CfzWebApi/RenumberAutoIndexNumbers", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDesignData(result);
          setActiveModule("showRoomDesign");
        },
        (error) => {
          setError(error);
        }
      );
  };

  // const redrawPreview = () => {
  //     let dtoData = JSON.stringify({
  //         roomUnitStack: designData.roomUnitStack,
  //         previewSizes: previewSizes
  //     });

  //     fetch("/api/CfzWebApi/Redraw", {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: dtoData
  //     })
  //         .then(res => res.json())
  //         .then(
  //             result => {
  //                 setDesignData(result);
  //             },
  //             error => {
  //                 setError(error);
  //             }
  //         );
  // };

  const toogleSurroundingBuilding = () => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      id: "SurroundingBuilding",
    });

    fetch("/api/CfzWebApi/ToogleVisible", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          afterNewDesignDataFromWebApi(result);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handlePreviewClicked = (area) => {
    let data = JSON.parse(area.name);
    let prevPosMarkTable = designData.posMarkTable;
    let dtoData = null;
    closeWindows();    

    switch (data.key) {
      case "AddPossibleRoom":
      case "RemoveDockedRoom":
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          roomUnitID: null,
          roomID: data.roomID,
          roomType: null,
          endOnFinish: data.endOnFinish,
        });
        switch (data.key) {
          case "AddPossibleRoom":
            fetch("/api/CfzWebApi/AddPossibleRoom", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: dtoData,
            })
              .then((res) => res.json())
              .then(
                (result) => {
                  setDesignData(result);
                  hasDookedRooms(result.roomUnitStack);
                  setMainToolbarStatus(
                    produce((draft) => {
                      draft.all.disabled = false;
                    })
                  );
                  setPreviewToolbarStatus(
                    produce((draft) => {
                      draft.newRoomUnit.disabled = false;
                      draft.showSurroundingBuilding.diabled = false;
                      draft.viewPlan2d.disabled = false;
                      draft.viewSection.disabled =
                        result.imageMaps.imageMapSectionData === null;
                      draft.selectSectionMark.disabled = false;
                      draft.partitionWallAddRemove.disabled = false;
                      draft.partitionWallCancel.visible = false;
                      draft.cornerChamferAddRemove.disabled = false;
                      draft.cornerChamferCancel.visible = false;
                      draft.dockShowRooms.disabled = false;
                      draft.dockRemoveRoom.disabled = false;
                      draft.cancelDockRoom.visible = false;
                      draft.endSelectSectionMark.visible = false;
                      draft.showPdfViewer.disabled = false;
                      draft.showOptions.disabled = false;
                    })
                  );
                  afterNewRoomFromWebApi(result, prevPosMarkTable);
                },
                (error) => {
                  setError(error);
                }
              );
            break;

          case "RemoveDockedRoom":
            fetch("/api/CfzWebApi/RemoveDockedRoom", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: dtoData,
            })
              .then((res) => res.json())
              .then(
                (result) => {
                  setDesignData(result);
                  if (data.endOnFinish) {
                    hasDookedRooms(result.roomUnitStack);
                    setMainToolbarStatus(
                      produce((draft) => {
                        draft.all.disabled = false;
                      })
                    );
                    setPreviewToolbarStatus(
                      produce((draft) => {
                        draft.newRoomUnit.disabled = false;
                        draft.showSurroundingBuilding.diabled = false;
                        draft.viewPlan2d.disabled = false;
                        draft.viewSection.disabled =
                          result.imageMaps.imageMapSectionData === null;
                        draft.selectSectionMark.disabled = false;
                        draft.partitionWallAddRemove.disabled = false;
                        draft.partitionWallCancel.visible = false;
                        draft.cornerChamferAddRemove.disabled = false;
                        draft.cornerChamferCancel.visible = false;
                        draft.dockShowRooms.disabled = false;
                        draft.dockRemoveRoom.disabled = false;
                        draft.cancelDockRoom.visible = false;
                        draft.endSelectSectionMark.visible = false;
                        draft.showPdfViewer.disabled = false;
                        draft.showOptions.disabled = false;
                      })
                    );
                  }
                },
                (error) => {
                  setError(error);
                }
              );
            break;

          default:
            break;
        }
        break;

      case "ToogleSectionMark":
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          id: data.toogleID,
        });
        fetch("/api/CfzWebApi/ToogleSectionMark", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "ShowHeightSection":
        setActiveSectionMark(data.sectionPosMark);
        setPreviewType("viewSection");
        break;

      case "AddPartitionWall":
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          wallID: data.wallID,
        });
        fetch("/api/CfzWebApi/AddPartitionWall", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
              hasDookedRooms(result.roomUnitStack);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = false;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = false;
                  draft.showSurroundingBuilding.diabled = false;
                  draft.viewPlan2d.disabled = false;
                  draft.viewSection.disabled =
                    result.imageMaps.imageMapSectionData === null;
                  draft.selectSectionMark.disabled = false;
                  draft.partitionWallAddRemove.disabled = false;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = false;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = false;
                  draft.dockRemoveRoom.disabled = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = false;
                  draft.showOptions.disabled = false;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "RemovePartitionWall":
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          wallID: data.wallID,
        });
        fetch("/api/CfzWebApi/RemovePartitionWall", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
              hasDookedRooms(result.roomUnitStack);
              setMainToolbarStatus(
                produce((draft) => {
                  draft.all.disabled = false;
                })
              );
              setPreviewToolbarStatus(
                produce((draft) => {
                  draft.newRoomUnit.disabled = false;
                  draft.showSurroundingBuilding.diabled = false;
                  draft.viewPlan2d.disabled = false;
                  draft.viewSection.disabled =
                    result.imageMaps.imageMapSectionData === null;
                  draft.selectSectionMark.disabled = false;
                  draft.partitionWallAddRemove.disabled = false;
                  draft.partitionWallCancel.visible = false;
                  draft.cornerChamferAddRemove.disabled = false;
                  draft.cornerChamferCancel.visible = false;
                  draft.dockShowRooms.disabled = false;
                  draft.dockRemoveRoom.disabled = false;
                  draft.cancelDockRoom.visible = false;
                  draft.endSelectSectionMark.visible = false;
                  draft.showPdfViewer.disabled = false;
                  draft.showOptions.disabled = false;
                })
              );
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "ComponentVisible":
        dtoData = JSON.stringify({
          roomUnitStack: designData.roomUnitStack,
          previewSizes: previewSizes,
          id: data.toogleID,
        });

        fetch("/api/CfzWebApi/ToogleVisible", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setDesignData(result);
            },
            (error) => {
              setError(error);
            }
          );
        break;

      case "DoorProp":
        setChangeDoorData({
          doorID: data.doorID,          
          isActive: true,          
          roomID: data.roomID,          
        });
        break;

      case "RoomProp":
        if (
          previewToolbarStatus.cancelDockRoom.visible ||
          previewToolbarStatus.partitionWallCancel.visible ||
          previewToolbarStatus.endSelectSectionMark.visible
        )
          return;

        setChangeRoomData({
          autoPosNumber: data.autoPosNumber,
          floorBoard: data.floorBoard,          
          floorDivision: data.floorDivision,
          floorType: data.floorType,
          hasRoof: data.hasRoof,
          invHeight: data.invHeight,
          isActive: true,
          isAllowSpecialDim: data.isAllowSpecialDim,
          isHasHeatCable: data.isHasHeatCable,
          isHasLeca: data.isHasLeca,
          isHasTryckUtVentil: data.isHasTryckUtVentil,
          isOriginMoveable: data.isOriginMoveable,                    
          originOffset: data.originOffset,
          panelMaterial: data.panelMaterial,
          panelThickness: data.panelThickness,
          roofDir: data.roofDir,
          roofDivision: data.roofDivision,
          roomID: data.roomID,
          roomLabel: data.roomLabel,
          roomType: data.roomType,
        });
        break;

      case "WallProp":
        break;

      case "ChangeDoorLocation":
        setChangeDimData({
          dimPrefix: data.dimPrefix,
          doorID: data.DoorID,
          isActive: true,
          maxValue: data.maxValue,
          minValue: data.minValue,
          oldValue: data.oldValue,
          propertyName: data.propertyName,
          roomID: data.roomID,
          isAllowSpecialDim: data.isAllowSpecialDim,
          step: data.step,
        });
        break;

      case "ChangeDimension":
        setChangeDimData({
          dimPrefix: data.dimPrefix,
          doorID: null,
          isActive: true,
          maxValue: data.maxValue,
          minValue: data.minValue,
          oldValue: data.oldValue,
          propertyName: data.propertyName,
          roomID: data.roomID,
          isAllowSpecialDim: data.isAllowSpecialDim,
          step: data.step,
        });
        break;

      case "ShelvingProp":
        setChangeShelvingData({
          isActive: true,
          numShelfLevels: data.numShelfLevels,
          roomID: data.roomID,
          shapeCode: data.shapeCode,
          shelfMaterial: data.shelfMaterial,
          shelfType: data.shelfType,
          shelvingUnitID: data.shelvingUnitID,
          shelvesHeight: data.shelvesHeight,
          shelvesWidths: data.shelvesWidths,
        });
        break;

      default:
        return null;
    }
  };

  const handleRenameRoomUnitClicked = () => {
    setRoomUnitDescription({
      description: roomUnitDescription.description,
      isOpen: true,
      isRequestNewRoom: false,
      isRename: true,
    });
  };

  const handleCloseRoomProps = () => {
    setChangeRoomData({
      isActive: false,
    });
  };

  const handleRoomPropsChanged = (newData) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      roomData: newData,
    });

    fetch("/api/CfzWebApi/UpdateRoomProperties", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDesignData(result);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handleCloseDoorProps = () => {
    setChangeDoorData({
      isActive: false,
    });
  };

  const handleDoorPropsChanged = (newData) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      doorData: newData,
    });

    fetch("/api/CfzWebApi/UpdateDoorProperties", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDesignData(result);
          setChangeDoorData({ isActive: false });
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handleCloseDimension = () => {
    setChangeDimData({
      isActive: false,
    });
  };

  const handleDimensionChanged = (roomID, propertyName, newValue, oldValue) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      dimData: {
        roomID: roomID,
        propertyName: propertyName,
        newValue: newValue,
        oldValue: oldValue,
      },
    });

    fetch("/api/CfzWebApi/UpdateDimension", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.status + " " + res.statusText);
        }
      })
      .then(
        (result) => {
          setDesignData(result);
        },
        (error) => {
          setError(error);
        }
      )
      .catch((error) => setError(error));
  };

  const handleCloseShelvings = () => {
    setChangeShelvingData({
      isActive: false,
    });
  };

  const handleShelvingsChanged = (newData) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      shelvingData: newData,
    });

    fetch("/api/CfzWebApi/UpdateShelvings", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDesignData(result);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handleOptionsChanged = (newData) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      dimTextHeight: newData.dimTextHeight,
      showSurrounding: false,
    });

    fetch("/api/CfzWebApi/UpdateOptions", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDesignData(result);
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handleClosePdfViewer = () => {
    setShowPdfViewer({
      isActive: false,
    });
  };

  const handleCloseOptions = () => {
    setShowOptions({
      isActive: false,
    });
  };

  const handleArchiveRoomOpen = (savedId, reload) => {    
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      savedId: savedId,
      reload: reload,
    });
    fetch("/api/CfzWebApi/OpenRoomUnit", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "application/json",
      },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {          
          afterNewDesignDataFromWebApi(result);          
          hasDookedRooms(result.roomUnitStack);          
          setActiveModule("showRoomDesign");          
        },
        (error) => {
          setError(error);
        }
      );
  };

  const handleAfterArchiveSaveRoom = (roomUnitStack) => {
    setDesignData({
      ...designData,
      roomUnitStack: roomUnitStack,
    });
  };

  const handleSendRequest = (formData, requestRecipient, guid) => {
    let dtoData = JSON.stringify({
      roomUnitStack: designData.roomUnitStack,
      previewSizes: previewSizes,
      sendRequestData: formData,
      requestRecipient: requestRecipient,
      jsguid: guid,
    });

    fetch("/api/CfzWebApi/SendRequest", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.status + " " + res.statusText);
        }
      })
      .then(
        (result) => {
          setDesignData({
            ...designData,
            roomUnitStack: result.roomUnitStack,
          });
          setActiveModule("showRequestReceived");
        },
        (error) => {
          setError(error);
        }
      )
      .catch((error) => setError(error));
  };
  
  const AppBarEx = styled(AppBar, {shouldForwardProp: (prop) => prop !== 'open',})(({ theme, open }) => ({  
    zIndex: 1201,
    transition: theme.transitions.create(['margin', 'width'], {      
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const renderAppBar = (
    <AppBarEx
      position="fixed"      
      open={isOpen}
    >
      <Toolbar disableGutters={!isOpen}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerOpen}          
          sx={{...(isOpen && { display: 'none' }),}}
          size="large">
          <MenuIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }} />
        <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>          
          {t('roomEditor.renderAppBar.title')}
        </Typography>

        <img
          src={imageNames["FbLogotype"]}
          alt=""
          style={{backgroundColor: "white", marginLeft: "12px", marginRight: "50px",}}
        />

        {runParams.sessionKey !== null && (
          <Box>
            <Button
                sx={{marginRight: theme.spacing(2),}}
                color="inherit" 
                startIcon={<ShoppingCartCheckoutIcon />}
                variant="outlined"
                onClick={finishWanErpDesign}
              >
                Klar, fortsätt till priskalkyl
            </Button>
            <Button
              sx={{marginRight: theme.spacing(2),}}
              color="inherit"     
              startIcon={<CancelPresentationIcon />}              
              variant="outlined" 
              onClick={cancelWanErpDesign}
            >
              Avbryt
            </Button>
          </Box>
        )}

        {user.access_token === "" && runParams.sessionKey === null && (          
          <Button
            sx={{marginRight: theme.spacing(2),}}
            color="inherit"
            onClick={handleLoginClicked}
          >
            {t('account.login')}
          </Button>          
        )}
        {user.access_token !== "" && runParams.sessionKey === null && (
          <Button
            sx={{marginRight: theme.spacing(2),}}
            color="inherit"
            onClick={handleLogoutClicked}
          >
            {t('account.logout')}            
          </Button>
        )}        
      </Toolbar>
    </AppBarEx>
  );

  const renderMainDrawer = (
    <DrawerEx      
      variant="permanent"      
      open={isOpen}      
    >      
      <DrawerHeaderEx>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeaderEx>
      <Divider />

      <List>
        {mainToolbarVisibility.showRoomDesign.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.showRoomDesign.tooltip')}>
            <ListItem
              button
              key={"showRoomDesign"}
              disabled={mainToolbarStatus.all.disabled}
              onClick={() => handleMainToolbarClicked("showRoomDesign")}
            >
              <ListItemIcon>
                <img src={imageNames["RoomDesign"]} alt="" />
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.showRoomDesign.text')} />
            </ListItem>
          </Tooltip>
        )}

        {mainToolbarVisibility.showRoomIndex.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.showRoomIndex.tooltip')}>
            <ListItem
              button
              key={"showRoomIndex"}
              disabled={mainToolbarStatus.all.disabled}
              onClick={() => handleMainToolbarClicked("showRoomIndex")}
            >
              <ListItemIcon>
                <Badge badgeContent={numRooms} color="primary">
                  <img src={imageNames["ShowAllRooms"]} alt="" />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.showRoomIndex.text')} />
            </ListItem>
          </Tooltip>
        )}

        {mainToolbarVisibility.renumberAutoIndexNumbers.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.renumberAutoIndexNumbers.tooltip')}>
            <ListItem
              button
              key={"renumberAutoIndexNumbers"}
              disabled={mainToolbarStatus.all.disabled}              
              onClick={() => handleMainToolbarClicked("renumberAutoIndexNumbers")}
            >
              <ListItemIcon>
                <Badge badgeContent={numRooms} color="primary">
                  <img src={imageNames["RenumberAutoIndexNumbers"]} alt="" />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.renumberAutoIndexNumbers.text')} />
            </ListItem>
          </Tooltip>
        )}

        {mainToolbarVisibility.sendRequest.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.sendRequest.tooltip')}>
            <ListItem
              button
              key={"sendRequest"}
              disabled={mainToolbarStatus.all.disabled}            
              onClick={() => handleMainToolbarClicked("sendRequest")}
            >
              <ListItemIcon>
                <Badge badgeContent={numRooms} color="primary">
                  <img src={imageNames["SendRequest"]} alt="" />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.sendRequest.text')} />
            </ListItem>
          </Tooltip>
        )}

        <br />

        {mainToolbarVisibility.showMyArchive.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.showMyArchive.tooltip')}>
            <ListItem
              button
              key={"showMyArchive"}
              disabled={mainToolbarStatus.all.disabled}
              onClick={() => handleMainToolbarClicked("showMyArchive")}
            >
              <ListItemIcon>
                <img src={imageNames["ShowMyArchive"]} alt="" />
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.showMyArchive.text')} />
            </ListItem>
          </Tooltip>
        )}

        {mainToolbarVisibility.saveRoomUnit.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.saveRoomUnit.tooltip')}>
            <ListItem
              button
              key={"saveRoomUnit"}
              disabled={mainToolbarStatus.all.disabled}
              onClick={() => handleMainToolbarClicked("saveRoomUnit")}
            >
              <ListItemIcon>
                <img src={imageNames["SaveRoomUnit"]} alt="" />
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.saveRoomUnit.text')} />
            </ListItem>
          </Tooltip>
        )}

        <br />

        {mainToolbarVisibility.showContactInfo.isHidden === false && (
          <Tooltip title={t('roomEditor.renderMainDrawer.showContactInfo.tooltip')}>
            <ListItem
              button
              key={"showContactInfo"}
              disabled={mainToolbarStatus.all.disabled}
              onClick={() => handleMainToolbarClicked("showContactInfo")}
            >
              <ListItemIcon>
                <img src={imageNames["Contact"]} alt="" />
              </ListItemIcon>
              <ListItemText primary={t('roomEditor.renderMainDrawer.showContactInfo.text')} />
            </ListItem>
          </Tooltip>
        )}
      </List>
    </DrawerEx>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <BeforeLeave />
      {renderAppBar}
      {renderMainDrawer}
      {
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeaderEx />
          <CssBaseline />
          {error && (
            <div>
              <Typography>Error: {error.message}</Typography>
            </div>
          )}

          {!isLoaded && (
            <div>
              <Typography>Loading...</Typography>
            </div>
          )}

          {activeModule === "showRoomDesign" && !designData && (
            <Grid>
              <Grid item>
                <Paper className={classes.paper}>Designdata saknas</Paper>
              </Grid>
            </Grid>
          )}

          {activeModule === "showRoomDesign" && designData && (
            <Grid>
              <Grid>
                <PreviewToolbar
                  buttonStatus={previewToolbarStatus}
                  previewType={previewType}
                  zoomLevels={previewZoomLevels}
                  onClick={handlePreviewToolbarClicked}
                  onZoom={handlePreviewZoomed}
                />
                <RoomUnitHeadline
                  roomUnitDescription={roomUnitDescription.description}
                  onEditDescription={handleRenameRoomUnitClicked}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={10}>
                  <Grid item xs>
                    <Paper className={classes.paper}>
                      <Preview
                        activeSectionMark={activeSectionMark}
                        command={previewType}
                        imageMaps={designData.imageMaps}
                        zoomLevels={previewZoomLevels}
                        onPreviewClicked={handlePreviewClicked}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={"auto"}>
                    <Paper className={classes.paper}>
                      {roomUnitDescription.isOpen && (
                        <RoomUnitDescriptionDialog
                          description={roomUnitDescription.description}
                          isRename={roomUnitDescription.isRename}
                          isRequestNewRoom={
                            roomUnitDescription.isRequestNewRoom
                          }
                          isStaff={runParams.isStaff}
                          onSubmit={handleRoomUnitDescriptionChanged}
                          onViewMyArchive={() =>
                            handleMainToolbarClicked("showMyArchive")
                          }
                          onCancel={handleRoomUnitDescriptionCanceled}
                        />
                      )}

                      {dockRoomTypeDialog.isOpen && (
                        <DockShowRoomDialog
                          onClick={handlePreviewToolbarClicked}
                        />
                      )}

                      {partionWallDialog.isOpen && (
                        <PartionWallDialog
                          isHasPartionWalls={
                            partionWallDialog.isHasPartionWalls
                          }
                          onClick={handlePreviewToolbarClicked}
                        />
                      )}

                      {cornerChamferDialog.isOpen && (
                        <CornerChamferDialog
                          isHasCornerChamfer={
                            cornerChamferDialog.isHasCornerChamfer
                          }
                          onClick={handlePreviewToolbarClicked}
                        />
                      )}

                      {changeRoomData.isActive && (
                        <ChangeRoomProps
                          roomData={changeRoomData}
                          runParams={runParams}
                          userRole={user.userRole}
                          onChange={handleRoomPropsChanged}
                          onClose={handleCloseRoomProps}
                        />
                      )}
                      {changeDoorData.isActive && (
                        <ChangeDoorProps
                          doorData={changeDoorData}
                          isStaff={runParams.isStaff}
                          roomUnitStack={designData.roomUnitStack}                          
                          onChange={handleDoorPropsChanged}
                          onClose={handleCloseDoorProps}
                        />
                      )}
                      {changeDimData.isActive && (
                        <ChangeDimension
                          changeDimData={changeDimData}
                          onDimensionChanged={handleDimensionChanged}
                          onClose={handleCloseDimension}
                        />
                      )}
                      {changeShelvingData.isActive && (
                        <ChangeShelvings
                          shelvingData={changeShelvingData}
                          onChange={handleShelvingsChanged}
                          onClose={handleCloseShelvings}
                        />
                      )}
                      {showPdfViewer.isActive && (
                        <PdfViewer
                          designData={designData}
                          previewSizes={previewSizes}
                          onClose={handleClosePdfViewer}
                        ></PdfViewer>
                      )}
                      {showOptions.isActive && (
                        <ChangeOptions
                          designData={designData}
                          onChange={handleOptionsChanged}
                          onSurroundingChange={toogleSurroundingBuilding}
                          onClose={handleCloseOptions}
                        ></ChangeOptions>
                      )}
                      {previewToolbarStatus.showHelp.isActive && (
                        <HelpInfo onClose={handleCloseHelpInfo} />
                      )}
                      <RoomPosMarkTable
                        data={designData.posMarkTable}
                        showHorisontal={false}
                        showVertical={true}
                        editEnabled={
                          !previewToolbarStatus.cancelDockRoom.visible &&
                          !previewToolbarStatus.partitionWallCancel.visible &&
                          !previewToolbarStatus.endSelectSectionMark.visible
                        }
                        onRoomPosMarkClicked={handlePreviewClicked}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {activeModule === "showRoomIndex" && (
            <Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <RoomIndex
                    designData={designData}
                    previewSizes={previewSizes}
                    onOpenRoomUnitClick={handleOpenRoomUnitClicked}
                    onDeleteRoomUnitClicked={handleDeleteRoomUnitClicked}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeModule === "sendRequest" && (
            <Grid>
              <Grid item>
                <SendRequest
                  roomUnitStack={designData.roomUnitStack}
                  previewSizes={previewSizes}
                  numRooms={numRooms}
                  user={user}
                  onSubmit={handleSendRequest}
                />
              </Grid>
            </Grid>
          )}

          {activeModule === "showRequestReceived" && (
            <Grid>
              <Grid item>
                <RequestReceived />
              </Grid>
            </Grid>
          )}

          {activeModule === "showMyArchive" && (
            <Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <MyArchive
                    roomUnitStack={designData.roomUnitStack}
                    user={user}
                    onRoomOpen={handleArchiveRoomOpen}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeModule === "saveRoomUnit" && (
            <Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <SaveRoomUnit
                    designData={designData}
                    previewSizes={previewSizes}
                    user={user}
                    onAfterRoomUnitSaved={handleAfterArchiveSaveRoom}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeModule === "showContactInfo" && (
            <Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <ShowContactInfo />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeModule === "login" && (
            <Grid>
              <Grid item>
                <Login
                  onLoginOk={handleLoginOk}
                  onLoginCancel={handleLoginCancel}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      }
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(RoomEditor);
