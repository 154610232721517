import React, { useState, useEffect } from "react";
import produce from "immer";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import MessageDialog from "./MessageDialog";

const styles = {        
    saveButton: {
        cursor: "pointer"
    }
};

const imageNames = {
    Save: require("../images/Save.png")
};

function SaveRoomUnit(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { classes } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [roomUnitsIndexData, setRoomUnitsIndexData] = useState("null");
    const [messageDialog, setMessageDialog] = useState({
        headline: "",
        isOpen: false,
        message: "",
        showOkIcon: false,
        showWarningIcon: false
    });

    useEffect(() => {
        if (!isLoaded) {
            let dtoData = JSON.stringify({
                roomUnitStack: props.designData.roomUnitStack,
                previewSizes: props.previewSizes,
                imageMapData: null,
                description: null
            });
            fetch("/api/CfzWebApi/GetAllRoomUnitIndexData", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: dtoData
            })
                .then(res => res.json())
                .then(result => {
                    setRoomUnitsIndexData(result);
                    setIsLoaded(true);
                });
        }
    }, [isLoaded, props.designData, props.previewSizes]);

    if (!isLoaded) return null;

    const handleSaveRoomClicked = roomUnitID => {
        let dtoData = JSON.stringify({
            roomUnitStack: props.designData.roomUnitStack,
            roomUnitID: roomUnitID,
            description: ""
        });
        fetch("/api/CfzWebApi/SaveRoomUnit", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + props.user.access_token,
                "Content-Type": "application/json"
            },

            body: dtoData
        })
        .then(res => res.json())
        .then(
            result => {
                if (result.succeeded) {
                    props.onAfterRoomUnitSaved(result.roomUnitStack);
                    setMessageDialog(
                        produce(draft => {
                            draft.headline = t('saveRoomUnit.msgBox.handleSaveRoomClicked.ok.headline');
                            draft.isOpen = true;
                            draft.message =t('saveRoomUnit.msgBox.handleSaveRoomClicked.ok.message');                                
                            draft.showOkIcon = true;
                            draft.showWarningIcon = false;
                        })
                    );
                } else {
                    setMessageDialog(
                        produce(draft => {
                            draft.headline = t('saveRoomUnit.msgBox.handleSaveRoomClicked.failed.headline');
                            draft.isOpen = true;
                            draft.message = result.errorMessage;
                            draft.showOkIcon = false;
                            draft.showWarningIcon = true;
                        })
                    );
                }
            },
            error => {
                setMessageDialog(
                    produce(draft => {
                        draft.headline = t('saveRoomUnit.msgBox.handleSaveRoomClicked.error.headline');
                        draft.isOpen = true;
                        draft.message = error.stack;
                        draft.showOkIcon = false;
                        draft.showWarningIcon = true;
                    })
                );
            }
        );
    };

    const handleMessageDialogClose = () => {
        setMessageDialog(
            produce(draft => {
                draft.headline = "";
                draft.isOpen = false;
                draft.meassage = "";
            })
        );
    };

    return (
        <div className={classes.root}>
            {roomUnitsIndexData && (
                <React.Fragment>
                    <AppBar sx={{backgroundColor: "black", position: "relative"}} elevation={0}>
                        <Toolbar>
                            <Typography
                                variant="button"
                                display="block"
                                sx={{marginLeft: theme.spacing(2), flex: 1}}
                            >
                                {t('saveRoomUnit.headline1')}
                                <br />
                                {t('saveRoomUnit.headline2')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Typography sx={{backgroundColor: "black", color: "white"}} />
                    <List>
                        {roomUnitsIndexData.map(roomUnitsData => (
                            <ListItem key={roomUnitsData.id}>
                                <Tooltip title={t('saveRoomUnit.tooltip')}>
                                    <ListItemIcon
                                        onClick={() =>
                                            handleSaveRoomClicked(
                                                roomUnitsData.id
                                            )
                                        }
                                    >
                                        <img
                                            className={classes.saveButton}
                                            src={imageNames.Save}
                                            alt=""
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={roomUnitsData.description}
                                    secondary={
                                        <React.Fragment>
                                            <span>
                                                {roomUnitsData.createdDate}
                                            </span>
                                            <br></br>
                                            <img
                                                src={roomUnitsData.image2dsrc}
                                                alt={""}
                                                height="300px"
                                            />
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            )}
            {messageDialog.isOpen && (
                <MessageDialog
                    headline={messageDialog.headline}
                    message={messageDialog.message}
                    showOkIcon={messageDialog.showOkIcon}
                    showWarningIcon={messageDialog.showWarningIcon}
                    onClose={handleMessageDialogClose}
                />
            )}
        </div>
    );
}

export default withStyles(styles)(SaveRoomUnit);
