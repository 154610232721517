import React from "react";
import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import CheckButton from "./CheckButton";

const styles = { };

const imageNames = {
  I1: require("../images/I1.png"),
  I2: require("../images/I2.png"),
  I3: require("../images/I3.png"),
  I4: require("../images/I4.png"),
  I13: require("../images/I13.png"),
  I24: require("../images/I24.png"),
  L12: require("../images/L12.png"),
  L23: require("../images/L23.png"),
  L34: require("../images/L34.png"),
  L41: require("../images/L41.png"),
  U123: require("../images/U123.png"),
  U234: require("../images/U234.png"),
  U341: require("../images/U341.png"),
  U412: require("../images/U412.png"),
  O1234: require("../images/O1234.png"),
};

function ShelvingShapeImage(props) {
  const { classes } = props;

  const isSelected =
    props.shapeCode && props.shapeCode === props.selectedShapeCode;

  return (
    <Box sx={{ position: "relative", display: "flex", flexDirection: "column",  padding: 1 }}>
      <img
        alt=""
        src={imageNames[props.shapeCode]}
        onClick={() => props.onClick(props.shapeCode)}
        style={{
          cursor: "pointer",
          pointerEvents: "visible",
        }}
      />
      <div
        style={{
          pointerEvents: "none",
          opacity: 1,
          position: "absolute",
          height: "36px",
          width: "100%",
        }}
      >
        <CheckButton
          isSelected={isSelected}
          isSelectable={true}
          color={"rgba(255, 255, 255, 0.7)"}
          selectedColor={"#4285f4"}
          hoverColor={"rgba(255, 255, 255, 1)"}
          paddingLeft={"6px"}
          paddingTop={"6px"}
        />
      </div>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(ShelvingShapeImage);
