import React from "react";
import Toolbar from "@mui/material/Toolbar";
import CustomToolbarButton from "./CustomToolbarButton";
import CustomToolbarZoomSlider from "./CustomToolbarZoomSlider";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = {};

function PreviewToolbar(props) {
  const { t } = useTranslation();

  return (
    <Toolbar disableGutters={true}>
      <CustomToolbarButton
        command="newRoomUnit"
        title={t('previewToolbar.newRoomUnit.title')}
        label={t('previewToolbar.newRoomUnit.label')}
        status={props.buttonStatus.newRoomUnit}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="showSurroundingBuilding"
        title={t('previewToolbar.showSurroundingBuilding.title')}
        label={t('previewToolbar.showSurroundingBuilding.label')}
        isOutOfOrder={true}
        status={props.buttonStatus.showSurroundingBuilding}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="viewPlan2d"
        title={t('previewToolbar.viewPlan2d.title')}
        label={t('previewToolbar.viewPlan2d.label')}
        status={props.buttonStatus.viewPlan2d}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="viewSection"
        title={t('previewToolbar.viewSection.title')}
        label={t('previewToolbar.viewSection.label')}
        status={props.buttonStatus.viewSection}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="selectSectionMark"
        title={t('previewToolbar.selectSectionMark.title')}
        label={t('previewToolbar.selectSectionMark.label')}
        status={props.buttonStatus.selectSectionMark}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="endSelectSectionMark"
        title={t('previewToolbar.endSelectSectionMark.title')}
        label={t('previewToolbar.endSelectSectionMark.label')}
        status={props.buttonStatus.endSelectSectionMark}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="partitionWallAddRemove"
        title={t('previewToolbar.partitionWallAddRemove.title')}
        label={t('previewToolbar.partitionWallAddRemove.label')}
        status={props.buttonStatus.partitionWallAddRemove}
        onClick={props.onClick}
      />      
      <CustomToolbarButton
        command="partitionWallCancel"
        title={t('previewToolbar.partitionWallCancel.title')}
        label={t('previewToolbar.partitionWallCancel.label')}
        status={props.buttonStatus.partitionWallCancel}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="cornerChamferAddRemove"
        title={t('previewToolbar.cornerChamferAddRemove.title')}
        label={t('previewToolbar.cornerChamferAddRemove.label')}
        status={props.buttonStatus.cornerChamferAddRemove}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="cornerChamferCancel"
        title={t('previewToolbar.cornerChamferCancel.title')}
        label={t('previewToolbar.cornerChamferCancel.label')}
        status={props.buttonStatus.cornerChamferCancel}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="dockShowRooms"
        title={t('previewToolbar.dockShowRooms.title')}
        label={t('previewToolbar.dockShowRooms.label')}
        status={props.buttonStatus.dockShowRooms}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="dockShowRemoveableRooms"
        title={t('previewToolbar.dockShowRemoveableRooms.title')}
        label={t('previewToolbar.dockShowRemoveableRooms.label')}
        status={props.buttonStatus.dockRemoveRoom}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="cancelDockRoom"
        title={t('previewToolbar.cancelDockRoom.title')}
        label={t('previewToolbar.cancelDockRoom.label')}
        status={props.buttonStatus.cancelDockRoom}
        onClick={props.onClick}
      />
      <CustomToolbarZoomSlider
        command="zoom"
        title={t('previewToolbar.zoom.title')}
        label={t('previewToolbar.zoom.label')}
        previewType={props.previewType}
        previewToolbarStatus={props.buttonStatus}
        zoomLevels={props.zoomLevels}
        onZoom={props.onZoom}
      />
      <CustomToolbarButton
        command="showOptions"
        title={t('previewToolbar.showOptions.title')}
        label={t('previewToolbar.showOptions.label')}
        status={props.buttonStatus.showOptions}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="showPdfViewer"
        title={t('previewToolbar.showPdfViewer.title')}
        label={t('previewToolbar.showPdfViewer.label')}
        status={props.buttonStatus.showPdfViewer}
        onClick={props.onClick}
      />
      <CustomToolbarButton
        command="showHelp"
        title={t('previewToolbar.showHelp.title')}
        label={t('previewToolbar.showHelp.label')}
        status={props.buttonStatus.showHelp}
        onClick={props.onClick}
      />
    </Toolbar>
  );
}

export default withStyles(styles)(PreviewToolbar);
