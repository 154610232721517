import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import ImageMapper from "./IatImageMapper"; //import ImageMapper from "react-image-mapper";
import "./css/Preview.css";

const styles = (theme) => ({});

function Preview(props) {
  const [hoveredArea, setHoveredArea] = useState(null);

  function getTipPosition(area) {
    return {
      top: `${area.scaledCoords[3] + 2 * 10}px`,
      left: `${area.center[0]}px`,
    };
  }

  function getTipText(area) {
    var data = JSON.parse(area.name);
    return "Klicka för " + data.title;
  }

  const handleOnClick = (area) => {
    setHoveredArea(null);
    props.onPreviewClicked(area);
  };

  let imgWidth = null;
  let src = null;
  let map = null;
  let zoomLevel = null;
  switch (props.command) {
    case "viewSection":
      if (props.activeSectionMark === "") {
        imgWidth = props.imageMaps.imageMapSectionData.imgWidth;
        src = props.imageMaps.imageMapSectionData.src;
        map = props.imageMaps.imageMapSectionData.map;
        zoomLevel = props.zoomLevels.section.value;
      } else {
        let imageMapData = props.imageMaps.imageMapSectionDatas.filter(
          (w) => w.sectionPosMark === props.activeSectionMark
        );
        if (imageMapData.length > 0) {
          imgWidth = imageMapData[0].imgWidth;
          src = imageMapData[0].src;
          map = imageMapData[0].map;
          zoomLevel = props.zoomLevels.section.value;
        }
      }
      break;

    case "viewPlan2d":
      imgWidth = props.imageMaps.imageMap2dData.imgWidth;
      src = props.imageMaps.imageMap2dData.src;
      map = props.imageMaps.imageMap2dData.map;
      zoomLevel = props.zoomLevels.image2d.value;
      break;

    default:
      return null;
  }

  if (map === null) {
    return (
      <div className="m-2">
        <div className="m-2" style={{ position: "relative" }}>
          <img src={src} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className="m-2">
      <div className="m-2" style={{ position: "relative" }}>
        <ImageMapper
          imgWidth={imgWidth}
          src={src}
          map={map}
          width={zoomLevel}
          onMouseOver={(area) => setHoveredArea(area)}
          onMouseLeave={(area) => setHoveredArea(null)}
          onClick={(area) => handleOnClick(area)}
        />
        {hoveredArea && (
          <span className="tooltip" style={getTipPosition(hoveredArea)}>
            {hoveredArea && getTipText(hoveredArea)}
          </span>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Preview);
