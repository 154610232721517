import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";

const styles = { };

function ChangeDoorInstructions(props) {    
    const { t } = useTranslation();
    const { classes } = props;    
    const [instruction1, setInstruction1] = useState(props.instruction1);
    const [instruction2, setInstruction2] = useState(props.instruction2);
    const [instruction3, setInstruction3] = useState(props.instruction3);        

    const handleInstructionChanged = (index, text) => {
        if (text.length <= 85) {        
            switch (index)
            {
                case 1:
                    setInstruction1(text);
                    break;
                
                case 2:
                    setInstruction2(text);
                    break;

                case 3:
                    setInstruction3(text);
                    break;

                default:
                    break;
            }
        }
    }

    const handleClose = ()  => {          
        props.onClose(instruction1.trim(), instruction2.trim(), instruction3.trim());
    };

    return (
        <Box sx={{display: "flex", flexWrap: "wrap"}}>
            <CssBaseline />
            <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
                <Card sx={{maxWidth: 1200, height: "100%", overflowY: "auto" }}>
                    <CardHeader
                        action={
                        <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                            <IconButton onClick={handleClose} size="large">
                            <CancelPresentationIcon style={{ color: green[500] }} />
                            </IconButton>
                        </Tooltip>
                        }
                        title={t('changeDoorInstructions.card.title')}
                    />
                    <CardContent>
                        <TextField
                            id="textRow1"
                            label={t('changeDoorInstructions.row1')}
                            autoFocus
                            className={classes.textField}
                            focused
                            fullWidth
                            InputProps={{ sx: {backgroundColor: "rgb(232, 240, 254) !important"} }} 
                            
                            margin="dense"
                            size="small"
                            variant="standard"
                            value={instruction1}
                            onChange={(event) => {handleInstructionChanged(1, event.target.value);}}
                        />

                        <TextField
                            id="textRow2"
                            label={t('changeDoorInstructions.row2')}                            
                            className={classes.textField}
                            focused
                            fullWidth
                            InputProps={{ sx: {backgroundColor: "rgb(232, 240, 254) !important"} }} 
                            
                            margin="dense"
                            size="small"
                            variant="standard"
                            value={instruction2}
                            onChange={(event) => {handleInstructionChanged(2, event.target.value);}}
                        />

                        <TextField
                            id="textRow3"
                            label={t('changeDoorInstructions.row3')}                            
                            className={classes.textField}
                            focused
                            fullWidth
                            InputProps={{ sx: {backgroundColor: "rgb(232, 240, 254) !important"} }} 
                            
                            margin="dense"
                            size="small"
                            variant="standard"
                            value={instruction3}
                            onChange={(event) => {handleInstructionChanged(3, event.target.value);}}
                        />
                
                    </CardContent>
                </Card>
            </Modal>
        </Box>


        // <div className={classes.root}>
        //     <CssBaseline />
        //     <Dialog
        //         open={true}
        //         TransitionComponent={Transition}
        //         aria-labelledby="form-dialog-title"
        //     >
        //         <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        //         <DialogContent>
        //             <DialogContentText>{contentText}</DialogContentText>

        //             <TextField
        //                 id="roomUnitDescription"
        //                 label={t('roomUnitDescriptionDialog.renderDescriptionDialog.projName')}
        //                 autoFocus
        //                 className={classes.textField}
        //                 fullWidth                        
        //                 InputProps={{ sx: {backgroundColor: "rgb(232, 240, 254) !important"} }} 
                        
        //                 margin="dense"
        //                 required
        //                 size="small"
        //                 variant="standard" 
        //                 {...form.allMuiProps("roomUnitDescription")}
        //             />
        //             <DialogActions>
        //                 <Button
        //                     className={classes.button}
        //                     color="primary"
        //                     type="submit"
        //                     variant="contained"
        //                     onClick={form.submit}
        //                 >
        //                     {btnOkLabel}
        //                 </Button>
        //             </DialogActions>
        //         </DialogContent>
        //     </Dialog>
        // </div>
    );
}

export default withStyles(styles)(ChangeDoorInstructions);
