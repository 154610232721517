import React, { useState } from "react";
import produce from "immer";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";
import SweSettings from "./localization/AllProFormManagerSweSettings";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import MessageDialog from "./MessageDialog";
import Register from "./Register";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

const styles = { };

const formConfigSwe = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

    fields: {
        email: {
            displayName: "E-post adress",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "En giltig e-postadress måste anges."
            }
        },
        password: {
            displayName: "Lösenord",
            inputType: "password",
            validation: {
                required: true,
                password: true,
                minLength: 4
            },
            errorMessages: {
                required: "Lösenord måste anges.",
                password: "Lösenord måste anges."
            }
        }
    }
};

const formConfigFin = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

    fields: {
        email: {
            displayName: "Sähköpostiosoite",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "Kelvollinen sähköpostiosoite on annettava."
            }
        },
        password: {
            displayName: "Salasana",
            inputType: "password",
            validation: {
                required: true,
                password: true,
                minLength: 4
            },
            errorMessages: {
                required: "Salasana on syötettävä.",
                password: "Salasana on syötettävä."
            }
        }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Login(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { classes } = props;
    const [loginError, setLoginError] = useState(null);
    const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [changePasswordEmail, setChangePasswordEmail] = useState("");
    const [messageDialog, setMessageDialog] = useState({
        headline: "",
        isOpen: false,
        message: "",
        showOkIcon: false,
        showWarningIcon: false
    });
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe);

    function handleSubmit() {
        form.validate().then(isValid => {
            if (isValid) {
                let data = form.data();
                let dtoData =
                    "grant_type=password&username=" +
                    data.email +
                    "&password=" +
                    data.password;
                fetch("/Token", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: dtoData
                })
                    .then(res => Promise.all([res.ok, res.json()]))
                    .then(([resOk, body]) => {
                        if (resOk) {
                            setLoginError(null);
                            props.onLoginOk(body);
                        } else if (
                            body !== null &&
                            body.error_description !== null
                        ) {
                            setLoginError(body.error_description);
                        } else {
                            setLoginError(body); //throw new Error(body);
                        }
                    })
                    .catch(error => {
                        // catches error case and if fetch itself rejects
                        setLoginError(
                            error !== null &&
                                error.toString() ===
                                    "SyntaxError: Unexpected token P in JSON at position 0"
                                ? "Kan ej ansluta till servern"
                                : error.toString()
                        );
                    });
            }
        });
    }

    form.submit = handleSubmit;

    const handleRegisterClicked = () => {
        setIsRegisterOpen(true);
    };

    const handleForgotPasswordClicked = () => {
        setIsForgotPasswordOpen(true);
    };

    const handleRegisterClose = () => {
        setIsRegisterOpen(false);
    };

    const handleForgotPasswordClose = () => {
        setIsForgotPasswordOpen(false);
    };

    const handleMessageDialogClose = () => {
        setMessageDialog(
            produce(draft => {
                draft.headline = "";
                draft.isOpen = false;
                draft.meassage = "";
            })
        );
    };

    const handleChangePasswordClose = () => {
        setIsChangePasswordOpen(false);
    };

    const handleForgotPasswordSubmit = data => {
        let mergedObject = {
            ...data,
            language: i18n.language
        };
        let dtoData = JSON.stringify(mergedObject);             
        
        fetch("/api/Account/ForgotPassword", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: dtoData
        })
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    throw new Error(res.status + " " + res.statusText);
                }
            })
            .then(
                result => {
                    setChangePasswordEmail(data.email);
                    setIsForgotPasswordOpen(false);
                    setIsChangePasswordOpen(true);
                    setMessageDialog(
                        produce(draft => {
                            draft.headline = t('login.msgBox.handleForgotPasswordSubmit.ok.headline');
                            draft.isOpen = true;
                            draft.message = t('login.msgBox.handleForgotPasswordSubmit.ok.message');                                
                            draft.showOkIcon = true;
                            draft.showWarningIcon = false;
                        })
                    );
                },
                error => {
                    setIsForgotPasswordOpen(false);
                    setMessageDialog(
                        produce(draft => {
                            draft.headline = t('login.msgBox.handleForgotPasswordSubmit.error.headline');
                            draft.isOpen = true;
                            draft.message = error.stack;
                            draft.showOkIcon = false;
                            draft.showWarningIcon = true;
                        })
                    );
                }
            )
            .catch(error => {
                setIsForgotPasswordOpen(false);
                setMessageDialog(
                    produce(draft => {
                        draft.headline = t('login.msgBox.handleForgotPasswordSubmit.error.headline');
                        draft.isOpen = true;
                        draft.message = error.stack;
                        draft.showOkIcon = false;
                        draft.showWarningIcon = true;
                    })
                );
            });
    };

    const handleChangePasswordSubmit = () => {
        setIsChangePasswordOpen(false);
        setMessageDialog(
            produce(draft => {
                draft.headline = t('login.msgBox.handleChangePasswordSubmit.headline');
                draft.isOpen = true;
                draft.message = "";
                draft.showOkIcon = true;
                draft.showWarningIcon = false;
            })
        );
    };

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
            >
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        <Typography variant="h6" sx={{marginLeft: theme.spacing(2), flex: 1}}>
                            {t('login.headline')}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="Close"
                            onClick={props.onLoginCancel}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {loginError !== null && (
                        <Typography sx={{color: "red", marginLeft: theme.spacing(1), marginTop: theme.spacing(1)}}>
                            {loginError}
                        </Typography>
                    )}

                    <TextField
                        id="email"
                        label={t('login.label.email')}
                        sx={{marginLeft: theme.spacing(1), marginRight: theme.spacing(1), width: "400px", ".MuiInputLabel-asterisk": { color: "red" }}}
                        margin="normal"
                        required
                        size="small"
                        variant="standard"
                        {...form.allMuiProps("email")}
                    />
                    <TextField
                        id="password"
                        label={t('login.label.password')}
                        sx={{marginLeft: theme.spacing(1), marginRight: theme.spacing(1), width: "400px", ".MuiInputLabel-asterisk": { color: "red" }}}                        
                        margin="normal"
                        required
                        size="small"
                        variant="standard"
                        {...form.allMuiProps("password")}
                    />

                    <DialogActions sx={{justifyContent: "flex-start"}}>
                        <Button
                            sx={{marginTop: "30px", width: "400px"}}
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={form.submit}
                        >
                            {t('login.button.login')}
                        </Button>
                    </DialogActions>
                    <DialogActions sx={{justifyContent: "flex-start"}}>
                        <Button
                            className={classes.button}
                            color="primary"
                            onClick={handleRegisterClicked}
                        >
                            {t('login.button.register')}
                        </Button>
                    </DialogActions>
                    <DialogActions sx={{justifyContent: "flex-start"}}>
                        <Button
                            className={classes.button}
                            color="primary"
                            onClick={handleForgotPasswordClicked}
                        >
                            {t('login.button.forgotPassword')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {isForgotPasswordOpen && (
                <ForgotPassword
                    isOpen={isForgotPasswordOpen}
                    onClose={handleForgotPasswordClose}
                    submit={handleForgotPasswordSubmit}
                />
            )}
            {isChangePasswordOpen && (
                <ChangePassword
                    defaultEmail={changePasswordEmail}
                    onClose={handleChangePasswordClose}
                    submit={handleChangePasswordSubmit}
                />
            )}
            {isRegisterOpen && (
                <Register
                    isOpen={isRegisterOpen}
                    onClose={handleRegisterClose}
                />
            )}
            {messageDialog.isOpen && (
                <MessageDialog
                    headline={messageDialog.headline}
                    message={messageDialog.message}
                    showOkIcon={messageDialog.showOkIcon}
                    showWarningIcon={messageDialog.showWarningIcon}
                    onClose={() => handleMessageDialogClose()}
                />
            )}
        </div>
    );
}

export default withStyles(styles)(Login);
