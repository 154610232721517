import React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({ });

const formControlCheckboxStyle = {
    "& .MuiCheckbox-root": {        
        paddingBottom: 0,      
        paddingTop: 0, 
      },
    "& .MuiRadio-root": {        
        paddingBottom: 0,      
        paddingTop: 0, 
        paddingRight: 1, 
      },
    "& .MuiSvgIcon-root": { 
        fontSize: "1.3rem" 
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "0.875rem", 
      marginBottom: 0,      
    },
  }

function DoorSupplement(props) {

    const handleRadioChange = (event)  => {         
        props.handleRadioChange(props.item, props.supplement.Caption, event.target.value);
    };

    const handleCheckboxChange = (event)  => {          
        props.handleCheckboxChange(props.item, props.supplement.Caption, event.target.checked);
    };

    return (
        <div>
            {props.supplement.Variants !== null && (                       
                <FormControl>
                    
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={props.radioValue}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            control={<Checkbox checked={props.isChecked} onChange={handleCheckboxChange} />}
                            label={props.supplement.Caption}
                            sx={{...formControlCheckboxStyle}}
                        />
                        {props.supplement.Variants.map((item, index) => (
                            <Box key={index}>
                            <FormControlLabel
                                value={item.Value}
                                control={<Radio disabled={!props.isChecked} />}
                                label={item.Text}
                                sx={{...formControlCheckboxStyle}}
                            />
                            </Box>
                        ))}
                    </RadioGroup>
                </FormControl>                
            )}

            {props.supplement.Variants === null && (                
                <FormControlLabel
                    control={<Checkbox checked={props.isChecked} onChange={handleCheckboxChange} />}
                    label={props.supplement.Caption}
                    sx={{...formControlCheckboxStyle}}
                />
            )}
            
        </div>
      );
}

export default withStyles(styles)(DoorSupplement);