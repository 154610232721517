import React from "react";
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

const styles = {};

function DoorTypeImage(props) {
    
    return (
        <Box>
            <Tooltip title={"Välj " + props.doorType}>
                <img
                    src={props.src}
                    alt=""
                    height={props.height}
                    style={{
                        cursor: "pointer",
                        pointerEvents: "visible"
                    }}
                    onClick={() => props.onClick(props.doorType)}
                />
            </Tooltip>     
        </Box>
    );
}

export default withStyles(styles, { withTheme: true })(DoorTypeImage);
