import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import DoorTypeImage from "./DoorTypeImage";

const styles = { };

const urlLinks = {    
    DoorGrk: "https://finnebacks.se/kylrumsdorrar/",
    DoorGrf: "https://finnebacks.se/frysrumsdorrar/",
    DoorHNtd: "https://finnebacks.se/kylrumsdorrar/",
    DoorHLtd: "https://finnebacks.se/frysrumsdorrar/",
    DoorTf: "https://finnebacks.se/frysrumsdorrar/",
    DoorTk: "https://finnebacks.se/kylrumsdorrar/",    
    DoorTkd: "https://finnebacks.se/kylrumsdorrar/",
    DoorTp: "https://finnebacks.se/pendeldorrar/",
    DoorTpd: "https://finnebacks.se/pendeldorrar/",
    DoorTpc: "https://finnebacks.se/pendeldorrar/",
    DoorTsdf: "https://finnebacks.se/skjutdorrar/",
    DoorTsdk: "https://finnebacks.se/skjutdorrar/",
  };

const LinkEx = styled(Link)(({ theme }) => ({
  margin: theme.spacing(0),
}));

function DoorInfo(props) {

    const handleTypeSelected = (newValue) => {

        props.onTypeSelected(newValue);
    };

    return (
        <Box>
            {props.infoCategory === "tf" && (
                <Stack direction="row" spacing={3}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <DoorTypeImage
                                src={require("../images/doors/TF.jpg")}
                                height={"100px"}
                                doorType={"TF"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">TF</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Frysdörr i glasfiber<br />
                                Enkeldörr: TF<br />                                
                                <br />
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorTf}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/GRF.jpg")}
                                height={"100px"}
                                doorType={"GRF"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">GRF</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Frysdörr i glasfiber<br />
                                med stigande gångjärn<br />
                                Enkeldörr: GRF<br />
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorGrf}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/H-LTD.jpg")}
                                height={"85px"}
                                doorType={"H-LTD"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">H-LTD</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Frysdörr i stål<br />
                                Enkeldörr: H-LTD<br />                                
                                <br />
                                </Typography>
                            </Box>
                        </Box>                
                    </Box>
                </Stack> 
            )}

            {props.infoCategory === "tk" && (
                <Stack direction="row" spacing={3}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>  
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <DoorTypeImage
                                    src={require("../images/doors/TK.jpg")}
                                    height={"100px"}
                                    doorType={"TK"}
                                    onClick={handleTypeSelected}
                                />
                                <DoorTypeImage
                                    src={require("../images/doors/TKD.jpg")}
                                    height={"100px"}
                                    doorType={"TK-D"}
                                    onClick={handleTypeSelected}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2">TK</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Kyldörr i glasfiber<br />
                                Enkeldörr: TK<br />
                                Dubbeldörr: TK-D
                                <br />
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorTk}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/GRF.jpg")}
                                height={"100px"}
                                doorType={"GRK"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">GRK</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Kyldörr i glasfiber<br />
                                med stigande gångjärn<br />
                                Enkeldörr: GRK<br />
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorGrk}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/H-NTD.jpg")}
                                height={"100px"}
                                doorType={"H-NTD"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">H-NTD</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Kyldörr i stål<br />
                                Enkeldörr: H-NTD<br />                                
                                <br />
                                </Typography>
                            </Box>
                        </Box>                
                    </Box>
                </Stack> 
            )}

            {props.infoCategory === "tp" && (
                <Stack direction="row" spacing={3}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}> 
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <DoorTypeImage
                                    src={require("../images/doors/TP.jpg")}
                                    height={"100px"}
                                    doorType={"TP"}
                                    onClick={handleTypeSelected}
                                />
                                <DoorTypeImage
                                    src={require("../images/doors/TPD.jpg")}
                                    height={"100px"}
                                    doorType={"TP-D"}
                                    onClick={handleTypeSelected}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2">TP</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Pendeldörr i glasfiber<br />
                                Enkeldörr: TP<br />
                                Dubbeldörr: TP-D                                
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorTp}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/TPC.jpg")}
                                height={"100px"}
                                doorType={"TPC"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">TPC</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Pendeldörr i<br />
                                genomsiktlig<br />
                                polykarbonat<br />
                                Enkeldörr: TPC<br />
                                Dubbeldörr: TPC-D
                                </Typography>                                
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            )}

            {props.infoCategory === "tsf" && (
                <Stack direction="row" spacing={3}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/TSDF.png")}
                                height={"100px"}
                                doorType={"TSDF"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">TSDF</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Skjutdörr i glasfiber<br />
                                Enkeldörr: TSDF<br />
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorTsdf}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>                    
                </Stack>
            )}
            
            {props.infoCategory === "tsk" && (
                <Stack direction="row" spacing={3}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>                            
                            <DoorTypeImage
                                src={require("../images/doors/TSDK.png")}
                                height={"100px"}
                                doorType={"TSDK"}
                                onClick={handleTypeSelected}
                            />
                            <Box>
                                <Typography variant="subtitle2">TSDK</Typography>
                                <Typography variant="body2" gutterBottom>                              
                                Skjutdörr i glasfiber<br />
                                Enkeldörr: TSDK<br />
                                </Typography>
                                <LinkEx
                                    href={urlLinks.DoorTsdk}
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover">
                                    [Läs mer...]
                                </LinkEx>
                            </Box>
                        </Box>                
                    </Box>                    
                </Stack>
            )}
        </Box>
    );
}

export default withStyles(styles)(DoorInfo);