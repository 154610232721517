var defaultSweErrorMessages = {
    required: "{name} måste anges",

    // Standard, preset validators - no values required
    phone: "Ange ett giltigt telefonnummer, 10 siffror",
    address: "Ange en giltig adress",
    email: "Detta är inte en giltig epost adress.",
    number: "{name} måste vara ett tal",
    integer: "{name} måste vara ett heltal",

    password: {
        default: "Detta är inte ett giltigt lösenord",
        mixedCase: "{name} must have both uppercase and lowercase characters,",
        lowerCase: "{name} must have {value} or more lowercase characters,",
        upperCase: "{name} must have {value} or more uppercase characters,",
        number: "{name} must have {value} or more numbers,",
        symbol: "{name} must have {value} or more symbols,",
        invalidChars: "These characters are INVALID: {value}"
    },

    // Generic, preset validators - require value(s) to be set
    minLength: "{name} måste innehålla minst {value} tecken",
    maxLength: "{name} får max innehålla {value} tecken.",
    exactLength: "{name} måste vara exakt {value} tecken.",

    minNumber: "{name} kan inte vara mindre än {value}",
    maxNumber: "{name} kan inte vara större än {value}",
    numberRange: "{name} måste vara mellan {value1} och {value2}",

    // minDate: function minDate(name, value) {
    //     return (
    //         (name || "Date") +
    //         " cannot be before " +
    //         date$2(value, "medium-date")
    //     );
    // },
    // maxDate: function maxDate(name, value) {
    //     return (
    //         (name || "Date") +
    //         " cannot be after " +
    //         date$2(value, "medium-date")
    //     );
    // },
    // dateRange: function dateRange(name, value) {
    //     return (
    //         (name || "Date") +
    //         " must be between " +
    //         (date$2(value[0], "medium-date") +
    //             " and " +
    //             date$2(value[1], "medium-date"))
    //     );
    // },

    // minTime: function minTime(name, value) {
    //     return (
    //         (name || "Time") +
    //         " cannot be before " +
    //         date$2(value, "medium-time")
    //     );
    // },
    // maxTime: function maxTime(name, value) {
    //     return (
    //         (name || "Time") +
    //         " cannot be after " +
    //         date$2(value, "medium-time")
    //     );
    // },
    // timeRange: function timeRange(name, value) {
    //     return (
    //         (name || "Time") +
    //         " must be between " +
    //         (date$2(value[0], "medium-time") +
    //             " and " +
    //             date$2(value[1], "medium-time"))
    //     );
    // },

    // The 'unknown' message should never be needed, but just in case...
    unknown: "Detta värde är ogiltigt"
};

export default {
    defaultSweErrorMessages
};
