var defaultFinErrorMessages = {
    required: "{name} on täsmennettävä.",

    // Standard, preset validators - no values required
    phone: "Anna kelvollinen puhelinnumero, 10 numeroa",
    address: "Anna kelvollinen osoite",
    email: "Tämä ei ole kelvollinen sähköpostiosoite.",
    number: "{name} täytyy olla numero",
    integer: "{name} on oltava kokonaisluku",

    password: {
        default: "Tämä ei ole kelvollinen salasana",
        mixedCase: "{name} must have both uppercase and lowercase characters,",
        lowerCase: "{name} must have {value} or more lowercase characters,",
        upperCase: "{name} must have {value} or more uppercase characters,",
        number: "{name} must have {value} or more numbers,",
        symbol: "{name} must have {value} or more symbols,",
        invalidChars: "These characters are INVALID: {value}"
    },

    // Generic, preset validators - require value(s) to be set
    minLength: "{name} tulee sisältää vähintään {value} merkki",
    maxLength: "{name} voi sisältää enintään {value} merkki.",
    exactLength: "{name} on oltava tarkka {value} merkki.",

    minNumber: "{name} ei voi olla pienempi kuin {value}",
    maxNumber: "{name} ei voi olla suurempi kuin {value}",
    numberRange: "{name} täytyy olla välillä {value1} ja {value2}",

    // minDate: function minDate(name, value) {
    //     return (
    //         (name || "Date") +
    //         " cannot be before " +
    //         date$2(value, "medium-date")
    //     );
    // },
    // maxDate: function maxDate(name, value) {
    //     return (
    //         (name || "Date") +
    //         " cannot be after " +
    //         date$2(value, "medium-date")
    //     );
    // },
    // dateRange: function dateRange(name, value) {
    //     return (
    //         (name || "Date") +
    //         " must be between " +
    //         (date$2(value[0], "medium-date") +
    //             " and " +
    //             date$2(value[1], "medium-date"))
    //     );
    // },

    // minTime: function minTime(name, value) {
    //     return (
    //         (name || "Time") +
    //         " cannot be before " +
    //         date$2(value, "medium-time")
    //     );
    // },
    // maxTime: function maxTime(name, value) {
    //     return (
    //         (name || "Time") +
    //         " cannot be after " +
    //         date$2(value, "medium-time")
    //     );
    // },
    // timeRange: function timeRange(name, value) {
    //     return (
    //         (name || "Time") +
    //         " must be between " +
    //         (date$2(value[0], "medium-time") +
    //             " and " +
    //             date$2(value[1], "medium-time"))
    //     );
    // },

    // The 'unknown' message should never be needed, but just in case...
    unknown: "Tämä arvo on virheellinen"
};

export default {
    defaultFinErrorMessages
};
