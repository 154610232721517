import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import { green } from '@mui/material/colors';
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import produce from "immer";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import DoorSupplement from "./DoorSupplement";

const styles = { };

function ChangeDoorSupplements(props) {    
    const { t } = useTranslation(); 

    const [previewSizes] = useState({
        image2d: { height: 400, width: 400 },
        image3d: { height: 400, width: 400 },
        section: { height: 400, width: 400 },
      });

    const [doorSupplements, setDoorSupplements] = useState(props.doorSupplements);
    const [imgPreview, setImgPreview] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {

            let prevSupplements = null;

            if (props.doorData.supplements !== null){
                prevSupplements = "|";
                const split = props.doorData.supplements.split('|');
                split.forEach(element => {
                    if (element !== null && element !== ""){
                        const childSplit = element.split('#');
                        const id = childSplit[0];
                        const variantValue = childSplit[1];
                        let index = doorSupplements.findIndex((w) => w.ID === id);                        
                        setDoorSupplements(
                            produce((draft) => {
                              draft[index].IsChecked = true;
                              draft[index].VariantValue = variantValue;
                            })
                        );

                        prevSupplements = prevSupplements.concat(id + "#");
                        if (variantValue !== null && variantValue !== "") {
                            prevSupplements = prevSupplements.concat(variantValue + "|");
                        }
                        else {
                            prevSupplements = prevSupplements.concat("|");
                        }
                    }
                });
            }

            let dtoData = JSON.stringify({
                roomUnitStack: props.roomUnitStack,
                previewSizes: previewSizes,
                doorData: props.doorData,
                currentSupplements: prevSupplements,
                isStaff: props.isStaff
            });
            fetch("/api/CfzWebApi/GetDoorSupplementPreview", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: dtoData,
            })
            .then((res) => res.json())
            .then((result) => { 
              setImgPreview(result);
              setIsLoaded(true);
            });
        }
    }, [isLoaded, props.doorData, props.roomUnitStack, props.isStaff, previewSizes, doorSupplements]);  

    const handleDoorSupplementChecked = (item, text, isChecked) => { 
        let index = doorSupplements.findIndex((w) => w.ID === item.ID); 
        let previewVariantValue = item.VariantValue;
        const uncheckedItems = [];

        setDoorSupplements(
            produce((draft) => { 
                draft[index].IsChecked = isChecked;
            })
        );

        if (isChecked)
        {            
            let supplement = doorSupplements[index];
            if (supplement.VariantValue === null && supplement.Variants != null && supplement.Variants.length > 0)
            {
                previewVariantValue = supplement.Variants[0].Value;

                setDoorSupplements(
                    produce((draft) => {
                      draft[index].VariantValue = supplement.Variants[0].Value;
                    })
                );                
            }

            if (supplement.CantCoExistWithId !== null && supplement.CantCoExistWithId.length > 0)
            {
                supplement.CantCoExistWithId.forEach(id => {
                    let uncheckIndex = doorSupplements.findIndex((w) => w.ID === id);
                    if (uncheckIndex >= 0) {
                        setDoorSupplements(
                            produce((draft) => { 
                                draft[uncheckIndex].IsChecked = false;
                            })
                        );

                        uncheckedItems.push(doorSupplements[uncheckIndex]);
                    }
                });
                
            }
        }

        if (item.IsRedrawImage) {
            getNewImagePrewiew(item.ID, isChecked, previewVariantValue, uncheckedItems);
        }
        
        // https://github.com/immerjs/immer/issues/115
    };

    const handleDoorSupplementRadioChanged = (item, text, newValue) => {
        let index = doorSupplements.findIndex((w) => w.ID === item.ID);
        setDoorSupplements(
            produce((draft) => {
              draft[index].VariantValue = newValue;
            })
        );

        if (item.IsRedrawImage) {
            getNewImagePrewiew(item.ID, item.IsChecked, newValue, null);
        }
    };

    const handleClose = ()  => {          
        props.onClose(doorSupplements);
    };

    const getNewImagePrewiew = (id, isChecked, variantValue, uncheckedItems) => {
        let result = "|";

        doorSupplements.forEach(item => {
            if (item.ID === id) {                
                if (isChecked)
                {                    
                    result = result.concat(item.ID + "#");  
                    if (variantValue !== null && variantValue !== "") {
                        result = result.concat(variantValue + "|");
                    }
                    else {
                        result = result.concat("|");
                    }
                }
            }            
            else if (item.IsChecked) {                
                if (uncheckedItems === null || uncheckedItems.findIndex((w) => w.ID === item.ID) === -1) {
                    result = result.concat(item.ID + "#");                    
                    if (item.VariantValue !== null && item.VariantValue !== "") {
                        result = result.concat(item.VariantValue + "|");
                    }
                    else {
                        result = result.concat("|");
                    }
                }
            }
        });

        let dtoData = JSON.stringify({
            roomUnitStack: props.roomUnitStack,
            previewSizes: previewSizes,
            doorData: props.doorData,
            currentSupplements: result,
            isStaff: props.isStaff
        });
        fetch("/api/CfzWebApi/GetDoorSupplementPreview", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: dtoData,
        })
        .then((res) => res.json())
        .then((result) => {       
          setImgPreview(result);          
        });
    };

    if (imgPreview !== null && imgPreview.imageMaps != null) {
        return (
        <Box sx={{display: "flex", flexWrap: "wrap"}}>
        <CssBaseline />
        <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
            <Card sx={{maxWidth: 1200, height: "100%", overflowY: "auto" }}>
            <CardHeader
                action={
                <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                    <IconButton onClick={handleClose} size="large">
                    <CancelPresentationIcon style={{ color: green[500] }} />
                    </IconButton>
                </Tooltip>
                }
                title={t('changeDoorOptionals.card.title')}
            />
            <CardContent>
                <img src={imgPreview.imageMaps.imageMap2dData.src} alt="" />

                {doorSupplements.map((item, index) => (
                    <DoorSupplement
                        key={index}
                        item={item}
                        handleCheckboxChange={handleDoorSupplementChecked}
                        handleRadioChange={handleDoorSupplementRadioChanged}
                        isChecked={item.IsChecked}
                        radioValue={item.VariantValue}
                        supplement={item}
                    />
                ))}
                
            </CardContent>
            </Card>
        </Modal>
        </Box>      
    );
    }

    return null;
}

export default withStyles(styles)(ChangeDoorSupplements);