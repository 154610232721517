import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from '@mui/material/styles';
import SweSettings from "./localization/AllProFormManagerSweSettings";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import { L10n } from "@syncfusion/ej2-base";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import {
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";

const styles = {  
  chipList: {
    paddingLeft: 0,
  },  
};

const TextFieldMandatory = styled(TextField)(({ theme }) => ({    
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "450px",  
  ".MuiInputLabel-asterisk": { color: "red" },  
  ".MuiInputBase-sizeSmall": {backgroundColor: "rgb(232, 240, 254) !important"},
}));

const TextFieldOptional = styled(TextField)(({ theme }) => ({  
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "450px",  
  ".MuiInputBase-sizeSmall": {backgroundColor: "rgb(232, 240, 254) !important"},
}));

const TextFieldShort = styled(TextField)(({ theme }) => ({  
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "200px",  
  ".MuiInputBase-sizeSmall": {backgroundColor: "rgb(232, 240, 254) !important"},
}));

const RadioGroupEx = styled(RadioGroup)(({ theme }) => ({  
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const FormGroupCheckBox = styled(FormGroup)(({ theme }) => ({  
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const formConfigSwe = {
  fieldDefaults: {
    isMUIControl: true,
    validateOnBlur: true,
    revalidateOnChange: true,
  },

  errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

  fields: {
    company: {
      displayName: "Företag",
      validation: {
        required: true,
        minLength: 4,
      },
      errorMessages: {
        required: "Namn på ditt företag måste anges.",
      },
    },
    name: {
      displayName: "Namn",
      validation: {
        required: true,
        minLength: 4,
      },
      errorMessages: {
        required: "Namn på kontaktperson måste anges.",
      },
    },
    phone: {
      displayName: "Telefon",
      validation: {
        required: true,
        minLength: 6,
      },
      errorMessages: {
        required: "Telefonnummer måste anges.",
      },
    },
    email: {
      displayName: "E-postadress",
      alias: "email",
      validation: {
        required: true,
        email: true,
      },
      errorMessages: {
        required: "En giltig e-postadress måste anges.",
      },
    },
    projDescription: {
      displayName: "Projektbenämning",
      validation: {
        required: true,
        minLength: 4,
      },
      errorMessages: {
        required: "Projektbenämning måste anges.",
      },
    },
    projLocation: {
      displayName: "Projekt ort",
    },
    comments: {
      displayName: "Kommentar",
    },
    dimensionStatus: {
      displayName: "Status på mått",
      validation: {
        required: true,
      },
      errorMessages: {
        required: "Status på angivna mått måste anges.",
      },
    },
    ofIsSubmitQuote: {
      displayName: "Jag ska lämna offert",
      dataType: "boolean",
      inputType: "checkbox",
      validation: {
        required: false,
      },
    },
    ofQuoteDateWeek: {
      displayName: "ofQuoteDateWeek",
    },
    oeIsHasMission: {
      displayName: "Jag har redan jobbet",
      dataType: "boolean",
      inputType: "checkbox",
    },
    oeLevDateWeek: {
      displayName: "Leverans önskas år-vecka",
    },
    oeLevDateCritical: {
      displayName: "Lev datum är kritiskt",
      dataType: "boolean",
      inputType: "checkbox",
    },
    oeLevDateUncertain: {
      displayName: "Lev datum är osäkert",
      dataType: "boolean",
      inputType: "checkbox",
    },
  },
};

const formConfigFin = {
  fieldDefaults: {
    isMUIControl: true,
    validateOnBlur: true,
    revalidateOnChange: true,
  },

  errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

  fields: {
    company: {
      displayName: "Yritys",
      validation: {
        required: true,
        minLength: 4,
      },
      errorMessages: {
        required: "Yrityksesi nimi on ilmoitettava.",
      },
    },
    name: {
      displayName: "Nimi",
      validation: {
        required: true,
        minLength: 4,
      },
      errorMessages: {
        required: "Yhteyshenkilön nimi on määritettävä.",
      },
    },
    phone: {
      displayName: "Puhelin",
      validation: {
        required: true,
        minLength: 6,
      },
      errorMessages: {
        required: "Puhelinnumero on syötettävä.",
      },
    },
    email: {
      displayName: "Sähköpostiosoite",
      alias: "email",
      validation: {
        required: true,
        email: true,
      },
      errorMessages: {
        required: "Kelvollinen sähköpostiosoite on annettava.",
      },
    },
    projDescription: {
      displayName: "Projektin nimi",
      validation: {
        required: true,
        minLength: 4,
      },
      errorMessages: {
        required: "Projektin nimi on määritettävä.",
      },
    },
    projLocation: {
      displayName: "Projektin sijainti",
    },
    comments: {
      displayName: "Kommentti",
    },
    dimensionStatus: {
      displayName: "Tila mitattuna",
      validation: {
        required: true,
      },
      errorMessages: {
        required: "Määritettyjen mittojen tila on määritettävä.",
      },
    },
    ofIsSubmitQuote: {
      displayName: "Teen lainauksen",
      dataType: "boolean",
      inputType: "checkbox",
      validation: {
        required: false,
      },
    },
    ofQuoteDateWeek: {
      displayName: "ofQuoteDateWeek",
    },
    oeIsHasMission: {
      displayName: "Minulla on jo työpaikka",
      dataType: "boolean",
      inputType: "checkbox",
    },
    oeLevDateWeek: {
      displayName: "Toimitus toivotaan vuosi-viikko",
    },
    oeLevDateCritical: {
      displayName: "Toimituspäivä on kriittinen",
      dataType: "boolean",
      inputType: "checkbox",
    },
    oeLevDateUncertain: {
      displayName: "Toimituspäivä on epävarma",
      dataType: "boolean",
      inputType: "checkbox",
    },
  },
};

const formData = {
  ofIsSubmitQuote: false,
  oeIsHasMission: false,
  oeLevDateCritical: false,
  oeLevDateUncertain: false,
};

const uploadSettings = {
  removeUrl: "/api/CfzWebApi/RemoveAttachments",
  saveUrl: "/api/CfzWebApi/UploadAttachments",
};

function SendRequest(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { classes } = props;
  const [guid] = useState(jsGuid());
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [requestRecipient, setRequestRecipient] = useState(null);
  const [requestRecipientIndex, setRequestRecipientIndex] = useState(0);
  const [dsRequestRecipient, setDsRequestRecipient] = useState(null);
  const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe, formData);  
  let uploadObj = React.createRef();

  useEffect(() => {
    if (!isLoaded) {
      L10n.load({
        "sv-SE": {
          uploader: {
            Browse: "Bläddra...",
            Clear: "Klar",
            Upload: "Ladda upp",
            dropFilesHint: "Eller släpp filer här",
            invalidMaxFileSize: "Filstorleken är för stor",
            invalidMinFileSize: "Filstorleken är för liten",
            invalidFileType: "Filtyp är inte tillåten",
            uploadFailedMessage: "Filen kunde inte laddas upp",
            uploadSuccessMessage: "Filen har laddats upp",
            removedSuccessMessage: "Filen har tagits bort",
            removedFailedMessage: "Det går inte att ta bort filen",
            inProgress: "uppladdning",
            readyToUploadMessage: "Redo att ladda upp",
            abort: "Avbryta",
            remove: "Ta bort",
            cancel: "Annullera",
            delete: "Radera fil",
            pauseUpload: "Filöverföring pausad",
            pause: "Paus",
            resume: "Återuppta",
            retry: "Försök igen",
            fileUploadCancel: "Uppladdning av fil avbruten",
          },
        },
        "fi-FI":{
          uploader: {
            Browse: "Bläddra...",
            Clear: "Klar",
            Upload: "Ladda upp",
            dropFilesHint: "Eller släpp filer här",
            invalidMaxFileSize: "Filstorleken är för stor",
            invalidMinFileSize: "Filstorleken är för liten",
            invalidFileType: "Filtyp är inte tillåten",
            uploadFailedMessage: "Filen kunde inte laddas upp",
            uploadSuccessMessage: "Filen har laddats upp",
            removedSuccessMessage: "Filen har tagits bort",
            removedFailedMessage: "Det går inte att ta bort filen",
            inProgress: "uppladdning",
            readyToUploadMessage: "Redo att ladda upp",
            abort: "Avbryta",
            remove: "Ta bort",
            cancel: "Annullera",
            delete: "Radera fil",
            pauseUpload: "Filöverföring pausad",
            pause: "Paus",
            resume: "Återuppta",
            retry: "Försök igen",
            fileUploadCancel: "Uppladdning av fil avbruten",
          },
        }
      });      

      fetch("/api/CfzWebApi/GetRequestRecipients", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((result) => {
          setIsLoaded(true);          
          setDsRequestRecipient(result);          
          setRequestRecipient("");                    
          if (props.user.access_token !== "") {            
            form.setValue("company", props.user.company);        
            form.setValue("name", props.user.name);
            form.setValue("phone", props.user.phone);
            form.setValue("email", props.user.email);            
          }
        });
    }
  });

  const handleRequestRecipientChipClicked = (args) => {
    setRequestRecipient(args.data.value);
    setRequestRecipientIndex(args.index);
  };

  function handleSubmit() {
    form.validate().then((isValid) => {
      if (isValid) {
        setIsSending(true);
        props.onSubmit(form.data(), requestRecipient, guid);
      }
    });
  }

  function onFileUpload(args) {
    args.customFormData = [{ jsguid: guid }];
  }

  function onFileRemove(args) {
    args.customFormData = [{ jsguid: guid }];
  }

  function jsGuid(len) {
    var buf = [],
      chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      charlen = chars.length,
      length = len || 32;

    for (var i = 0; i < length; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }

    return buf.join("");
  }

  form.submit = handleSubmit;

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <Paper sx={{maxWidth: "500px"}}>
            <Card className={classes.mandatoryCard}>
              <CardHeader
                sx={{color: "dodgerblue"}}
                title={t('sendRequest.mandatory.title')}
              />
              <CardContent sx={{display: "flex",  flexDirection: "column"}}>
                <form
                  sx={{display: "flex", flexDirection: "column"}}
                  noValidate
                  autoComplete="off"
                >
                  <TextFieldMandatory
                    id="company"
                    label={t('sendRequest.mandatory.labels.company')}
                    InputLabelProps={{shrink: true}}                     
                    margin="normal"
                    required
                    size="small"
                    variant="standard" 
                    {...form.allMuiProps("company")}
                  />
                  <TextFieldMandatory
                    id="name"
                    label={t('sendRequest.mandatory.labels.name')}
                    InputLabelProps={{ shrink: true}}
                    margin="normal"
                    required
                    size="small"
                    variant="standard" 
                    {...form.allMuiProps("name")}
                  />
                  <TextFieldMandatory
                    id="phone"
                    label={t('sendRequest.mandatory.labels.phone')}
                    InputLabelProps={{ shrink: true}}                    
                    margin="normal"
                    required
                    size="small"
                    variant="standard" 
                    {...form.allMuiProps("phone")}
                  />
                  <TextFieldMandatory
                    id="email"
                    label={t('sendRequest.mandatory.labels.email')}
                    InputLabelProps={{ shrink: true}}
                    margin="normal"
                    required
                    size="small"
                    variant="standard" 
                    {...form.allMuiProps("email")}
                  />
                  <TextFieldMandatory
                    id="projDescription"
                    label={t('sendRequest.mandatory.labels.projDescription')}
                    InputLabelProps={{ shrink: true}}                    
                    margin="normal"
                    required
                    size="small"
                    variant="standard" 
                    {...form.allMuiProps("projDescription")}
                  />
                  <RadioGroupEx
                    aria-label="dimensionStatus"                    
                    {...form.fieldProps("dimensionStatus")}
                  >
                    <FormControlLabel
                      value="approved"
                      sx={{maxHeight: 24}}
                      control={<Radio color="primary" />}
                      label={t('sendRequest.mandatory.labels.dimensionStatus.approved')}
                    />
                    <FormControlLabel
                      value="draft"
                      sx={{maxHeight: 24}}
                      control={<Radio color="primary" />}
                      label={t('sendRequest.mandatory.labels.dimensionStatus.draft')}
                    />
                  </RadioGroupEx>
                  <FormHelperText classes={classes.TextField} error={true}>
                    {form.error("dimensionStatus")}
                  </FormHelperText>
                </form>
              </CardContent>
            </Card>
          </Paper>
          <br />
          <Paper sx={{maxWidth: "500px"}}>
            <Card className={classes.mandatoryCard}>
              <CardHeader
                sx={{color: "#17a2b8"}}
                title={t('sendRequest.optional.title')}
              />
              <CardContent sx={{display: "flex",  flexDirection: "column"}}>
                <TextFieldOptional
                  id="projLocation"
                  label={t('sendRequest.optional.labels.projLocation')}
                  InputLabelProps={{shrink: true}}                  
                  margin="normal"
                  size="small"
                  variant="standard" 
                  {...form.allMuiProps("projLocation")}
                />
                {dsRequestRecipient && (
                  <Box sx={{marginLeft: theme.spacing(1), marginRight: theme.spacing(1), marginTop: theme.spacing(1), width: "450px"}}>
                    <label
                      className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink"
                      style={{ marginBottom: 0 }}
                    >
                      {t('sendRequest.optional.labels.requestRecipient')}
                    </label>
                    <ChipListComponent
                      id="chip-choice"
                      selection="Single"
                      cssClass="e-outline"
                      className={classes.chipList}
                      selectedChips={requestRecipientIndex}
                      click={handleRequestRecipientChipClicked}
                    >
                      <ChipsDirective>
                        {dsRequestRecipient.map((data) => (
                          <ChipDirective
                            key={data.Email}
                            text={data.Name}
                            value={data.Email}
                          ></ChipDirective>
                        ))}
                      </ChipsDirective>
                    </ChipListComponent>
                  </Box>
                )}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={"auto"}
                    style={{
                      backgroundColor: "LightPink",
                      marginTop: "20px",
                      padding: "8px"
                    }}
                  >
                    <Card>
                      <FormGroupCheckBox row >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              {...form.fieldProps("ofIsSubmitQuote")}
                            />
                          }
                          label={t('sendRequest.optional.labels.ofIsSubmitQuote')}
                        />
                      </FormGroupCheckBox>
                      <TextFieldShort
                        id="ofQuoteDateWeek"
                        label={t('sendRequest.optional.labels.ofQuoteDateWeek')}
                        InputLabelProps={{shrink: true}}                        
                        margin="normal"
                        size="small"
                        variant="standard" 
                        {...form.allMuiProps("ofQuoteDateWeek")}
                      />
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={"auto"}
                    style={{
                      backgroundColor: "PaleGreen",
                      marginLeft: "2px",
                      marginTop: "20px",
                      padding: "8px"
                    }}
                  >
                    <Card>
                      <FormGroupCheckBox row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              {...form.fieldProps("oeIsHasMission")}
                            />
                          }
                          label={t('sendRequest.optional.labels.oeIsHasMission')}
                        />
                      </FormGroupCheckBox>
                      <TextFieldShort
                        id="oeLevDateWeek"
                        label={t('sendRequest.optional.labels.oeLevDateWeek')}
                        InputLabelProps={{shrink: true}}                        
                        margin="normal"
                        size="small"
                        variant="standard" 
                        {...form.allMuiProps("oeLevDateWeek")}
                      />
                      <FormGroupCheckBox row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              {...form.fieldProps("oeLevDateCritical")}
                            />
                          }
                          label={t('sendRequest.optional.labels.oeLevDateCritical')}
                        />
                      </FormGroupCheckBox>
                      <FormGroupCheckBox row >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              {...form.fieldProps("oeLevDateUncertain")}
                            />
                          }
                          label={t('sendRequest.optional.labels.oeLevDateUncertain')}
                        />
                      </FormGroupCheckBox>
                    </Card>
                  </Grid>
                </Grid>
                <Box sx={{marginTop: theme.spacing(2)}}>
                  <Typography variant="body2">
                    {t('sendRequest.optional.labels.attachment1')}
                    <br />
                    {t('sendRequest.optional.labels.attachment2')}
                  </Typography>
                  <UploaderComponent
                    id="uploader"
                    allowedExtensions=".pdf"
                    asyncSettings={uploadSettings}
                    autoUpload={true}
                    locale={i18n.language === "fi" ? "fi-FI" : "sv-SE"}
                    ref={uploadObj}
                    type="file"
                    removing={(args) => onFileRemove(args)}
                    uploading={(args) => onFileUpload(args)}
                  />
                </Box>
                <TextFieldOptional
                  id="comments"
                  label={t('sendRequest.optional.labels.comments')}
                  className={classes.textField}
                  InputLabelProps={{shrink: true}}                  
                  margin="normal"
                  multiline={true}
                  rows={7}
                  size="small"
                  variant="standard" 
                  {...form.allMuiProps("comments")}
                />
              </CardContent>
            </Card>
          </Paper>
          <br />
          <Button
            className={classes.button}
            color="primary"
            type="submit"
            variant="contained"
            onClick={form.submit}
          >
            {t('sendRequest.optional.labels.btnSubmit')}
          </Button>
          {isSending && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Grid>
        <Grid item>
          <Paper sx={{maxWidth: "500px"}}>
            <Card sx={{borderColor: "#1E90FF", borderWidth: "3px", maxWidth: "500px"}}>
              <CardHeader
                sx={{color: "black"}}
                title={t('sendRequest.instruktions.title')}
              />
              <CardContent sx={{display: "flex",  flexDirection: "column"}}>
                <Typography component="div">
                  <Box mb={2}>
                    {t('sendRequest.instruktions.info1')}{" "}
                    <Box
                      sx={{color: "red"}}
                      fontWeight="fontWeightBold"
                      component="span"
                    >
                      {props.numRooms}&nbsp;
                    </Box>
                    {t('sendRequest.instruktions.info2')}
                  </Box>

                  <Box mb={2}>
                    {t('sendRequest.instruktions.info3')}
                  </Box>

                  <Box mb={2}>
                    {t('sendRequest.instruktions.info4')}
                  </Box>

                  <Box mb={2}>
                    {t('sendRequest.instruktions.info5')}
                  </Box>

                  <Box mb={2}>
                    {t('sendRequest.instruktions.info6')}
                  </Box>
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(SendRequest);
