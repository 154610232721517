import React, { useState } from "react";
import produce from "immer";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import SweSettings from "./localization/AllProFormManagerSweSettings";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import MessageDialog from "./MessageDialog";

const styles = { };

const TextFieldEx = styled(TextField)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "400px",
    ".MuiInputLabel-asterisk": { color: "red" },
  }));

const formConfigSwe = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

    fields: {
        email: {
            displayName: "E-postadress",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "En giltig e-postadress måste anges."
            }
        }
    }
};

const formConfigFin = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

    fields: {
        email: {
            displayName: "Sähköpostiosoite",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "Kelvollinen sähköpostiosoite on annettava."
            }
        }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RequestAuthCode(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { classes } = props;
    const [loading, setLoading] = useState(false);
    const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe);    
    const [messageDialog, setMessageDialog] = useState({
        isOpen: false,
        success: false,
        headline: "",
        message: "",
        showOkIcon: false,
        showWarningIcon: false
    });

    function handleSubmit() {
        form.validate().then(isValid => {
            if (isValid) {
                setLoading(true);
                let dtoData = JSON.stringify({
                    email: form.data().email,
                    language: i18n.language
                });
                fetch("/api/Account/RequestNewAuthCode", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: dtoData
                })
                    .then(res => {
                        if (!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        }
                    })
                    .then(() => {
                        setMessageDialog(
                            produce(draft => {
                                draft.isOpen = true;
                                draft.success = true;
                                draft.headline = t('requestAuthCode.msgBox.handleSubmit.ok.headline');                                    
                                draft.message = t('requestAuthCode.msgBox.handleSubmit.ok.message');                                    
                                draft.showOkIcon = true;
                                draft.showWarningIcon = false;
                            })
                        );
                    })
                    .catch(error => {
                        setMessageDialog(
                            produce(draft => {
                                draft.isOpen = true;
                                draft.success = false;
                                draft.headline = t('requestAuthCode.msgBox.handleSubmit.error.headline');
                                draft.message = error.stack;
                                draft.showOkIcon = false;
                                draft.showWarningIcon = true;
                            })
                        );
                    });
            }
        });
    }

    const handleMessageDialogClose = success => {
        if (success) {
            props.onClose();
        } else {
            setLoading(false);
            setMessageDialog(
                produce(draft => {
                    draft.headline = "";
                    draft.isOpen = false;
                    draft.meassage = "";
                    draft.showOkIcon = false;
                    draft.showWarningIcon = false;
                })
            );
        }
    };

    form.submit = handleSubmit;

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
            >
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        <Typography variant="h6" sx={{marginLeft: theme.spacing(2), flex: 1}}>
                            {t('requestAuthCode.headline')}                            
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="Close"
                            onClick={props.onClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextFieldEx
                        id="email"
                        label={t('requestAuthCode.labels.email')}
                        disabled={loading}                        
                        margin="normal"
                        required
                        size="small"
                        variant="standard"
                        {...form.allMuiProps("email")}
                    />

                    <DialogActions>
                        <Button
                            className={classes.button}
                            color="primary"
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            onClick={form.submit}
                        >
                            {t('requestAuthCode.btnSubmit')}                            
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{color: green[500], position: "absolute", left: "50%", marginLeft: 12}}
                            />
                        )}
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {messageDialog.isOpen && (
                <MessageDialog
                    headline={messageDialog.headline}
                    message={messageDialog.message}
                    showOkIcon={messageDialog.showOkIcon}
                    showWarningIcon={messageDialog.showWarningIcon}
                    onClose={() =>
                        handleMessageDialogClose(messageDialog.success)
                    }
                />
            )}
        </div>
    );
}

export default withStyles(styles)(RequestAuthCode);
