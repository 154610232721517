import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import SweSettings from "./localization/AllProFormManagerSweSettings";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";

const styles = { };

const formConfigSwe = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

    fields: {
        roomUnitDescription: {
            displayName: "Projektbenämning",
            validation: {
                required: true
            },
            errorMessages: {
                required: "Projektbenämning måste anges."
            }
        }
    }
};

const formConfigFin = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

    fields: {
        roomUnitDescription: {
            displayName: "Projektin nimi",
            validation: {
                required: true
            },
            errorMessages: {
                required: "Projektin nimi on määritettävä."
            }
        }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GreenButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[500],
    "&:hover": {
        backgroundColor: green[700]
    }
  }));

function RoomUnitDescriptionDialog(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { classes } = props;
    const [typeDialogIsOpen, setTypeDialogIsOpen] = useState(true);
    const [roomType, setRoomType] = useState(null);    
    const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe);

    useEffect(() => {
        form.setValue("roomUnitDescription", props.description);
    }, [props.description, form]);

    const handleFormSubmit = () => {
        form.validate().then(isValid => {
            if (isValid) {
                if (props.isRename) {
                    props.onSubmit(form.data(), false, true, "");
                    return;
                }

                props.onSubmit(
                    form.data(),
                    props.isRequestNewRoom,
                    props.isRename,
                    roomType
                );
            }
        });
    };

    const handleRoomTypeClicked = roomType => {
        setRoomType(roomType);
        setTypeDialogIsOpen(false);
    };

    form.submit = handleFormSubmit;

    const title = props.isRequestNewRoom
        ? t('roomUnitDescriptionDialog.renderDescriptionDialog.title.isRequestNewRoom.true')    // "Bygg nytt rum (eller kombination av flera intilligande rum)"
        : t('roomUnitDescriptionDialog.renderDescriptionDialog.title.isRequestNewRoom.false')   // "Benämning på detta rum / rumskombination";

    const btnOkLabel = props.isRequestNewRoom
        ? t('roomUnitDescriptionDialog.renderDescriptionDialog.btnOkLabel.isRequestNewRoom')    // "Bygg nytt"
        : props.isRename
        ? t('roomUnitDescriptionDialog.renderDescriptionDialog.btnOkLabel.isRename.true')       // "OK"
        : t('roomUnitDescriptionDialog.renderDescriptionDialog.btnOkLabel.isRename.false')      // "Börja bygga";

    const contentText = props.isRequestNewRoom
        ? t('roomUnitDescriptionDialog.renderDescriptionDialog.contentText.isRequestNewRoom')   // "Ange en benämning eller en beskrivning för det nya rummet/rumskombinationen som vi kan referera till när vi kontaktar dig."
        : props.isRename
        ? t('roomUnitDescriptionDialog.renderDescriptionDialog.contentText.isRename.true')      // "Ange en benämning eller en beskrivning som vi kan referera till när vi kontaktar dig angående det rum/rumskombinations som du nu jobbar med."
        : t('roomUnitDescriptionDialog.renderDescriptionDialog.contentText.isRename.false')     // "Ange en benämning eller en beskrivning som vi kan referera till när vi kontaktar dig angående det rum/rumskombinations som du i detta nu skapar.";

    const renderDescriptionDialog = (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>

                <TextField
                    id="roomUnitDescription"
                    label={t('roomUnitDescriptionDialog.renderDescriptionDialog.projName')}
                    autoFocus
                    className={classes.textField}
                    fullWidth
                    sx={{ ".MuiInputLabel-asterisk": { color: "red" } }}
                    InputProps={{ sx: {backgroundColor: "rgb(232, 240, 254) !important"} }} 
                    
                    margin="dense"
                    required
                    size="small"
                    variant="standard" 
                    {...form.allMuiProps("roomUnitDescription")}
                />
                <DialogActions>
                    <Button
                        className={classes.button}
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={form.submit}
                    >
                        {btnOkLabel}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );

    const renderRoomTypeDialog = (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {t('roomUnitDescriptionDialog.renderRoomTypeDialog.dialogTitle')}                
            </DialogTitle>
            <DialogContent>
                <DialogActions sx = {{ display: "flex", justifyContent: "center",  marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }}>
                    <Button
                        className={classes.button}
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={() => handleRoomTypeClicked("Kyl")}
                    >
                        {t('roomUnitDescriptionDialog.renderRoomTypeDialog.kylrum')}
                    </Button>
                    <GreenButton
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        onClick={() => handleRoomTypeClicked("Frys")}
                    >
                        {t('roomUnitDescriptionDialog.renderRoomTypeDialog.frysrum')}                        
                    </GreenButton>

                    {!props.isRequestNewRoom && !props.isRename && !props.isStaff && (
                        <Button
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            onClick={props.onViewMyArchive}
                        >
                            {t('roomUnitDescriptionDialog.renderRoomTypeDialog.openMyArchive')}
                        </Button>
                    )}
                    {(props.isRequestNewRoom || props.isRename) && (
                        <Button
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            onClick={props.onCancel}
                        >
                            {t('roomUnitDescriptionDialog.renderRoomTypeDialog.cancel')}
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );

    if (props.isRename) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                {renderDescriptionDialog}
            </div>
        );
    }

    if (typeDialogIsOpen) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                {renderRoomTypeDialog}
            </div>
        );
    }

    if (!typeDialogIsOpen) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                {renderDescriptionDialog}
            </div>
        );
    }
}

export default withStyles(styles)(
    RoomUnitDescriptionDialog
);
