import React, { useState, useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MUIDataTable from "mui-datatables";

const styles = theme => ({});

const imageNames = {
    fileOpenEditiable: require("../images/FileOpenEditiable.png"),
    refresh: require("../images/Refresh.png")
};

function MyArchive(props) {
    const { classes } = props;
    const [archiveData, setArchiveData] = useState(null);    
    const [isLoaded, setIsLoaded] = useState(false);

    const handleButtonClicked = (index, reload) => {        
        props.onRoomOpen(archiveData[index].id, reload);        
    };

    useEffect(() => {
        if (!isLoaded) {        
            let dtoData = JSON.stringify({
                roomUnitStack: props.roomUnitStack,
                previewSizes: null,
                imageMapData: null
            });
            fetch("/api/CfzWebApi/GetAllSavedRoomUnits", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + props.user.access_token,
                    "Content-Type": "application/json"
                },

                body: dtoData
                })
                .then(res => {                
                    setIsLoaded(true);
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error(res.status + " " + res.statusText);
                    }
                })
                .then(
                    result => {
                        setArchiveData(result);
                    },
                    error => {
                        console.log(error);
                    }
                )
                .catch(error => console.log(error));
        }
    }, [props.roomUnitStack, props.user.access_token]);    

    return (
        <div className={classes.root}>
            {archiveData !== null && (
                <MUIDataTable
                    title={"Mitt arkiv"}
                    columns={[
                        {
                            name: "isOpen",
                            label: "Öppna",
                            options: {
                                filter: false,
                                sort: false,
                                empty: false,
                                customBodyRender: (value, tableMeta) => {
                                    if (value) {
                                        return null;
                                    } else {
                                        return (
                                            <Tooltip title="Öppna">
                                                <IconButton
                                                    aria-label="Open"
                                                    onClick={() =>
                                                        handleButtonClicked(
                                                            tableMeta.rowIndex,
                                                            false
                                                        )
                                                    }
                                                    size="large">
                                                    <img
                                                        src={
                                                            imageNames.fileOpenEditiable
                                                        }
                                                        alt=""
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        );
                                    }
                                }
                            }
                        },
                        {
                            name: "isOpen",
                            label: "Börja om",
                            options: {
                                filter: false,
                                sort: false,
                                empty: false,
                                customBodyRender: (value, tableMeta) => {
                                    if (value) {
                                        return (
                                            <Tooltip title="Rum/Rumskombination är redan öppen. Börja om genom att öppna på nytt från arkivet. Om du gjort osparade ändringar i den nu öppnade versionen så går dessa förlorade.">
                                                <IconButton
                                                    aria-label="Reload"
                                                    onClick={() =>
                                                        handleButtonClicked(
                                                            tableMeta.rowIndex,
                                                            true
                                                        )
                                                    }
                                                    size="large">
                                                    <img
                                                        src={imageNames.refresh}
                                                        alt=""
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        );
                                    } else {
                                        return null;
                                    }
                                }
                            }
                        },
                        { label: "Beskrivning", name: "description" },
                        { label: "Skapad", name: "createdDate" },
                        { label: "Sparad", name: "savedDate" }
                    ]}
                    data={archiveData}
                    options={{
                        download: false,
                        filterType: "dropdown",
                        print: false,
                        responsive: "standard",
                        rowsPerPage: 100,
                        rowsPerPageOptions: [10, 20, 50, 100],
                        selectableRows: "none",
                        textLabels: {
                            body: {
                                noMatch: "Inga matchande rader",
                                toolTip: "Sortera"
                            },
                            pagination: {
                                next: "Nästa sida",
                                previous: "Föregående sida",
                                rowsPerPage: "Rader per sida:",
                                displayRows: "av totalt"
                            },
                            toolbar: {
                                search: "Sök",
                                downloadCsv: "Ladda ner CSV",
                                print: "Skriv ut",
                                viewColumns: "Visa kolumner",
                                filterTable: "Filtrera tabellen"
                            },
                            filter: {
                                all: "Alla",
                                title: "FILTER",
                                reset: "ÅTERSTÄLL"
                            },
                            viewColumns: {
                                title: "Visa kolumner",
                                titleAria: "Visa/Göm tabellkolumner"
                            },
                            selectedRows: {
                                text: "rad(er) selekterade",
                                delete: "Ta bort",
                                deleteAria: "Ta bort selekterade rader"
                            }
                        }
                    }}
                />
            )}
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(MyArchive);
