import React, { useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

const styles = { };

function CheckButton(props) {
    const theme = useTheme();
    const { classes } = props;

    const [hover, setHover] = useState(false);

    let circleStyle = {
        display: props.isSelected ? "block" : "none"
    };

    function fill() {
        if (props.isSelected) return props.selectedColor;
        else if (hover) return props.hoverColor;
        return props.color;
    }

    function visibility() {
        if (props.isSelected || (props.isSelectable && props.parentHover))
            return "visible";
        return "hidden";
    }

    return (
        <div
            title="Välj denna"
            sx={{ background: "none",
                  float: "left",
                  width: "36px",
                  height: "36px",
                  border: "none",
                  cursor: "pointer",
                  pointerEvents: "visible" }}
            style={{
                visibility: visibility(),
                paddingLeft: props.paddingLeft,
                paddingTop: props.paddingTop
            }}
            onClick={props.onClick ? e => props.onClick(props.index, e) : null}
            onMouseOver={e => setHover(true)}
            onMouseOut={e => setHover(false)}
        >
            <svg
                fill={fill()}
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <radialGradient
                    id="shadow"
                    cx="38"
                    cy="95.488"
                    r="10.488"
                    gradientTransform="matrix(1 0 0 -1 -26 109)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".832" stopColor="#010101" />
                    <stop offset="1" stopColor="#010101" stopOpacity="0" />
                </radialGradient>

                <circle
                    style={circleStyle}
                    opacity=".26"
                    fill="url(#shadow)"
                    cx="12"
                    cy="13.512"
                    r="10.488"
                />
                <circle
                    style={circleStyle}
                    fill="#FFF"
                    cx="12"
                    cy="12.2"
                    r="8.292"
                />
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(CheckButton);
