import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import { padding } from "@mui/system";

const styles = (theme) => ({
  button: {
    borderRadius: "30%",
    margin: 1,
    outline: "none",
    "&:focus": { outline: 0 },
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    fontSize: "0.8125rem",
  },  
});

const imageNames = {
  cancelDockRoom: require("../images/Cancel.png"),
  checked: require("../images/Checked.png"),
  dockShowRooms: require("../images/DockNewRoom.png"),
  dockShowRemoveableRooms: require("../images/DockRemoveRoom.png"),
  endSelectSectionMark: require("../images/Checked.png"),
  newRoomUnit: require("../images/NewRoomUnit.png"),
  partitionWallAddRemove: require("../images/PartionWallAddRemove.png"),
  partitionWallCancel: require("../images/Cancel.png"),
  cornerChamferAddRemove: require("../images/CornerChamferAddRemove.png"),
  cornerChamferCancel: require("../images/Cancel.png"),
  selectSectionMark: require("../images/SelectSectionMarks.png"),
  showOptions: require("../images/Options.png"),
  showHelp: require("../images/Help.png"),
  showPdfViewer: require("../images/Print.png"),
  showSurroundingBuilding: require("../images/SurroundingBuilding.png"),
  viewSection: require("../images/Section.png"),
  viewPlan2d: require("../images/Plan2d.png"),
};

function CustomToolbarButton(props) {
  const { classes } = props;

  if (props.isOutOfOrder) return null;

  if (!props.status.visible) return null;

  if (props.status.isHidden) return null;

  const imgStyle = props.status.disabled ? { opacity: 0.3 } : {};

  return (
    <Tooltip title={props.title} placement="bottom-start">
      <div>
        <Button
          classes={{ root: classes.button, label: classes.label }}
          variant="text"
          color="primary"
          disabled={props.status.disabled}
          onClick={() => props.onClick(props.command)}
        >
          <Stack>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >            
              {props.status.isCheckButton && props.status.checked && (
                <img src={imageNames["checked"]} alt="" />
              )}
              <img src={imageNames[props.command]} alt="" style={imgStyle} />            
            </Grid>
            <span style={{lineHeight:1.1, paddingTop:10}}>{props.label}</span>          
          </Stack>
        </Button>
      </div>
    </Tooltip>
  );
}

export default withStyles(styles, { withTheme: true })(CustomToolbarButton);
