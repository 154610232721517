import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from "@mui/material/CssBaseline";
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import linq from "linq";
import Modal from "@mui/material/Modal";
import { orange } from "@mui/material/colors";
import produce from "immer";
import { red } from '@mui/material/colors';
import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import ChangeDoorInstructions from "./ChangeDoorInstructions";
import ChangeDoorSupplements from "./ChangeDoorSupplements";
import ChangeIntegerValue from "./ChangeIntegerValue";
import DoorInfo from "./DoorInfo";


const styles = { };

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.1),
    paddingTop: 0,
    paddingBottom: 0,
    border: 0,
    outline: '0',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {      
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {      
      borderRadius: theme.shape.borderRadius,
    },
  },  
  '& .MuiToggleButton-root.Mui-selected': {
    color: 'red',    
    backgroundColor: 'white',        
  },  
}));

const StyledIconButton = styled(IconButton)({  
  paddingTop: '0px',
  paddingBottom: '0px',
  '&:focus': {
    outline: 0,
  },
});

const formControlCheckboxStyle = {
  "& .MuiCheckbox-root": {        
      paddingBottom: 0,      
      paddingTop: 0, 
    },
  "& .MuiRadio-root": {        
      paddingBottom: 0,      
      paddingTop: 0, 
      paddingRight: 1, 
    },
  "& .MuiSvgIcon-root": { 
      fontSize: "1.3rem" 
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem", 
    marginBottom: 0,      
  },
}

function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21.635,6.366c-0.467-0.772-1.043-1.528-1.748-2.229c-0.713-0.708-1.482-1.288-2.269-1.754L19,1C19,1,21,1,22,2S23,5,23,5  L21.635,6.366z M10,18H6v-4l0.48-0.48c0.813,0.385,1.621,0.926,2.348,1.652c0.728,0.729,1.268,1.535,1.652,2.348L10,18z M20.48,7.52  l-8.846,8.845c-0.467-0.771-1.043-1.529-1.748-2.229c-0.712-0.709-1.482-1.288-2.269-1.754L16.48,3.52  c0.813,0.383,1.621,0.924,2.348,1.651C19.557,5.899,20.097,6.707,20.48,7.52z M4,4v16h16v-7l3-3.038V21c0,1.105-0.896,2-2,2H3  c-1.104,0-2-0.895-2-2V3c0-1.104,0.896-2,2-2h11.01l-3.001,3H4z" />
    </SvgIcon>
  );
}

function ChangeDoorProps(props) {
  const theme = useTheme(); 
  const { t } = useTranslation(); 

  const [doorCollection, setDoorCollection] = useState(null);
  const [infoCategories, setInfoCategories] = useState(null);
  const [anyInsulThicknesses, setAnyInsulThicknesses] = useState(false);
  const [anySideHungs, setAnySideHungs] = useState(false);  
  const [anyOpenDirections, setAnyOpenDirections] = useState(false);
  const [doorTypes, setDoorTypes] = useState(null);
  const [doorCaptions, setDoorCaptions] = useState(null);
  const [doorWidths, setDoorWidths] = useState(null);
  const [doorHeights, setDoorHeights] = useState(null);
  const [doorThicknesses, setDoorThicknesses] = useState(null);
  const [doorSideHungs, setDoorSideHungs] = useState(null);
  const [openDirections, setOpenDirections] = useState(null);
  const [allDoorSupplements, setAllDoorSupplements] = useState(null);
  const [usedSupplementTableRows, setUsedSupplementTableRows] = useState(null);

  const [currentDoorData, setCurrentDoorData] = useState({
    doorID: null,
    visible: null,
    isHoleInSitu: false,    
    isSpecial: false,
    type: null,
    width: null,
    height: null,
    heightUs: 0,
    thickness: null,
    sideHung: null,
    direction: null,    
    supplements: null,
    instruction1: "",
    instruction2: "",
    instruction3: "",
  });  

  const [changeSpecialDim, setChangeSpecialDim] = useState({
    dimPrefix: null,
    isActive: false,    
    oldValue: "0",  
    min: -1,
    max: -1,  
  });

  const [editDoorSupplements, setEditDoorSupplements] = useState({    
    isActive: false,
  });

  const [editDoorInstructions, setEditDoorInstructions] = useState({    
    isActive: false,
  });

  const [activeTab, setActiveTab] = React.useState("tk");
  const [infoExpanded, setInfoExpanded] = useState("infoPanel");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      if (props.doorData.isActive) {
        let dtoData = JSON.stringify({
          roomUnitStack: props.roomUnitStack,
          previewSizes: null,
          doorData: {
            doorID: props.doorData.doorID,
            direction: "",
            height: -1,
            instruction1: "",
            instruction2: "",
            instruction3: "",
            isSpecial: false,
            sideHung: "",
            supplements: "",
            thickness: -1,
            type: "",
            width: -1,            
          },
          doorCollection: null,
          isStaff: props.isStaff,
        });
        fetch("/api/CfzWebApi/GetDoorProperties", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => res.json())
          .then((result) => {       
            setActiveTab(result.doorCollection.infoCategories.includes("|TK|") ? "tk" : "tf"); 
            setInfoCategories(result.doorCollection.infoCategories);
            setDoorCollection(result.doorCollection.products);
            setDoorTypes(result.doorCollection.doorTypes);
            setDoorCaptions(result.doorCollection.doorCaptions);                        
            if (result.doorData.visible && result.doorCollection.doorTypes.includes(result.doorData.type)) {
              setCurrentDoorData(result.doorData);
              loadDoorWidths(result.doorCollection.products, result.doorData.type);
              loadDoorHeights(result.doorCollection.products, result.doorData.type, result.doorData.width);
              loadThicknessesSideHungsAndDirections(result.doorCollection.products, result.doorData.type);
              loadDoorSupplements(result.doorCollection.products, result.doorData.type, result.doorData.supplements);              
            }
            else {
              setCurrentDoorData(
                produce((draft) => {
                  draft.doorID = result.doorData.doorID;                  
                  draft.visible = false;
                  draft.sideHung = result.doorData.sideHung;
                })                
              );
            }

            setCurrentDoorData(
              produce((draft) => {
                draft.instruction1 = result.doorData.instruction1;
                draft.instruction2 = result.doorData.instruction2;
                draft.instruction3 = result.doorData.instruction3;
              })                
            );

            setIsLoaded(true);
          });
      }
    }
  }, [isLoaded, props.doorData, props.roomUnitStack, props.isStaff]);  

  const loadProduct = (type) => {
    let dtoData = JSON.stringify({
      type: type,
      isStaff: props.isStaff,
    });
    fetch("/api/CfzWebApi/GetDoorProduct", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: dtoData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setDoorCollection(result.products);          
          loadDoorWidths(result.products, type);
          setDoorHeights(null);
          loadThicknessesSideHungsAndDirections(result.products, type); 
          loadDoorSupplements(result.products, type, currentDoorData.supplements);         
        }        
      );
  };

  const loadDoorWidths = (collection, type) => {
    setDoorWidths(linq.from(linq.from(collection).where(w => w.Type === type).select(w => w.Widths).singleOrDefault()).select(w => w.Width).toArray());
  };

  const loadDoorHeights = (collection, type, width) => {
    setDoorHeights(linq.from(linq.from(collection).where(w => w.Type === type).select(w => w.Widths).singleOrDefault()).where(w => w.Width === width).select(w => w.Heights).toArray()[0]);
  };

  const loadThicknessesSideHungsAndDirections = (collection, type) => {
      const anyThicknesses = linq.from(collection).any(w => w.Type === type && w.Thicknesses !== null);      
      const thicknesses = anyThicknesses ? linq.from(collection).where(w => w.Type === type).select(w => w.Thicknesses).toArray()[0] : null;
      setAnyInsulThicknesses(anyThicknesses);
      setDoorThicknesses(thicknesses);

      const anySideHungs = linq.from(collection).any(w => w.Type === type && w.SideHungs !== null);
      const sideHungs = anySideHungs ? linq.from(collection).where(w => w.Type === type).select(w => w.SideHungs).toArray()[0] : null;
      setAnySideHungs(anySideHungs);
      setDoorSideHungs(sideHungs); 

      const anyOpenDirections = linq.from(collection).any(w => w.Type === type && w.OpenDirections !== null);
      const openDirections = anyOpenDirections ? linq.from(collection).where(w => w.Type === type).select(w => w.OpenDirections).toArray()[0] : null;
      setAnyOpenDirections(anyOpenDirections);
      setOpenDirections(openDirections);
      if (anyOpenDirections && openDirections.includes('Pendel')) {
        setCurrentDoorData(
          produce((draft) => {
            draft.direction = "Pendel";        
          })
        );
      }
  };

  const loadDoorSupplements = (collection, type, currentSupplements) => {
    const allDoorSupplements = linq.from(linq.from(collection).where(w => w.Type === type).select(w => w.Supplements)).toArray()[0];    

    if (currentSupplements !== null && allDoorSupplements !== null){
      const usedItems = [];
      const usedItemsAsText = [];
      const split = currentSupplements.split('|');
      split.forEach(element => {
          if (element !== null && element !== ""){
              const childSplit = element.split('#');
              let index1 = allDoorSupplements.findIndex((w) => w.ID === childSplit[0]);
              if (index1 >= 0) {
                let text = allDoorSupplements[index1].Caption;
                if (childSplit[1] !== null && childSplit[1] !== "" && allDoorSupplements[index1].Variants !== null) {
                  let index2 = allDoorSupplements[index1].Variants.findIndex((w) => w.Value === childSplit[1]);
                  if (index2 >= 0){
                    text = text.concat(": " + allDoorSupplements[index1].Variants[index2].Text);
                  }
                }
                usedItems.push(element);
                usedItemsAsText.push(text);
              }
          }
      });
            
      if (usedItems.length > 0) {
        usedItems.unshift("");
        usedItems.push("");        
        setCurrentDoorData(
          produce((draft) => {
            draft.supplements = usedItems.join("|");
          })
        );
      }
      else {
        setCurrentDoorData(
          produce((draft) => {
            draft.supplements = null;
          })
        );
      }

      setUsedSupplementTableRows(usedItemsAsText);
    }

    setAllDoorSupplements(allDoorSupplements);
  };

  if (!isLoaded) return null;


  const isSaveChangesDisabled = () => {        
    if (currentDoorData.type === null || doorTypes === null || !doorTypes.includes(currentDoorData.type))
      return true;

    if (currentDoorData.isSpecial){
      if (currentDoorData.width === null || currentDoorData.width === 0)
        return true;

      if (currentDoorData.height === null || currentDoorData.height === 0)
        return true;
    }
    else if (currentDoorData.type === "HÅL") {
      if (currentDoorData.width === null || currentDoorData.width === 0)
        return true;

      if (currentDoorData.height === null || currentDoorData.height === 0)
        return true;

      if (currentDoorData.heightUs === null)
        return true;
    }
    else {
      if (currentDoorData.width === null || doorWidths === null || !doorWidths.includes(currentDoorData.width))
        return true;

      if (currentDoorData.height === null || doorHeights === null || !doorHeights.includes(currentDoorData.height))
        return true;

      if (anyInsulThicknesses && (currentDoorData.thickness === null || doorThicknesses === null || !doorThicknesses.includes(currentDoorData.thickness))){
        console.log("invalid thicknesses", anyInsulThicknesses);
        return true;
      }
    }

    if (anySideHungs && (currentDoorData.sideHung === null || doorSideHungs === null || !doorSideHungs.includes(currentDoorData.sideHung))){
        console.log("invalid sidehungs");
        return true;
    }

    if (anyOpenDirections && (currentDoorData.direction === null || openDirections === null || !openDirections.includes(currentDoorData.direction)))
        return true;

    return false;
  };

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setInfoExpanded(newExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDoorTypeImageClicked = (newValue) => {
    onDoorTypeChanged(newValue);
  };

  const handleDoorTypeChanged = (event, newValue) => {
    onDoorTypeChanged(newValue);
  };

  const onDoorTypeChanged = (newValue) => {
    if (newValue !== null){
      if (currentDoorData.isSpecial){
        setCurrentDoorData(
          produce((draft) => {
            draft.type = newValue;            
          })
        );
      }
      else {
        setCurrentDoorData(
          produce((draft) => {
            draft.type = newValue;
            draft.width = null;
            draft.height = null;
          })
        ); 
      }

      loadProduct(newValue);      
    }
  };

  const handleSpecialDimChecked = (event) => {    
    setCurrentDoorData(
      produce((draft) => {
        draft.isSpecial = event.target.checked;          
      })
    );    
  };

  const handleSpecialDoorWidthClicked = (event, curValue) => {    
    setChangeSpecialDim({
      dimPrefix: 'b:',
      isActive: true,
      oldValue: currentDoorData.width,
      propertyName: 'width',
    });
  };

  const handleSpecialDoorHeightClicked = (event, curValue) => {
    setChangeSpecialDim({
      dimPrefix: 'h:',
      isActive: true,
      oldValue: currentDoorData.height,
      propertyName: 'height',
    });
  };

  const handleHeightUsClicked = (event, curValue) => {
    setChangeSpecialDim({
      dimPrefix: 'h:',
      isActive: true,
      oldValue: currentDoorData.heightUs,
      propertyName: 'heightUs',
    });
  };

  const handleSpecialDimChanged = (propertyName, newValue, oldValue) => {
    if (newValue === null || newValue === '')
      return;

    setChangeSpecialDim({
      isActive: false,
    });

    if (newValue === oldValue)
      return;

    switch(propertyName)
    {
      case 'width':
        setCurrentDoorData(
          produce((draft) => {
            draft.width = newValue;
          })
        );
        break;

      case 'height':
        setCurrentDoorData(
          produce((draft) => {
            draft.height = newValue;          
          })
        );
        break;

      case 'heightUs':
        setCurrentDoorData(
          produce((draft) => {
            draft.heightUs = newValue;
          })
        );
        break;

      default:
        break;
    }
  };

  const handleCloseSpecialDim = () => {
    setChangeSpecialDim({
      isActive: false,
    });
  };

  const handleDoorWidthChanged = (event, newValue) => {
    if (newValue !== null){
      setCurrentDoorData(
        produce((draft) => {
          draft.width = newValue;        
        })
      );

      loadDoorHeights(doorCollection, currentDoorData.type, newValue);      
    }
  };

  const handleDoorHeightChanged = (event, newValue) => {
    if (newValue !== null){
      setCurrentDoorData(
        produce((draft) => {
          draft.height = newValue;          
        })
      );

      loadThicknessesSideHungsAndDirections(doorCollection, currentDoorData.type);
    }
  };

  const handleDoorInsulThicknessChanged = (event, newValue) => {
    if (newValue !== null){
      setCurrentDoorData(
        produce((draft) => {
          draft.thickness = newValue;        
        })
      );
    }
  };

  const handleDoorSideHungChanged = (event, newValue) => {
    if (newValue !== null){
      setCurrentDoorData(
        produce((draft) => {
          draft.sideHung = newValue;        
        })
      );
    }
  };

  const handleDoorDirectionChanged = (event, newValue) => {
    if (newValue !== null){
      setCurrentDoorData(
        produce((draft) => {
          draft.direction = newValue;        
        })
      );
    }
  };   

  const handleHoleInSituChange = (event) => {
    setCurrentDoorData(
      produce((draft) => {
        draft.isHoleInSitu = event.target.checked;
      })
    );    
  };

  const handleEditDoorSupplements = () => {
    setEditDoorSupplements({
      isActive: true,
    });
  };

  const handleEndEditDoorSupplements = (updatedDoorSupplements) => {
    let result = "|";
    const usedItems = [];
    updatedDoorSupplements.forEach(item => {
      if (item.IsChecked){
        result = result.concat(item.ID + "#");
        if (item.VariantValue !== null && item.VariantValue !== "") {
          result = result.concat(item.VariantValue + "|");
        }
        else {
          result = result.concat("|");
        }

        let usedItem = item.Caption;
        if (item.VariantValue !== null && item.VariantValue !== "" && item.Variants !== null) {
          let index = item.Variants.findIndex((w) => w.Value === item.VariantValue);
          usedItem = usedItem.concat(": " + item.Variants[index].Text);          
        }        
        usedItems.push(usedItem);
      }
    });
    setCurrentDoorData(
      produce((draft) => {
        draft.supplements = result;
      })
    );
    setUsedSupplementTableRows(usedItems);
    setEditDoorSupplements({
      isActive: false,
    });
  };
  
  const handleEditDoorInstructions = () => {
    setEditDoorInstructions({
      isActive: true,
    });
  };

  const handleEndEditDoorInstructions = (instruction1, instruction2, instruction3) => {
    setCurrentDoorData(
      produce((draft) => {
        draft.instruction1 = instruction1;
        draft.instruction2 = instruction2;
        draft.instruction3 = instruction3;
      })                
    );
    setEditDoorInstructions({
      isActive: false,
    });
  };

  const getTypeTooltipText = (index) => {
    return doorCaptions[index];
  };

  if (props.doorData.isActive) {
    return (
      <Box sx={{display: "flex", flexWrap: "wrap"}}>
        <CssBaseline />
        <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
          <Card sx={{maxWidth: 650, height: "100%", overflowY: "auto" }}>
            <CardHeader
              action={
                <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                  <IconButton onClick={props.onClose} size="large">
                    <CancelPresentationIcon style={{ color: red[500] }} />
                  </IconButton>                  
                </Tooltip>                
              }
              title={t('changeDoorProps.card.title')}
            />
            <CardContent>
              <FormControl sx={{margin: theme.spacing(1), minWidth: 330}}>

                <Accordion 
                  expanded={infoExpanded === "infoPanel"}
                  onChange={handleAccordionChange("infoPanel")}
                  >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="infoPaneld-content"
                    id="infoPaneld-header"
                    sx={{"& .MuiAccordionSummary-content.Mui-expanded": { marginBottom: 0 }}}>
                    <Typography fontWeight="fontWeightBold">{t('changeDoorProps.accordionSummary.title')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                      <Tabs                                                 
                        indicatorColor="primary"
                        sx={{ "& button": { paddingBottom: 0 }, "& button:focus": { outline: "0" }, paddingBottom: 0}}
                        textColor="primary"
                        value={activeTab}
                        onChange={handleTabChange}
                        >
                        {infoCategories.includes("|TF|") && (
                          <Tab label="Anslagsdörrar" value="tf"/>
                        )}
                        {infoCategories.includes("|TK|") && (
                          <Tab label="Anslagsdörrar" value="tk"/>                      
                        )}
                        {infoCategories.includes("|TP|") && (
                          <Tab label="Pendeldörrar" value="tp" />
                        )}
                        {infoCategories.includes("|TSF|") && (
                          <Tab label="Skjutdörrar/portar" value="tsf" />
                        )}
                        {infoCategories.includes("|TSK|") && (
                          <Tab label="Skjutdörrar/portar" value="tsk" />
                        )}
                      </Tabs> 
                    </Box>

                    {activeTab === "tf" && (
                      <DoorInfo infoCategory="tf" onTypeSelected={handleDoorTypeImageClicked} />
                    )}
                    {activeTab === "tk" && (
                      <DoorInfo infoCategory="tk" onTypeSelected={handleDoorTypeImageClicked} />
                    )}
                    {activeTab === "tp" && (
                      <DoorInfo infoCategory="tp" onTypeSelected={handleDoorTypeImageClicked} />
                    )}
                    {activeTab === "tsk" && (
                      <DoorInfo infoCategory="tsk" onTypeSelected={handleDoorTypeImageClicked} />
                    )}
                    {activeTab === "tsf" && (
                      <DoorInfo infoCategory="tsf" onTypeSelected={handleDoorTypeImageClicked} />
                    )}
                    <Divider sx={{mt:1}} />
                    <Typography component="p" mt={1} variant="body2">
                      {t('changeDoorProps.moreDoors')}
                    </Typography>
                  </AccordionDetails>

                </Accordion>

                <Typography display="flex" component="div" mt={2} noWrap={true}>
                  <Box component="span" fontWeight="fontWeightBold">
                    Välj dörregenskaper:
                  </Box>                                      
                  <Button 
                    display="inline" 
                    disabled={isSaveChangesDisabled()}
                    size="small"
                    sx={{ borderColor: green[500], color: green[500], margin:"auto" }} 
                    variant="outlined"
                    onClick={() => {props.onChange(currentDoorData);}}
                  >
                      Verkställ mina val
                  </Button>
                </Typography>

                <Typography component="div" mt={1}>
                  <Box component="span" fontWeight="fontWeightBold">
                  <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorType')}</Typography>:
                  </Box>
                  <Box m={0}>
                    <StyledToggleButtonGroup
                        size="small"                    
                        exclusive                    
                        value={currentDoorData.type}
                        onChange={handleDoorTypeChanged}
                      >
                        {doorTypes.map((doorType, index) => (                          
                            <ToggleButton key={index} value={doorType} >
                              <Tooltip title={getTypeTooltipText(index)} placement="bottom-start">
                                <span>{doorType}</span>
                              </Tooltip>
                            </ToggleButton>                          
                        ))}
                    </StyledToggleButtonGroup>                    
                  </Box>
                </Typography>
              
                {props.isStaff && currentDoorData.type !== "HÅL" && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                      <Typography sx={{textDecoration: 'underline'}} display="inline">Specialmått</Typography>: 
                      <Checkbox 
                        checked={currentDoorData.isSpecial} 
                        display="inline" 
                        size="small"                         
                        onChange={handleSpecialDimChecked} 
                    /> 
                    </Box>                    
                  </Typography>
                )}

                {(currentDoorData.isSpecial || currentDoorData.type === "HÅL") && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                    <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorWidth')}</Typography>:                      
                    </Box>   
                    <Box m={0}>
                      <Typography sx={{color:"red", fontSize: "0.8125rem", fontWeight: 500, paddingLeft: "7px"}} display="inline">{currentDoorData.width}</Typography>                        
                      <StyledIconButton onClick={handleSpecialDoorWidthClicked}>
                        <EditIcon color="primary" fontSize="small" />
                      </StyledIconButton>
                    </Box>
                  </Typography>
                )}

                {(currentDoorData.isSpecial || currentDoorData.type === "HÅL") && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                      <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorHeight')}</Typography>:                      
                    </Box>
                    <Box m={0}>
                      <Typography sx={{color:"red", fontSize: "0.8125rem", fontWeight: 500, paddingLeft: "7px"}} display="inline">{currentDoorData.height}</Typography>
                      <StyledIconButton onClick={handleSpecialDoorHeightClicked}>
                        <EditIcon color="primary" fontSize="small" />
                      </StyledIconButton>
                    </Box>                    
                  </Typography>
                )}

                {currentDoorData.type === "HÅL" && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                      <Typography sx={{textDecoration: 'underline'}} display="inline">{"Höjd understycke"}</Typography>:                      
                    </Box>
                    <Box m={0}>
                      <Typography sx={{color:"red", fontSize: "0.8125rem", fontWeight: 500, paddingLeft: "7px"}} display="inline">{currentDoorData.heightUs}</Typography>
                      <StyledIconButton onClick={handleHeightUsClicked}>
                        <EditIcon color="primary" fontSize="small" />
                      </StyledIconButton>
                    </Box>
                  </Typography>
                )}
                
                {!currentDoorData.isSpecial && currentDoorData.type !== "HÅL" && doorWidths !== null && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                    <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorWidth')}</Typography>:
                    </Box>
                    <Box m={0}>                    
                        <StyledToggleButtonGroup
                            size="small"                    
                            exclusive                    
                            value={currentDoorData.width}
                            onChange={handleDoorWidthChanged}
                          >
                            {doorWidths.map((doorWidth, index) => (
                              <ToggleButton key={index} value={doorWidth} >
                                {doorWidth}
                              </ToggleButton>
                            ))}
                        </StyledToggleButtonGroup>
                    </Box>
                  </Typography>
                )}

                
                {!currentDoorData.isSpecial && currentDoorData.type !== "HÅL" && doorHeights !== null && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                    <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorHeight')}</Typography>:
                    </Box>
                    <Box m={0}>                    
                        <StyledToggleButtonGroup
                              size="small"                    
                              exclusive                    
                              value={currentDoorData.height}
                              onChange={handleDoorHeightChanged}
                            >
                              {doorHeights.map((doorHeight, index) => (
                                <ToggleButton key={index} value={doorHeight} >
                                  {doorHeight}
                                </ToggleButton>
                              ))}
                        </StyledToggleButtonGroup>                                            
                    </Box>
                  </Typography>
                )}                
                
                {!currentDoorData.isSpecial && currentDoorData.type !== "HÅL" && doorThicknesses !== null && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                    <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorInsulThickness')}</Typography>:
                    </Box>
                    <Box m={0}>                      
                        <StyledToggleButtonGroup
                                size="small"                    
                                exclusive                    
                                value={currentDoorData.thickness}
                                onChange={handleDoorInsulThicknessChanged}
                              >
                                {doorThicknesses.map((doorThickness, index) => (
                                  <ToggleButton key={index} value={doorThickness} >
                                    {doorThickness}
                                  </ToggleButton>
                                ))}
                        </StyledToggleButtonGroup>
                    </Box>
                  </Typography>
                )}                
                
                {doorSideHungs!== null && currentDoorData.type !== "HÅL" && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                    <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorSideHung')}</Typography>:
                    </Box>
                    <Box m={0}>
                      
                        <StyledToggleButtonGroup
                                  size="small"                    
                                  exclusive                    
                                  value={currentDoorData.sideHung}
                                  onChange={handleDoorSideHungChanged}
                                >
                                  {doorSideHungs.map((doorSideHung, index) => (
                                    <ToggleButton key={index} value={doorSideHung} >
                                      {doorSideHung}
                                    </ToggleButton>
                                  ))}
                        </StyledToggleButtonGroup>                        
                    </Box>
                  </Typography>
                )}


                {openDirections!== null && currentDoorData.type !== "HÅL" && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                      <Typography sx={{textDecoration: 'underline'}} display="inline">{t('changeDoorProps.doorDirection')}</Typography>:
                    </Box>
                    <Box m={0}>                    
                        <StyledToggleButtonGroup
                                    size="small"                    
                                    exclusive                    
                                    value={currentDoorData.direction}
                                    onChange={handleDoorDirectionChanged}
                                  >
                                    {openDirections.map((openDirection, index) => (
                                      <ToggleButton key={index} value={openDirection} >
                                        {openDirection}
                                      </ToggleButton>
                                    ))}
                        </StyledToggleButtonGroup>                                            
                    </Box>
                  </Typography>
                )}                 

                {currentDoorData.type !== null && (
                  <Typography component="div" mt={1}>
                    <Box component="span" fontWeight="fontWeightBold">
                      <Typography sx={{textDecoration: 'underline'}} display="inline">{"Håltagning"}</Typography>:
                    </Box>
                    <Box m={0}>                    
                      <FormControlLabel
                        control={<Checkbox checked={currentDoorData.isHoleInSitu} onChange={handleHoleInSituChange} />}
                        label="Håltagning sker på plats"
                        sx={{...formControlCheckboxStyle}}
                      />                        
                    </Box>
                  </Typography>
                )} 

                {changeSpecialDim.isActive && (
                  <ChangeIntegerValue
                    changeData={changeSpecialDim}
                    onOkClicked={handleSpecialDimChanged}
                    onClose={handleCloseSpecialDim}
                  />
                )}

                {!isSaveChangesDisabled() && currentDoorData.type !== "HÅL" && allDoorSupplements !== null && props.isStaff && (
                  <Box>
                  <Typography display="flex" component="div" mt={2} noWrap={true}>                    
                    <Typography sx={{textDecoration: 'underline'}} display="inline">Tillval dörr</Typography>:                                          
                    <Button 
                      display="inline"
                      size="small"
                      sx={{ borderColor: orange[700], color: orange[700], marginLeft:"40px", paddingTop: "2px", paddingBottom: "2px" }} 
                      variant="outlined"
                      onClick={handleEditDoorSupplements}
                    >
                        Ändra mina tillval
                    </Button>                  
                  </Typography>
                  {usedSupplementTableRows !== null && (
                    <Box m={0}>
                      <TableContainer>
                        <Table sx={{width: "100%"}} size="small">
                          <TableBody>
                            {usedSupplementTableRows.map((usedItem, index) => (
                              <TableRow
                                key={index}                                
                              >
                                <TableCell component="th" scope="row" sx={{paddingLeft: "5px", paddingTop: "3px", paddingBottom: "3px"}}>{usedItem}</TableCell>
                                <TableCell align="left" sx={{paddingTop: "3px", paddingBottom: "3px"}}></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                  </TableContainer>

                    </Box>                    
                  )}
                  </Box>
                )}

                {editDoorSupplements.isActive && currentDoorData.type !== "HÅL" && (
                  <ChangeDoorSupplements
                    doorData={currentDoorData}
                    doorSupplements={allDoorSupplements}
                    isStaff={props.isStaff}
                    roomUnitStack={props.roomUnitStack}
                    onClose={handleEndEditDoorSupplements}
                  />
                )}

                {!isSaveChangesDisabled() && props.isStaff && (
                  <Box>
                    <Typography display="flex" component="div" mt={2} noWrap={true}>                    
                      <Typography sx={{textDecoration: 'underline'}} display="inline">Instruktioner</Typography>:                                          
                      <Button 
                        display="inline"
                        size="small"
                        sx={{ borderColor: orange[700], color: orange[700], marginLeft:"40px", paddingTop: "2px", paddingBottom: "2px" }} 
                        variant="outlined"
                        onClick={handleEditDoorInstructions}
                      >
                          Ändra mina instruktioner
                      </Button>                  
                    </Typography>
                    <Typography>{currentDoorData.instruction1}</Typography>
                    <Typography>{currentDoorData.instruction2}</Typography>
                    <Typography>{currentDoorData.instruction3}</Typography>
                  </Box>
                )}

                {editDoorInstructions.isActive && (
                  <ChangeDoorInstructions  
                    instruction1 = {currentDoorData.instruction1}
                    instruction2 = {currentDoorData.instruction2}
                    instruction3 = {currentDoorData.instruction3}
                    onClose={handleEndEditDoorInstructions}
                  />
                )}

              </FormControl>
            </CardContent>
          </Card>
        </Modal>
      </Box>      
    );
  }
  return null;
}

export default withStyles(styles)(ChangeDoorProps);
