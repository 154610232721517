import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CssBaseline from "@mui/material/CssBaseline";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import ShelvingShapeImage from "./ShelvingShapeImage";
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";

const styles = {   
  imageCenter: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const BoxWidthTop = styled(Box)(({ theme }) => ({
  height: 70, 
  width: 110,
}));

const BoxWidth = styled(Box)(({ theme }) => ({
  height: 110,
  width: 110,
}));

const shelvingHeights = [
  { value: 1480, label: "1480 mm" },
  { value: 1800, label: "1800 mm" },
];

const shelvingWidths = [
  { value: 400, label: "400 mm" },
  { value: 500, label: "500 mm" },
  { value: 600, label: "600 mm" },
];

const shelfLevels = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];

let shelfTypes = [
  { value: "Solid", label: "Släta hyllor" },
  { value: "Grid", label: "Galler hyllor" },
];

let shelfMaterials = [
  { value: "Al", label: "Aluminium" },
  { value: "Rfr", label: "Rostfritt" },
];

const imageNames = {
  I1: require("../images/I1.png"),
  I2: require("../images/I2.png"),
  I3: require("../images/I3.png"),
  I4: require("../images/I4.png"),
  I13: require("../images/I13.png"),
  I24: require("../images/I24.png"),
  L12: require("../images/L12.png"),
  L23: require("../images/L23.png"),
  L34: require("../images/L34.png"),
  L41: require("../images/L41.png"),
  U123: require("../images/U123.png"),
  U234: require("../images/U234.png"),
  U341: require("../images/U341.png"),
  U412: require("../images/U412.png"),
  O1234: require("../images/O1234.png"),
};

function ChangeShelvings(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { classes } = props;
  const [shapeCode, setShapeCode] = useState(null);
  const [shelvesHeight, setShelvesHeight] = useState(null);
  const [shelvesWidthLeft, setShelvesWidthLeft] = useState(null);
  const [shelvesWidthTop, setShelvesWidthTop] = useState(null);
  const [shelvesWidthRight, setShelvesWidthRight] = useState(null);
  const [shelvesWidthBottom, setShelvesWidthBottom] = useState(null);
  const [numShelfLevels, setNumShelfLevels] = useState(null);
  const [shelfType, setShelfType] = useState(null);
  const [shelfMaterial, setShelfMaterial] = useState(null);
  const [shelvingUnitID, setShelvingUnitID] = useState(null);
  const [roomID, setRoomID] = useState(null);

  useEffect(() => {    
    setShapeCode(props.shelvingData.shapeCode);
    switch(i18n.language)
    {
      case "fi":
        shelfTypes[0].label = t('changeShelvings.shelfTypes.solid');
        shelfTypes[1].label = t('changeShelvings.shelfTypes.grid');
        shelfMaterials[0].label = t('changeShelvings.shelfMaterials.al');
        shelfMaterials[1].label = t('changeShelvings.shelfMaterials.rfr');
        break;

      case "sv":
      default:
        break;
    }
  }, [props.shelvingData.shapeCode]);

  useEffect(() => {
    setShelvesHeight(props.shelvingData.shelvesHeight);
    setShelvesWidthLeft(props.shelvingData.shelvesWidths[0]);
    setShelvesWidthTop(props.shelvingData.shelvesWidths[1]);
    setShelvesWidthRight(props.shelvingData.shelvesWidths[2]);
    setShelvesWidthBottom(props.shelvingData.shelvesWidths[3]);
    setNumShelfLevels(props.shelvingData.numShelfLevels);
    setShelfType(props.shelvingData.shelfType);
    setShelfMaterial(props.shelvingData.shelfMaterial);
    setShelvingUnitID(props.shelvingData.shelvingUnitID);
    setRoomID(props.shelvingData.roomID);
  }, [
    props.shelvingData.shelvesHeight,
    props.shelvingData.shelvesWidths,
    props.shelvingData.numShelfLevels,
    props.shelvingData.shelfType,
    props.shelvingData.shelfMaterial,
    props.shelvingData.shelvingUnitID,
    props.shelvingData.roomID,
  ]);

  const handleShelvingShapeCodeClicked = (value) => {
    if (value !== shapeCode) {
      setShapeCode(value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      props.onChange({
        shelvingUnitID: shelvingUnitID,
        roomID: roomID,
        shapeCode: value,
        shelvesHeight: shelvesHeight,
        shelvesWidths: shelvesWidths,
        numShelfLevels: numShelfLevels,
        shelfType: shelfType,
        shelfMaterial: shelfMaterial,
      });
    }
  };

  const handleShelvesHeightChanged = (args) => {
    if (args.itemData.value !== shelvesHeight) {
      setShelvesHeight(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      props.onChange({
        shelvingUnitID: shelvingUnitID,
        roomID: roomID,
        shapeCode: shapeCode,
        shelvesHeight: args.itemData.value,
        shelvesWidths: shelvesWidths,
        numShelfLevels: numShelfLevels,
        shelfType: shelfType,
        shelfMaterial: shelfMaterial,
      });
    }
  };

  const handleShelvesWidthLeftChanged = (args) => {
    if (args.itemData.value !== shelvesWidthLeft) {
      setShelvesWidthLeft(args.itemData.value);
      var shelvesWidths = [
        args.itemData.value,
        shelvesWidthTop,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      handleShelvesWidthChanged(shelvesWidths);
    }
  };

  const handleShelvesWidthTopChanged = (args) => {
    if (args.itemData.value !== shelvesWidthTop) {
      setShelvesWidthTop(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        args.itemData.value,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      handleShelvesWidthChanged(shelvesWidths);
    }
  };

  const handleShelvesWidthRightChanged = (args) => {
    if (args.itemData.value !== shelvesWidthRight) {
      setShelvesWidthRight(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        args.itemData.value,
        shelvesWidthBottom,
      ];
      handleShelvesWidthChanged(shelvesWidths);
    }
  };

  const handleShelvesWidthBottomChanged = (args) => {
    if (args.itemData.value !== shelvesWidthBottom) {
      setShelvesWidthBottom(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        shelvesWidthRight,
        args.itemData.value,
      ];
      handleShelvesWidthChanged(shelvesWidths);
    }
  };

  const handleShelvesWidthChanged = (shelvesWidths) => {
    props.onChange({
      shelvingUnitID: shelvingUnitID,
      roomID: roomID,
      shapeCode: shapeCode,
      shelvesHeight: shelvesHeight,
      shelvesWidths: shelvesWidths,
      numShelfLevels: numShelfLevels,
      shelfType: shelfType,
      shelfMaterial: shelfMaterial,
    });
  };

  const handleNumShelfLevelsChanged = (args) => {
    if (args.itemData.value !== numShelfLevels) {
      setNumShelfLevels(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      props.onChange({
        shelvingUnitID: shelvingUnitID,
        roomID: roomID,
        shapeCode: shapeCode,
        shelvesHeight: shelvesHeight,
        shelvesWidths: shelvesWidths,
        numShelfLevels: args.itemData.value,
        shelfType: shelfType,
        shelfMaterial: shelfMaterial,
      });
    }
  };

  const handleShelfTypeChanged = (args) => {
    if (args.itemData.value !== shelfType) {
      setShelfType(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      props.onChange({
        shelvingUnitID: shelvingUnitID,
        roomID: roomID,
        shapeCode: shapeCode,
        shelvesHeight: shelvesHeight,
        shelvesWidths: shelvesWidths,
        numShelfLevels: numShelfLevels,
        shelfType: args.itemData.value,
        shelfMaterial: shelfMaterial,
      });
    }
  };

  const handleShelfMaterialChanged = (args) => {
    if (args.itemData.value !== shelfMaterial){
      setShelfMaterial(args.itemData.value);
      var shelvesWidths = [
        shelvesWidthLeft,
        shelvesWidthTop,
        shelvesWidthRight,
        shelvesWidthBottom,
      ];
      props.onChange({
        shelvingUnitID: shelvingUnitID,
        roomID: roomID,
        shapeCode: shapeCode,
        shelvesHeight: shelvesHeight,
        shelvesWidths: shelvesWidths,
        numShelfLevels: numShelfLevels,
        shelfType: shelfType,
        shelfMaterial: args.itemData.value,
      });
    }
  };

  if (props.shelvingData.isActive) {
    return (
      <Box sx={{display: "flex", flexWrap: "wrap"}}>
        <CssBaseline />
        <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
          <Card sx={{maxWidth: 500, height: "100%", overflowY: "auto"}}>
            <CardHeader
              action={
                <Tooltip title={"Stäng"} placement="bottom-start">
                  <IconButton onClick={props.onClose} size="large">
                    <CheckBoxIcon style={{ color: green[500] }} />
                  </IconButton>
                </Tooltip>
              }
              title={t('changeShelvings.title')}
            />
            <CardContent>
              <Grid container>
                <div className="col-*-3">
                  <ShelvingShapeImage
                    shapeCode="I1"
                    selectedShapeCode={shapeCode}
                    onClick={handleShelvingShapeCodeClicked}
                  />
                </div>
                <div className="col-*-3">
                  <ShelvingShapeImage
                    shapeCode="I2"
                    selectedShapeCode={shapeCode}
                    onClick={handleShelvingShapeCodeClicked}
                  />
                </div>
                <div className="col-*-3">
                  <ShelvingShapeImage
                    shapeCode="I3"
                    selectedShapeCode={shapeCode}
                    onClick={handleShelvingShapeCodeClicked}
                  />
                </div>
                <div className="col-*-3">
                  <ShelvingShapeImage
                    shapeCode="I4"
                    selectedShapeCode={shapeCode}
                    onClick={handleShelvingShapeCodeClicked}
                  />
                </div>
              </Grid>
              <Grid container>
                <ShelvingShapeImage
                  shapeCode="I13"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="I24"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <Box sx={{height: 84, width: 84}}></Box>
                <ShelvingShapeImage
                  shapeCode="O1234"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
              </Grid>
              <Grid container>
                <ShelvingShapeImage
                  shapeCode="L12"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="L23"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="L34"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="L41"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
              </Grid>
              <Grid container>
                <ShelvingShapeImage
                  shapeCode="U123"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="U234"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="U341"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
                <ShelvingShapeImage
                  shapeCode="U412"
                  selectedShapeCode={shapeCode}
                  onClick={handleShelvingShapeCodeClicked}
                />
              </Grid>

              <Grid>
                <DropDownListComponent
                  id="shelvingHeight"
                  dataSource={shelvingHeights}
                  fields={{
                    text: "label",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeShelvings.shelvingHeight.placeholder')}
                  value={shelvesHeight}
                  select={handleShelvesHeightChanged}
                />
              </Grid>
              <Grid>
                <DropDownListComponent
                  id="numShelfLevels"
                  dataSource={shelfLevels}
                  fields={{
                    text: "label",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeShelvings.numShelfLevels.placeholder')}
                  value={numShelfLevels}
                  select={handleNumShelfLevelsChanged}
                />
              </Grid>
              <Grid>
                <DropDownListComponent
                  id="shelfType"
                  dataSource={shelfTypes}
                  fields={{
                    text: "label",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeShelvings.shelfType.placeholder')}
                  value={shelfType}
                  select={handleShelfTypeChanged}
                />
              </Grid>
              <Grid>
                <DropDownListComponent
                  id="shelfMaterial"
                  dataSource={shelfMaterials}
                  fields={{
                    text: "label",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeShelvings.shelfMaterial.placeholder')}
                  value={shelfMaterial}
                  select={handleShelfMaterialChanged}
                />
              </Grid>

              <Grid container>
                <BoxWidthTop />
                <BoxWidthTop                  
                  display="flex"
                  alignItems="flex-end"
                >
                  {shapeCode !== null && shapeCode.includes("2") && (
                    <DropDownListComponent
                      id="shelvingWidthTop"
                      dataSource={shelvingWidths}
                      fields={{
                        text: "label",
                        value: "value",
                      }}
                      floatLabelType="Always"
                      placeholder={t('changeShelvings.shelvingWidth.placeholder')}
                      value={shelvesWidthTop}
                      select={handleShelvesWidthTopChanged}
                    />
                  )}
                </BoxWidthTop>
                <BoxWidthTop />
              </Grid>

              <Grid container>
                <BoxWidth                  
                  display="flex"
                  alignItems="center"
                  pr={2}
                >
                  {shapeCode !== null && shapeCode.includes("1") && (
                    <DropDownListComponent
                      id="shelvingWidthLeft"
                      dataSource={shelvingWidths}
                      fields={{
                        text: "label",
                        value: "value",
                      }}
                      floatLabelType="Always"
                      placeholder={t('changeShelvings.shelvingWidth.placeholder')}
                      value={shelvesWidthLeft}
                      select={handleShelvesWidthLeftChanged}
                    />
                  )}
                </BoxWidth>
                <BoxWidth                  
                  display="flex"
                  alignItems="center"
                  justifyItems="center"
                >
                  <img
                    alt=""
                    src={imageNames[shapeCode]}
                    className={classes.imageCenter}
                  />
                </BoxWidth>
                <BoxWidth                  
                  display="flex"
                  alignItems="center"
                  pl={2}
                >
                  {shapeCode !== null && shapeCode.includes("3") && (
                    <DropDownListComponent
                      id="shelvingWidthRight"
                      dataSource={shelvingWidths}
                      fields={{
                        text: "label",
                        value: "value",
                      }}
                      floatLabelType="Always"
                      placeholder={t('changeShelvings.shelvingWidth.placeholder')}
                      value={shelvesWidthRight}
                      select={handleShelvesWidthRightChanged}
                    />
                  )}
                </BoxWidth>
              </Grid>

              <Grid container>
                <BoxWidth />
                <BoxWidth>
                  {shapeCode !== null && shapeCode.includes("4") && (
                    <DropDownListComponent
                      id="shelvingWidthBottom"
                      dataSource={shelvingWidths}
                      fields={{
                        text: "label",
                        value: "value",
                      }}
                      floatLabelType="Always"
                      placeholder={t('changeShelvings.shelvingWidth.placeholder')}
                      value={shelvesWidthBottom}
                      select={handleShelvesWidthBottomChanged}
                    />
                  )}
                </BoxWidth>
                <BoxWidth />
              </Grid>
            </CardContent>
          </Card>
        </Modal>
      </Box>
    );
  }

  return null;
}

export default withStyles(styles)(ChangeShelvings);
