import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import produce from "immer";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import SweSettings from "./localization/AllProFormManagerSweSettings";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";
import { useTranslation } from 'react-i18next';
import MessageDialog from "./MessageDialog";

const styles = { };

const TextFieldEx = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "400px",
  ".MuiInputLabel-asterisk": { color: "red" },
}));

const formConfigSwe = {
  fieldDefaults: {
    isMUIControl: true,
    validateOnBlur: true,
    revalidateOnChange: true,
  },

  errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

  fields: {
    email: {
      displayName: "E-postadress",
      alias: "email",
      validation: {
        required: true,
        email: true,
      },
      errorMessages: {
        required: "En giltig e-postadress måste anges.",
      },
    },
    password: {
      displayName: "Lösenord",
      inputType: "password",
      validation: {
        required: true,
        password: true,
        minLength: 4,
        maxLength: 24,
        passwordComplexity: { lower: 0, upper: 0, number: 0, symbol: 0 },
      },
      errorMessages: {
        required: "Lösenord måste anges.",
        minLength: "Lösenord måste innehålla minst 4 tecken, helst 6 tecken eller fler.",
        maxLength: "Lösenord får inte innehålla fler än 24 tecken.",
      },
    },
    confirmPassword: {
      displayName: "Bekräfta lösenord",
      inputType: "password",
      validation: {
        custom: (value, fieldname, unknown, form) => {
          const passwordValue = form.value("password");
          if (passwordValue !== value) {
            return "Lösenord och det bekräftade lösenordet överenstämmer inte";
          } else {
            return true;
          }
        },
      },
    },
    authCode: {
      displayName: "Behörighetskod",
      validation: {
        required: true,
      },
      errorMessages: {
        required: "Behörighetskod måste anges.",
      },
    },
  },
};

const formConfigFin = {
  fieldDefaults: {
    isMUIControl: true,
    validateOnBlur: true,
    revalidateOnChange: true,
  },

  errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

  fields: {
    email: {
      displayName: "Sähköpostiosoite",
      alias: "email",
      validation: {
        required: true,
        email: true,
      },
      errorMessages: {
        required: "Kelvollinen sähköpostiosoite on annettava.",
      },
    },
    password: {
      displayName: "Salasana",
      inputType: "password",
      validation: {
        required: true,
        password: true,
        minLength: 4,
        maxLength: 24,
        passwordComplexity: { lower: 0, upper: 0, number: 0, symbol: 0 },
      },
      errorMessages: {
        required: "Salasana on syötettävä.",
        minLength: "Salasanan tulee sisältää vähintään 4 merkkiä, mieluiten 6 merkkiä tai enemmän.",
        maxLength: "Salasanat voivat sisältää enintään 24 merkkiä.",
      },
    },
    confirmPassword: {
      displayName: "Vahvista salasana",
      inputType: "password",
      validation: {
        custom: (value, fieldname, unknown, form) => {
          const passwordValue = form.value("password");
          if (passwordValue !== value) {
            return "Salasana ja vahvistettu salasana eivät täsmää";
          } else {
            return true;
          }
        },
      },
    },
    authCode: {
      displayName: "Valtuutuskoodi",
      validation: {
        required: true,
      },
      errorMessages: {
        required: "Valtuutuskoodi on syötettävä.",
      },
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChangePassword(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { classes } = props;
  const [loading, setLoading] = useState(false);
  const [messageDialog, setMessageDialog] = useState({
    isOpen: false,
    success: false,
    headline: "",
    message: "",
    showOkIcon: false,
    showWarningIcon: false,
  });
  const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe);

  function handleSubmit() {
    form.validate().then((isValid) => {
      if (isValid) {
        setLoading(true);
        let mergedObject = {
          ...form.data(),
          language: i18n.language
        };
        let dtoData = JSON.stringify(mergedObject);
        fetch("/api/Account/ResetPassword", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dtoData,
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error(res.status + " " + res.statusText);
            }
          })
          .then(
            (result) => {
              if (result.succeeded) {
                props.submit();
              } else {
                setMessageDialog(
                  produce((draft) => {
                    draft.isOpen = true;
                    draft.success = false;
                    draft.headline = t('changePassword.error');
                    draft.message = result.errorMessages[0];
                    draft.showOkIcon = false;
                    draft.showWarningIcon = true;
                  })
                );
              }
            },
            (error) => {
              setMessageDialog(
                produce((draft) => {
                  draft.isOpen = true;
                  draft.success = false;
                  draft.headline = t('changePassword.error');
                  draft.message = error.stack;
                  draft.showOkIcon = false;
                  draft.showWarningIcon = true;
                })
              );
            }
          )
          .catch((error) => {
            setMessageDialog(
              produce((draft) => {
                draft.isOpen = true;
                draft.success = false;
                draft.headline = t('changePassword.error');
                draft.message = error.stack;
                draft.showOkIcon = false;
                draft.showWarningIcon = true;
              })
            );
          });
      }
    });
  }

  const handleMessageDialogClose = (success) => {
    if (success) {
      props.onClose();
    } else {
      setLoading(false);
      setMessageDialog(
        produce((draft) => {
          draft.headline = "";
          draft.isOpen = false;
          draft.meassage = "";
          draft.showOkIcon = false;
          draft.showWarningIcon = false;
        })
      );
    }
  };

  form.submit = handleSubmit;

  return (
    <Box sx={{display: "flex"}}>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={true}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <Typography variant="h6" sx={{marginLeft: theme.spacing(2), flex: 1}}>
              {t('changePassword.title')}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="Close"
              onClick={props.onClose}
              size="large">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid item md={12}>
            <Grid container spacing={4}>
              <Grid item md={5}>
                <Box sx={{backgroundColor: "#006bb2", color: "white"}} m={1} p={1}>
                  <b>{t('changePassword.passwordInfo.passwordMust')}</b>
                  <ul>
                    <li>{t('changePassword.passwordInfo.beAtLeastFourChar')}</li>
                  </ul>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Box>
              </Grid>
              <Grid item md={7}>
                <Box sx={{backgroundColor: "#17a2b8", color: "#fff"}} m={1} p={1}>
                  <b>{t('changePassword.passwordInfo.aSaferPassword')}</b>
                  <ul>
                      <li>{t('changePassword.passwordInfo.beAtLeastSixChar')}</li>
                      <li>{t('changePassword.passwordInfo.atLeastOneNotAlfaNum')}
                          <br />{t('changePassword.passwordInfo.sampleNotAlfaNum')}
                      </li>
                      <li>{t('changePassword.passwordInfo.atLeastOneDigit')}</li>
                      <li>{t('changePassword.passwordInfo.atLeastOneSmallLetter')}</li>
                      <li>{t('changePassword.passwordInfo.atLeastOneCapital')}</li>                                        
                  </ul>
                </Box>
              </Grid>
            </Grid>
            <Box m={1} p={0}>
              {t('changePassword.emailInfo.note1')}
              <br />
              {t('changePassword.emailInfo.note2')}{" "}
              <a href="mailto:noreply@finnebacks.inadire.se">
                noreply@finnebacks.inadire.se
              </a>
              &nbsp; {t('changePassword.emailInfo.note3')}
              <br />
            </Box>
            <Box m={1} mb={0} p={0}>
              <Typography variant="body2" gutterBottom={false}>
                {t('changePassword.tips')}
              </Typography>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column"}} pt={0}>
              <TextFieldEx
                id="email"
                label={t('changePassword.labels.email')}
                disabled={loading}                
                margin="normal"
                required
                {...form.allMuiProps("email")}
              />
              <TextFieldEx
                id="password"
                label={t('changePassword.labels.password')}
                disabled={loading}                
                margin="normal"
                required
                {...form.allMuiProps("password")}
              />
              <TextFieldEx
                id="confirmPassword"
                label={t('changePassword.labels.confirmPassword')}
                disabled={loading}                
                margin="normal"
                required
                {...form.allMuiProps("confirmPassword")}
              />
              <TextFieldEx
                id="authCode"
                label={t('changePassword.labels.authCode')}
                disabled={loading}                
                margin="normal"
                required
                {...form.allMuiProps("authCode")}
              />
            </Box>
          </Grid>
          <DialogActions sx={{justifyContent: "flex-start"}}>
            <Button
              className={classes.button}
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
              onClick={form.submit}
            >
              OK
            </Button>
            {loading && (
              <CircularProgress size={24} sx={{color: green[500], position: "absolute", left: "50%", marginLeft: 12,}} />
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
      {messageDialog.isOpen && (
        <MessageDialog
          headline={messageDialog.headline}
          message={messageDialog.message}
          showOkIcon={messageDialog.showOkIcon}
          showWarningIcon={messageDialog.showWarningIcon}
          onClose={() => handleMessageDialogClose(messageDialog.success)}
        />
      )}
    </Box>
  );
}

export default withStyles(styles)(ChangePassword);
