import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import EdiText from "react-editext";

import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";

import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";

import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";

import FloorTypeImage from "./FloorTypeImage";
import ChangePanelDivision from "./ChangePanelDivision";
import {IatCustomSelect, IatStyledOption} from "./basecomponents/IatCustomSelect";

const styles = {    
  blueInput: {
    backgroundColor: "rgb(232, 240, 254) !important",
    width: "850px",
  },
  label: {
    "& .MuiFormControlLabel-root": {      
      marginBottom: "0  !important",
    },
  }  
};

const formControlCheckboxStyle = {  
  "& .MuiCheckbox-root": {        
      paddingBottom: 0,      
      paddingTop: 0, 
    },  
  "& .MuiSvgIcon-root": { 
      fontSize: "1.3rem" 
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem", 
    marginBottom: 0,
  },  
}

// MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd css-1ebj5r2-MuiFormControlLabel-root

let dsRoomType = [
  { text: "Kyl", value: "Kyl" },
  { text: "Frys", value: "Frys" },
];
let dsHasRoof = [
  { text: "Ja", value: "Ja" },
  { text: "Nej", value: "Nej" },
];

let dsFloorBoard = [
  { text: "Nej", value: "Ingen" },
  { text: "12 mm (10000 kg/m², 75 kg/cm²)", value: "Wbp12" },
];

let dsPanelMaterial = [{ text: "Vit stålplåt", value: "PL" }];

const dsPanelThickness = [
  { text: "t =  75 mm (Temp: -25˚C till +80˚C)", value: 75 },
  { text: "t = 100 mm (Temp: -35˚C till +80˚C)", value: 100 },
  { text: "t = 125 mm (Temp: -40˚C till +80˚C)", value: 125 },
];

const sliderTicksInvHeight = {
  placement: "After",
  largeStep: 150,
  smallStep: 150,
  showSmallTicks: false,
};

const sliderTicksOrigo = {
  placement: "After",
  largeStep: 1000,
  smallStep: 1000,
  showSmallTicks: false,
};

const panelDirOptions = [  
  { code: 'X1', label: '' },
  { code: 'Y1', label: '' },
  { code: 'X2', label: '' },  
  { code: 'Y2', label: '' },
];

const imagePanelDir = {  
  'X1': require("../images/PanelDirX1.png"),
  'Y1': require("../images/PanelDirY1.png"),
  'X2': require("../images/PanelDirX2.png"),  
  'Y2': require("../images/PanelDirY2.png")
};

function ChangeRoomProps(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { classes } = props;

  const [cardTitle, setCardTitle] = useState("Egenskaper rum");
  const [floorBoard, setFloorBoard] = useState(null);
  const [floorDir, setFloorDir] = useState(null);
  const [floorDivision, setFloorDivision] = useState(null);
  const [floorType, setFloorType] = useState(null);
  const [hasRoof, setHasRoof] = useState(null);
  const [invHeight, setInvHeight] = useState(null);
  const [isOriginMoveable, setIsOriginMoveable] = useState(false);
  const [isAllowSpecialDim, setIsAllowSpecialDim] = useState(false);
  const [isHasHeatCable, setIsHasHeatCable] = useState(false);
  const [isHasLeca, setIsHasLeca] = useState(false);
  const [isHasTryckUtVentil, setIsHasTryckUtVentil] = useState(false);
  const [originOffset, setOriginOffset] = useState(null);
  const [panelMaterial, setPanelMaterial] = useState(null);
  const [panelThickness, setPanelThickness] = useState(null);
  const [roofDir, setRoofDir] = useState(null);
  const [roofDivision, setRoofDivision] = useState(null);
  const [roomLabel, setRoomLabel] = useState("");
  const [roomType, setRoomType] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [runParams, setRunParams] = useState({
    isStaff: false,
    sessionKey: null,
  });

  useEffect(() => {
    if (!isLoaded) {      
      switch (props.userRole) {
        case "x":
          dsFloorBoard = [
            { text: "Nej", value: "Ingen" },
            {
              text: "9 mm (4000 kg/m², 50 kg/cm²)",
              value: "Wbp09",
            },
            {
              text: "12 mm (10000 kg/m², 75 kg/cm²)",
              value: "Wbp12",
            },
            {
              text: "21 mm (18000 kg/m², 120 kg/cm²)",
              value: "Wbp21",
            },
          ];

          dsPanelMaterial = [
            { text: "Vit stålplåt", value: "PL" },
            { text: "Rostfritt", value: "RF" },
            { text: "Dekor", value: "DE" },
          ];
          break;

        default:
          break;
      }

      switch(i18n.language)
      {
        case "fi":
          dsRoomType[0].text = t('changeRoomProps.common.kyl');
          dsRoomType[1].text = t('changeRoomProps.common.frys');

          dsHasRoof[0].text = t('changeRoomProps.common.yes');
          dsHasRoof[1].text = t('changeRoomProps.common.no');

          dsFloorBoard[0].text = t('changeRoomProps.common.no')
          break;

        case "sv":
        default:
          break;
      }

      const split = props.roomData.floorType.split('|');
      const floorDirFromFloorType = split.length >= 2 ? split[1] : "--";
      console.log(props.roomData.floorType, floorDirFromFloorType);

      setCardTitle(t('changeRoomProps.card.title') + " " + props.roomData.autoPosNumber);
      setFloorBoard(props.roomData.floorBoard);
      setFloorDir(floorDirFromFloorType);
      setFloorDivision(props.roomData.floorDivision);
      setFloorType(props.roomData.floorType);
      setHasRoof(props.roomData.hasRoof);
      setInvHeight(props.roomData.invHeight);
      setIsAllowSpecialDim(props.roomData.isAllowSpecialDim);
      setIsHasHeatCable(props.roomData.isHasHeatCable);
      setIsHasLeca(props.roomData.isHasLeca);
      setIsHasTryckUtVentil(props.roomData.isHasTryckUtVentil);
      setIsOriginMoveable(props.roomData.isOriginMoveable);
      setOriginOffset(props.roomData.originOffset);
      setPanelMaterial(props.roomData.panelMaterial);
      setPanelThickness(props.roomData.panelThickness);
      setRoofDir(props.roomData.roofDir);
      setRoofDivision(props.roomData.roofDivision);
      setRoomLabel(props.roomData.roomLabel);
      setRoomType(props.roomData.roomType);
      setRunParams(props.runParams);
      setIsLoaded(true);
    }
  }, [isLoaded, props.roomData, props.userRole, props.runParams]);

  if (!isLoaded) return null;
  if (!props.roomData.isActive) return null;

  const handleRoomLabelChanged = (roomLabel) => {
    setRoomLabel(roomLabel);
    props.onChange({
      floorBoard: floorBoard,
      floorDir: floorDir,
      floorDivision: floorDivision,
      floorType: floorType,
      hasRoof: hasRoof,
      invHeight: invHeight,
      isAllowSpecialDim: isAllowSpecialDim,
      isHasHeatCable: isHasHeatCable,
      isHasLeca: isHasLeca,
      isHasTryckUtVentil: isHasTryckUtVentil,
      originOffset: originOffset,
      panelMaterial: panelMaterial,
      panelThickness: panelThickness,
      roofDir: roofDir,
      roofDivision: roofDivision,
      roomID: props.roomData.roomID,
      roomLabel: roomLabel,
      roomType: roomType,
    });
  };

  const handleRoomTypeChanged = (args) => {
    if (args.itemData.value !== roomType) {
      let newFloorType = floorType.substring(0, 1);
      let newPanelThickness = panelThickness;
      let newFloorBoard = floorBoard;
      let newFloorDir = floorDir;
      let hasHeatCable = isHasHeatCable;
      let hasTryckUtVentil = isHasTryckUtVentil;

      switch (args.itemData.value) {
        case "Kyl":
          setCardTitle(
            t('changeRoomProps.card.title') + " K" + props.roomData.autoPosNumber.substring(1)
          );
          newFloorType = "1";
          newPanelThickness = 75;
          newFloorBoard = "Ingen";
          if (newFloorDir === null || newFloorDir !== "--") newFloorDir = "--";
          hasHeatCable = false;
          hasTryckUtVentil = false;
          break;

        case "Frys":
          setCardTitle(
            t('changeRoomProps.card.title') + " F" + props.roomData.autoPosNumber.substring(1)
          );
          if (floorType.substring(0, 1) === "1") newFloorType = "2";
          if (panelThickness < 100) newPanelThickness = 100;
          if (newFloorDir === null || newFloorDir === "--") newFloorDir = "Y1";
          hasHeatCable = true;
          hasTryckUtVentil = true;
          break;

        default:
          break;
      }

      setRoomType(args.itemData.value);
      setFloorType(newFloorType);
      setPanelThickness(newPanelThickness);
      setFloorBoard(newFloorBoard);
      setFloorDir(newFloorDir);
      setIsHasHeatCable(hasHeatCable);
      setIsHasTryckUtVentil(hasTryckUtVentil);

      props.onChange({
        floorBoard: newFloorBoard,
        floorDir: newFloorDir,
        floorDivision: floorDivision,
        floorType: newFloorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: hasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: newPanelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: args.itemData.value,
      });
    }
  };

  const handleFloorTypeClicked = (value) => {
    if (value !== floorType) {
      let newFloorBoard = floorBoard;
      let newFloorDir = floorDir;
      let hasHeatCable = isHasHeatCable;
      let hasLeca = isHasLeca;      
      switch (value) {
        case "1":
          newFloorBoard = "Ingen";
          if (newFloorDir === null || newFloorDir !== "--") newFloorDir = "--";
          hasHeatCable = false;
          hasLeca = false;
          break;

        case "2":
          if (floorBoard === "Ingen") newFloorBoard = "Wbp12";
          if (newFloorDir === null || newFloorDir === "--") newFloorDir = "Y1";
          hasHeatCable = (roomType === "Frys");
          hasLeca = false;
          break;

        case "3":
          if (floorBoard === "Ingen") newFloorBoard = "Wbp12";
          if (newFloorDir === null || newFloorDir === "--") newFloorDir = "Y1";
          hasHeatCable = (roomType === "Frys");
          hasLeca = true;
          break;

        case "4":
          newFloorBoard = "Ingen";
          if (newFloorDir === null || newFloorDir !== "--") newFloorDir = "--";
          hasHeatCable = (roomType === "Frys");
          hasLeca = false;
          break;

        default:
          break;
      }
      setFloorType(value);
      setFloorBoard(newFloorBoard);
      setFloorDir(newFloorDir);
      setIsHasHeatCable(hasHeatCable);
      setIsHasLeca(hasLeca);
      props.onChange({
        floorBoard: newFloorBoard,
        floorDir: newFloorDir,
        floorDivision: floorDivision,
        floorType: value,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: hasHeatCable,
        isHasLeca: hasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleFloorBoardChanged = (args) => {
    if (args.itemData.value !== floorBoard) {
      setFloorBoard(args.itemData.value);
      props.onChange({
        floorBoard: args.itemData.value,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleRoomHeightChange = (args) => {
    if (args.value !== invHeight) {
      setInvHeight(args.value);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: args.value,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleHasRoofChanged = (args) => {
    if (args.itemData.value !== hasRoof) {
      let newRoofDir = roofDir;
      switch(args.itemData.value)
      {
        case "Ja":
          if (newRoofDir === null || newRoofDir === "--") newRoofDir = "Y1";
          break;

        case "Nej":
          if (newRoofDir === null || newRoofDir !== "--") newRoofDir = "--";
          break;

        default:
          break;
      }
      setHasRoof(args.itemData.value);
      setRoofDir(newRoofDir);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: args.itemData.value,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: newRoofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleOriginOffsetChange = (args) => {
    if (args.value !== originOffset) {
      setOriginOffset(args.value);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: args.value,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handlePanelMaterialChanged = (args) => {
    if (args.itemData.value !== panelMaterial) {
      setPanelMaterial(args.itemData.value);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: args.itemData.value,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handlePanelThicknessChanged = (args) => {
    if (args.itemData.value !== panelThickness) {
      setPanelThickness(args.itemData.value);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: args.itemData.value,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleRoofDirChanged = (newRoofDir) => {
    if (newRoofDir !== roofDir) {
      setRoofDir(newRoofDir);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: newRoofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleFloorDirChanged = (newFloorDir) => {
    if (newFloorDir !== floorDir) {
      setFloorDir(newFloorDir);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: newFloorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleRoofDivisionChanged = (newDivision) => {
    if (newDivision !== roofDivision) {
      setRoofDivision(newDivision);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: floorDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: newDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };

  const handleFloorDivisionChanged = (newDivision) => {
    if (newDivision !== floorDivision) {
      setFloorDivision(newDivision);
      props.onChange({
        floorBoard: floorBoard,
        floorDir: floorDir,
        floorDivision: newDivision,
        floorType: floorType,
        hasRoof: hasRoof,
        invHeight: invHeight,
        isAllowSpecialDim: isAllowSpecialDim,
        isHasHeatCable: isHasHeatCable,
        isHasLeca: isHasLeca,
        isHasTryckUtVentil: isHasTryckUtVentil,
        originOffset: originOffset,
        panelMaterial: panelMaterial,
        panelThickness: panelThickness,
        roofDir: roofDir,
        roofDivision: roofDivision,
        roomID: props.roomData.roomID,
        roomLabel: roomLabel,
        roomType: roomType,
      });
    }
  };
  
  const handleCheckboxChange  = (event) => {
    switch(event.target.name)
    {
      case "AllowSpecialDim":
        setIsAllowSpecialDim(event.target.checked);
        break;

      case "HeatCable":
        setIsHasHeatCable(event.target.checked);
        break;

      case "Leca":
        setIsHasLeca(event.target.checked);
        break;

      case "TryckUtVentil":
        setIsHasTryckUtVentil(event.target.checked);
        break;            
    }

    props.onChange({
      floorBoard: floorBoard,
      floorDir: floorDir,
      floorDivision: floorDivision,
      floorType: floorType,
      hasRoof: hasRoof,
      invHeight: invHeight,
      isAllowSpecialDim: event.target.name === "AllowSpecialDim" ? event.target.checked : isAllowSpecialDim,
      isHasHeatCable: event.target.name === "HeatCable" ? event.target.checked : isHasHeatCable,
      isHasLeca: event.target.name === "Leca" ? event.target.checked : isHasLeca,
      isHasTryckUtVentil: event.target.name === "TryckUtVentil" ? event.target.checked : isHasTryckUtVentil,
      originOffset: originOffset,
      panelMaterial: panelMaterial,
      panelThickness: panelThickness,
      roofDir: roofDir,
      roofDivision: roofDivision,
      roomID: props.roomData.roomID,
      roomLabel: roomLabel,
      roomType: roomType,
    });
  };

  const floorBoardEnabled =
    floorType.substring(0, 1) === "2" || floorType.substring(0, 1) === "3";

  const isStaffWithSessionKey = runParams !== null && runParams.isStaff && runParams.sessionKey !== null;

  if (props.roomData.isActive) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Modal sx={{ display: "flex", justifyContent: "flex-end" }} open={true}>
          <Card sx={{height: "100%", overflowY: "auto"}}>
            <CardHeader
              action={
                <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                  <IconButton onClick={props.onClose} size="large">
                    <CheckBoxIcon style={{ color: green[500] }} />
                  </IconButton>
                </Tooltip>
              }
              title={cardTitle}
            />
            <CardContent className={classes.cardContents}>
              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <DropDownListComponent
                  id="roomType"
                  dataSource={dsRoomType}
                  fields={{
                    text: "text",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeRoomProps.roomType.placeholder')}
                  value={roomType}
                  select={handleRoomTypeChanged}
                />
              </Grid>

              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <div
                  className={"e-float-text e-label-top"}
                  style={{
                    fontSize: "13px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {t('changeRoomProps.invHeight.label')} {invHeight} mm
                </div>
                <SliderComponent
                  id="invRoomHeight"
                  min={1950}
                  max={6000}
                  showButtons={true}
                  step={150}
                  ticks={sliderTicksInvHeight}
                  value={invHeight}
                  change={handleRoomHeightChange}
                />
              </Grid>

              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <DropDownListComponent
                  id="hasRoof"
                  dataSource={dsHasRoof}
                  fields={{
                    text: "text",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeRoomProps.hasRoof.placeholder')}
                  value={hasRoof}
                  select={handleHasRoofChanged}
                />
              </Grid>

              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <DropDownListComponent
                  id="panelThickness"
                  dataSource={dsPanelThickness}
                  fields={{
                    text: "text",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeRoomProps.thickness.placeholder')}
                  value={panelThickness}
                  select={handlePanelThicknessChanged}
                />
              </Grid>

              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <DropDownListComponent
                  id="panelMaterial"
                  dataSource={dsPanelMaterial}
                  fields={{
                    text: "text",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeRoomProps.material.placeholder')}
                  value={panelMaterial}
                  select={handlePanelMaterialChanged}
                />
              </Grid>

              <Typography>{t('changeRoomProps.golvtyp')}</Typography>

              <Grid container>
                <Grid item>
                  <FloorTypeImage
                    floorType="1"
                    roomType={roomType}
                    selectedFloorType={floorType}
                    onClick={handleFloorTypeClicked}
                  />
                </Grid>

                <Grid item>
                  <FloorTypeImage
                    floorType="2"
                    roomType={roomType}
                    selectedFloorType={floorType}
                    onClick={handleFloorTypeClicked}
                  />
                </Grid>
                <Grid item>
                  <FloorTypeImage
                    floorType="3"
                    roomType={roomType}
                    selectedFloorType={floorType}
                    onClick={handleFloorTypeClicked}
                  />
                </Grid>
                <Grid item>
                  <FloorTypeImage
                    floorType="4"
                    roomType={roomType}
                    selectedFloorType={floorType}
                    onClick={handleFloorTypeClicked}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <DropDownListComponent
                  id="floorBoard"
                  dataSource={dsFloorBoard}
                  enabled={floorBoardEnabled}
                  fields={{
                    text: "text",
                    value: "value",
                  }}
                  floatLabelType="Always"
                  placeholder={t('changeRoomProps.floorboard.placeholder')}
                  value={floorBoard}
                  select={handleFloorBoardChanged}
                />
              </Grid>

              {isStaffWithSessionKey && hasRoof !== null && hasRoof === 'Ja' && (
                <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                  <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Typography sx={{width: '40px'}}>{t('changeRoomProps.roofDirDivision.headline')}</Typography>
                    <IatCustomSelect   
                      sx ={{minWidth: '120px', padding: '3px'}}
                      value={roofDir}  
                      onChange={handleRoofDirChanged}>
                      {panelDirOptions.map((c) => (
                          <IatStyledOption key={c.code} value={c.code}>
                          <img
                              loading="lazy"
                              width="53"
                              src={imagePanelDir[c.code]}
                              alt={`${c.label}`}
                          />
                          {c.label}
                          </IatStyledOption>
                      ))}
                    </IatCustomSelect>
                    <ChangePanelDivision                      
                      panelDivision={roofDivision}
                      onChange={handleRoofDivisionChanged}
                    />
                  </Stack>
                </Grid>
              )}

              {isStaffWithSessionKey && floorBoardEnabled && (
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Typography sx={{width: '40px'}}>{t('changeRoomProps.floorDirDivision.headline')}</Typography>
                  <IatCustomSelect   
                    sx ={{minWidth: '120px', padding: '3px'}}
                    value={floorDir}  
                    onChange={handleFloorDirChanged}>
                    {panelDirOptions.map((c) => (
                        <IatStyledOption key={c.code} value={c.code}>
                        <img
                            loading="lazy"
                            width="53"
                            src={imagePanelDir[c.code]}
                            alt={`${c.label}`}
                        />
                        {c.label}
                        </IatStyledOption>
                    ))}
                  </IatCustomSelect>
                  <ChangePanelDivision                      
                    panelDivision={floorDivision}
                    onChange={handleFloorDivisionChanged}
                  />
                </Stack>
              )}

              {isStaffWithSessionKey && (
                <Grid sx={{ marginBottom: theme.spacing(1.5) }}>
                  <FormGroup>
                    <FormControlLabel sx={{...formControlCheckboxStyle}} control={<Checkbox size="small" checked={isHasHeatCable} onChange={handleCheckboxChange} name="HeatCable" />} label="Värmekabel" />
                    <FormControlLabel sx={{...formControlCheckboxStyle}} control={<Checkbox size="small" checked={isHasLeca} />} onChange={handleCheckboxChange} name="Leca" label="Avjämningsmaterial (leca)" />
                    <FormControlLabel sx={{...formControlCheckboxStyle}} control={<Checkbox size="small" checked={isHasTryckUtVentil} onChange={handleCheckboxChange} name="TryckUtVentil" />} label="Tryckutjämningsventil" />
                    <FormControlLabel sx={{...formControlCheckboxStyle}} control={<Checkbox size="small" checked={isAllowSpecialDim} onChange={handleCheckboxChange} name="AllowSpecialDim" />} label="Tillåt valfria mått" />
                  </FormGroup>
                </Grid>
              )}

              <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                <div
                  className={"e-float-text e-label-top"}
                  style={{
                    fontSize: "13px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {t('changeRoomProps.roomDenomination.label')}
                </div>
                <EdiText
                  type="text"
                  editButtonContent={t('changeRoomProps.roomDenomination.editButtonContent')}
                  inputProps={{
                    className: classes.blueInput,
                  }}                  
                  viewProps={{sx: {maxWidth: "750px"}}}
                  value={roomLabel}
                  onSave={handleRoomLabelChanged}
                />
              </Grid>

              {isOriginMoveable && (
                <Grid sx={{ marginBottom: theme.spacing(1.5), minWidth: 945 }}>
                  <div
                    className={"e-float-text e-label-top"}
                    style={{
                      fontSize: "13px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    {t('changeRoomProps.originOffsetTitle')}{" "}
                    {originOffset} mm
                  </div>
                  <SliderComponent
                    id="originOffset"
                    min={-15000}
                    max={15000}
                    showButtons={true}
                    step={50}
                    ticks={sliderTicksOrigo}
                    value={originOffset}
                    change={handleOriginOffsetChange}
                  />
                </Grid>
              )}                          

            </CardContent>
          </Card>
        </Modal>
      </div>
    );
  }

  return null;
}

export default withStyles(styles)(ChangeRoomProps);
