import React, { useState, useEffect } from "react";
import produce from "immer";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FinSettings from "./localization/AllProFormManagerFinSettings";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from '@mui/material/styles';
import { useFormManager, cloneDeep } from "@allpro/form-manager";
import SweSettings from "./localization/AllProFormManagerSweSettings";
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import MessageDialog from "./MessageDialog";

const styles = {};

const TextFieldEx = styled(TextField)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "400px",
    ".MuiInputLabel-asterisk": { color: "red" },
  }));

const formConfigSwe = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(SweSettings.defaultSweErrorMessages),

    fields: {
        email: {
            displayName: "E-postadress",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "En giltig e-postadress måste anges."
            }
        },
        authCode: {
            displayName: "Behörighetskod",
            validation: {
                required: true
            },
            errorMessages: {
                required: "Behörighetskod måste anges."
            }
        }
    }
};

const formConfigFin = {
    fieldDefaults: {
        isMUIControl: true,
        validateOnBlur: true,
        revalidateOnChange: true
    },

    errorMessages: cloneDeep(FinSettings.defaultFinErrorMessages),

    fields: {
        email: {
            displayName: "Sähköpostiosoite",
            alias: "email",
            validation: {
                required: true,
                email: true
            },
            errorMessages: {
                required: "Kelvollinen sähköpostiosoite on annettava."
            }
        },
        authCode: {
            displayName: "Valtuutuskoodi",
            validation: {
                required: true
            },
            errorMessages: {
                required: "Valtuutuskoodi on syötettävä"
            }
        }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RegisterVerifyEmail(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { classes } = props;
    const form = useFormManager(i18n.language === "fi" ? formConfigFin : formConfigSwe);
    const [loading, setLoading] = useState(false);
    const [messageDialog, setMessageDialog] = useState({
        isOpen: false,
        success: false,
        headline: "",
        message: "",
        showOkIcon: false,
        showWarningIcon: false
    });

    useEffect(() => {
        form.setFieldData("email", props.email);
    }, [props.email, form]);

    function handleSubmit() {
        form.validate().then(isValid => {
            if (isValid) {
                setLoading(true);
                let dtoData = JSON.stringify({
                    email: form.data().email,
                    authCode: form.data().authCode,
                    language: i18n.language
                });
                fetch("/api/Account/RegisterEmailConfirmed", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: dtoData
                })
                    .then(res => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            throw new Error(res.status + " " + res.statusText);
                        }
                    })
                    .then(
                        result => {
                            if (result.succeeded) {
                                props.submit();
                            } else {
                                setLoading(false);
                                setMessageDialog(
                                    produce(draft => {
                                        draft.isOpen = true;
                                        draft.success = false;
                                        draft.headline = t('registerVerifyEmail.msgBox.handleSubmit.error.headline');
                                        draft.message = result.errorMessages[0];
                                        draft.showOkIcon = false;
                                        draft.showWarningIcon = true;
                                    })
                                );
                            }
                        },
                        error => {
                            setMessageDialog(
                                produce(draft => {
                                    draft.isOpen = true;
                                    draft.success = false;
                                    draft.headline = t('registerVerifyEmail.msgBox.handleSubmit.error.headline');
                                    draft.message = error.stack;
                                    draft.showOkIcon = false;
                                    draft.showWarningIcon = true;
                                })
                            );
                        }
                    )
                    .catch(error => {
                        setMessageDialog(
                            produce(draft => {
                                draft.isOpen = true;
                                draft.success = false;
                                draft.headline = t('registerVerifyEmail.msgBox.handleSubmit.error.headline');
                                draft.message = error.stack;
                                draft.showOkIcon = false;
                                draft.showWarningIcon = true;
                            })
                        );
                    });
            }
        });
    }

    const handleMessageDialogClose = success => {
        if (success) {
            props.onClose();
        } else {
            setLoading(false);
            setMessageDialog(
                produce(draft => {
                    draft.headline = "";
                    draft.isOpen = false;
                    draft.meassage = "";
                    draft.showOkIcon = false;
                    draft.showWarningIcon = false;
                })
            );
        }
    };

    form.submit = handleSubmit;

    return (
        <Box sx={{display: "flex"}}>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
            >
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        <Typography variant="h6" sx={{marginLeft: theme.spacing(2), flex: 1}}>
                            {t('registerVerifyEmail.headline')}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="Close"
                            onClick={props.onClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid item md={12}>
                        <Box m={1} p={0}>
                            {t('registerVerifyEmail.emailInfo.note1')}                            
                            <br />
                            {t('registerVerifyEmail.emailInfo.note2')}{" "}
                            <a href="mailto:noreply@finnebacks.inadire.se">
                                noreply@finnebacks.inadire.se
                            </a>
                            &nbsp; {t('registerVerifyEmail.emailInfo.note3')}
                            <br />
                            <br />
                            {t('registerVerifyEmail.emailInfo.note4')}
                        </Box>

                        <Box sx={{display: "flex", flexDirection: "column"}} pt={0}>
                            <TextFieldEx
                                id="email"
                                label={t('registerVerifyEmail.labels.email')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("email")}
                            />
                            <TextFieldEx
                                id="authCode"
                                label={t('registerVerifyEmail.labels.authCode')}
                                disabled={loading}                                
                                margin="normal"
                                required
                                size="small"
                                variant="standard"
                                {...form.allMuiProps("authCode")}
                            />
                        </Box>
                    </Grid>
                    <DialogActions sx={{justifyContent: "flex-start"}}>
                        <Button
                            className={classes.button}
                            color="primary"
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            onClick={form.submit}
                        >
                            OK
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{color: green[500], position: "absolute", left: "50%", marginLeft: 12}}
                            />
                        )}
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {messageDialog.isOpen && (
                <MessageDialog
                    headline={messageDialog.headline}
                    message={messageDialog.message}
                    showOkIcon={messageDialog.showOkIcon}
                    showWarningIcon={messageDialog.showWarningIcon}
                    onClose={() =>
                        handleMessageDialogClose(messageDialog.success)
                    }
                />
            )}
        </Box>
    );
}

export default withStyles(styles)(RegisterVerifyEmail);
