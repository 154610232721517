import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CssBaseline from "@mui/material/CssBaseline";
import { green } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import ChangeIntegerValue from "./ChangeIntegerValue";

const styles = { };

const StyledIconButton = styled(IconButton)({  
  paddingTop: '0px',
  paddingBottom: '0px',
  '&:focus': {
    outline: 0,
  },
});

function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21.635,6.366c-0.467-0.772-1.043-1.528-1.748-2.229c-0.713-0.708-1.482-1.288-2.269-1.754L19,1C19,1,21,1,22,2S23,5,23,5  L21.635,6.366z M10,18H6v-4l0.48-0.48c0.813,0.385,1.621,0.926,2.348,1.652c0.728,0.729,1.268,1.535,1.652,2.348L10,18z M20.48,7.52  l-8.846,8.845c-0.467-0.771-1.043-1.529-1.748-2.229c-0.712-0.709-1.482-1.288-2.269-1.754L16.48,3.52  c0.813,0.383,1.621,0.924,2.348,1.651C19.557,5.899,20.097,6.707,20.48,7.52z M4,4v16h16v-7l3-3.038V21c0,1.105-0.896,2-2,2H3  c-1.104,0-2-0.895-2-2V3c0-1.104,0.896-2,2-2h11.01l-3.001,3H4z" />
    </SvgIcon>
  );
}

function ChangeDimension(props) {
  const theme = useTheme();  
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState(null);
  const [isAllowSpecialDim, setIsAllowSpecialDim] = useState(false);

  const [changeSpecialDim, setChangeSpecialDim] = useState({
    dimPrefix: null,
    isActive: false,    
    oldValue: "0", 
    min: 0,
    max: 0,   
  });

  useEffect(() => {
    setNewValue(props.changeDimData.oldValue);
    setIsAllowSpecialDim(props.changeDimData.isAllowSpecialDim);
  }, [props.changeDimData.oldValue, props.changeDimData.isAllowSpecialDim]);

  function handleSliderChange(args) {
    setNewValue(args.value);
    props.onDimensionChanged(
      props.changeDimData.roomID,
      props.changeDimData.propertyName,
      args.value,
      args.previousValue
    );
  }

  const handleSpecialDimClicked = (event, curValue) => {    
    setChangeSpecialDim({
      dimPrefix: props.changeDimData.dimPrefix,
      isActive: true,
      oldValue: newValue,
      propertyName: null,
      min: props.changeDimData.minValue,
      max: props.changeDimData.maxValue,
    });
  };

  const handleSpecialDimChanged = (propertyName, newValue, oldValue) => {
    if (newValue === null || newValue === '')
      return;

      setChangeSpecialDim({
        isActive: false,
      });
  
      if (newValue === oldValue)
        return;

      setNewValue(newValue);
      props.onDimensionChanged(
        props.changeDimData.roomID,
        props.changeDimData.propertyName,
        newValue,
        oldValue
      );
  };

  const handleCloseSpecialDim = () => {
    setChangeSpecialDim({
      isActive: false,
    });
  };

  if (props.changeDimData.isActive) {
    return (
      <Box sx={{display: "flex", flexWrap: "wrap"}}>
        <CssBaseline />
        <Modal sx={{display: "flex", justifyContent: "flex-end"}} open={true}>
          <Card sx={{maxWidth: 400, height: "100%", overflowY: "auto"}}>
            <CardHeader
              action={
                <Tooltip title={t('card.cardheader.close')} placement="bottom-start">
                  <IconButton onClick={props.onClose} size="large">
                    <CheckBoxIcon style={{ color: green[500] }} />
                  </IconButton>
                </Tooltip>
              }
              title={t('changeDimension.card.title')}
            />
            <CardContent>
              <FormControl sx={{margin: theme.spacing(1),  minWidth: 330}}>
                <Grid container>
                  <Grid item xs>
                    <SliderComponent
                      id="slider"
                      min={props.changeDimData.minValue}
                      max={props.changeDimData.maxValue}
                      showButtons={true}
                      step={props.changeDimData.step}
                      value={props.changeDimData.oldValue}
                      change={handleSliderChange}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      sx={{paddingLeft: "15px"}}
                    >
                      {props.changeDimData.dimPrefix}
                      {newValue} mm
                    </Typography>
                  </Grid>
                </Grid>

                {isAllowSpecialDim && (
                  <Typography component="div" mt={1} mb={2}>
                    <Box m={0}>                                           
                      <StyledIconButton onClick={handleSpecialDimClicked}>
                        <Typography sx={{color:"black", fontSize: "0.8125rem", fontWeight: 500, paddingRight: 1}} display="inline">Klicka här för att ange valfritt mått</Typography> 
                        <EditIcon color="primary" fontSize="small"/>                      
                      </StyledIconButton>
                    </Box>                                    
                  </Typography>
                )}

                {isAllowSpecialDim && (newValue % 75 !== 0 || newValue % 25 !== 0) && (
                  <Typography ml={1} sx={{color:"red", fontSize: "0.8125rem", fontWeight: 500}} display="inline">
                    Restvärde utöver jämna 75 mm är: {newValue % 75} mm
                    <br/>
                    Restvärde utöver jämna 25 mm är: {newValue % 25} mm
                  </Typography> 
                )}
                

                <ChangeIntegerValue
                    changeData={changeSpecialDim}
                    onOkClicked={handleSpecialDimChanged}
                    onClose={handleCloseSpecialDim}
                  />

              </FormControl>
            </CardContent>
          </Card>
        </Modal>
      </Box>
    );
  }

  return null;
}

export default withStyles(styles)(ChangeDimension);
